import React, { useState, useEffect } from "react";
import { Row, Col, Form, Radio, Button, Collapse, Tag, Dropdown, Menu, Popover, Modal, notification, Input, Divider, Table } from "antd";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber, FormatDate } from "../../ValueObjects";
import { manutencaoActions, listagemActions } from "../../actions";
import { formatarCpfCnpj } from "../../services/funcoes";
import { BreadcrumbPage, ComboData, Data } from "../../components";
import { linkApi } from "../../services/auth";
import { DownOutlined, SaveOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export default function ParcelaCartao() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [listaMovimentacao, setListaMovimentacao] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null); // Estado para controlar a linha expandida
    const [totalConciliado, setTotalConciliado] = useState(null);
    const [totalNaoConciliado, setTotalNaoConciliado] = useState(null);
    const [totalizador, setTotalizador] = useState(false);

    const handleExpand = (record) => {
        setExpandedRowKey(record.key === expandedRowKey ? null : record.key);
    };

    useEffect(() => {
        form.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
        listarMovimentacao();
    }, []);

    function listarMovimentacao() {
        let filtro = "";
        filtro += !!form.getFieldValue().dataInicial ? `&dataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        filtro += !!form.getFieldValue().dataFinal ? `&dataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        filtro += !!form.getFieldValue().tipo ? `&tipoMovimentacao=${form.getFieldValue().tipo}` : '';       
        filtro += !!form.getFieldValue().TipoDoc ? `&tipoDoc=${form.getFieldValue().TipoDoc}` : '';
        filtro += !!form.getFieldValue().conciliados ? `&conciliado=${form.getFieldValue().conciliados}` : '';
        filtro += !!form.getFieldValue().filtros ? `&filtro=${form.getFieldValue().filtros}` : '';
        api.get(`ConciliacaoCartao/ListarParcelasCartao?${filtro}`).then(
            (res) => {
                if (res.status === 200) {
                    let dadosMovimentacao = res.data;
                    if (dadosMovimentacao.length > 0) {
                        setTotalizador(true);
                    }
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtro } });
                    let conciliadoTotal = 0;
                    let nãoConciliadoTotal = 0;
                    let conciliado = dadosMovimentacao.filter(item => item.rcc_conciliado === true);
                    let naoconciliado = dadosMovimentacao.filter(item => item.rcc_conciliado === false);
                    conciliadoTotal = conciliado.reduce((soma, item) => soma + item.rcc_valor, 0);
                    nãoConciliadoTotal = naoconciliado.reduce((soma, item) => soma + item.rcc_valor, 0);
                    setTotalConciliado(conciliadoTotal);
                    setTotalNaoConciliado(nãoConciliadoTotal);
                    setListaMovimentacao(dadosMovimentacao);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };


    return (
        <div className="p-4">
            {/* <Form layout="vertical" form={form} className="p-b-75"> */}
            <div>
                <Form layout="vertical" form={form} >
                    <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" onClick={listarMovimentacao}>
                        <Col className="col-w-100">
                            <BreadcrumbPage />
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={7} xxl={3}>
                            <Form.Item label={"Pesquisar por"} name="filtros">
                                <Input.Search placeholder={"Banco, Bandeira, Cliente, N° Nota, Série, NSU"} onChange={valor => listarMovimentacao(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={6} lg={4} xl={4} xxl={3}>
                            <ComboData form={form} eventoAoAlterar={() => {
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                listarMovimentacao();
                            }} />
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                            <Data label="Data Inicial" name="dataInicial" onBlur={listarMovimentacao} />
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                            <Data label="Data Final" name="dataFinal" onBlur={listarMovimentacao} />
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10} xl={5}>
                            <Form.Item label="Tipo Cartão" name="tipo" initialValue={1}>
                                <Radio.Group options={[{ label: 'Todos', value: 1 }, { label: 'Crédito', value: 3 }, { label: 'Débito', value: 4 }]} onChange={listarMovimentacao} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10} xl={6}>
                            <Form.Item label="Conciliados" name="conciliados" initialValue={null}>
                                <Radio.Group options={[{ label: 'Todos', value: null }, { label: 'Conciliado', value: true }, { label: 'Não Conci.', value: false }]} onChange={listarMovimentacao} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={10} lg={10} xl={7}>
                            <Form.Item label="Tipo Documento" name="TipoDoc" initialValue={0}>
                                <Radio.Group options={[{ label: 'Todos', value: 0 }, { label: 'Receb. Parcelas', value: 1 }, { label: 'Cupom Fiscal', value: 2 }]} onChange={listarMovimentacao} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">
                <Table pagination={true} dataSource={listaMovimentacao} columns={
                    [
                        {
                            title: 'Prev. Rec.',
                            width: 80,
                            render: (record) => (
                                <div>
                                    <b>{moment(record.rcc_previsaorecebimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Conciliado',
                            width: 50,
                            render: (record) => (
                                <div>
                                    {/* <Row align="middle" justify="center" gutter={[5, 5]}> */}
                                    <Col >
                                        <Tag color={((record.rcc_conciliado === null) ? 'default' : (record.rcc_conciliado === true ? 'green' : 'red'))}>
                                            <b>
                                                <b>{((record.rcc_conciliado === false) ? 'Não' : (record.rcc_conciliado === true ? 'Sim' : ''))}</b>
                                            </b>
                                        </Tag>
                                    </Col>
                                    {/* </Row> */}
                                </div>
                            ),
                        },
                        {
                            title: 'Pagamento',
                            width: 70,
                            render: (record) => (
                                <div>
                                    <b>{record.tipo}</b>
                                </div>
                            ),

                        },
                        {
                            title: 'Tipo Doc.',
                            width: 70,
                            render: (record) => (
                                <div>
                                    <b>{record.tipoDoc}</b>
                                </div>
                            ),

                        },
                        {
                            title: 'Bandeira',
                            width: 50,
                            render: (record) => (
                                <div>
                                    <Row align="middle">
                                        <Col >
                                            <div className="img text-center">
                                                <img src={`${linkApi}BandeiraCartao/LogoBandeira?codigoBandeiraCartao=${record.bnd_id}`} alt="Visa" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Doc. Fiscal',
                            width: 50,
                            render: (record) => (
                                <div>
                                    <b>{record.ntf_numero}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Parcela',
                            width: 50,
                            render: (record) => (
                                <div>
                                    <b>{record.rcc_parcelarecebimento + " / " + record.cpr_numeroparcelas}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'NSU',
                            width: 200,
                            render: (record) => (
                                <div>
                                    <b>{record.rcc_nsu || '-'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Conciliado',
                            width: 50,
                            render: (record) => (
                                <div>
                                    <b>{record.hcc_datahoraconciliacao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Taxa (R$)',
                            align: 'right',
                            width: 100,
                            render: (record) => (
                                <div>
                                    <b className="f-18">{FormatNumber(record.valorTaxa, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Total (R$)',
                            align: 'right',
                            width: 100,
                            render: (record) => (
                                <div>
                                    <b className="f-18">{FormatNumber(record.rcc_valor, true)}</b>
                                </div>
                            ),
                        }
                    ]}
                    expandable={{
                        expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                        onExpand: (expanded, record) => handleExpand(record),
                        expandedRowRender: (record) => (
                            <div>
                                <Row align="middle" gutter={[16, 0]}>
                                    <Col>
                                        Chave Nfe: <b>{record.ntf_chavenfe}</b>
                                    </Col>
                                    <Col>
                                        Série: <b>{record.ser_serie}</b>
                                    </Col>
                                    <Col>
                                        Cliente: <b>{record.pessoa}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                            ) : (
                                <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                            )
                    }}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} scroll={{ x: 900 }}
                />
            </div>   
            {totalizador && (
                <div style={{ marginTop: 16 }}>
                    {/* <Divider>Totalizadores</Divider> */}
                    <Row gutter={[16, 16]} justify="end">
                        <Col style={{ textAlign: 'right', fontSize: '14px' }}>
                            <b>Total Conciliado:</b> R${FormatNumber(totalConciliado, true)}
                        </Col>
                        <Col style={{ textAlign: 'right', fontSize: '14px' }}>
                            <b>Total Não Conciliado:</b> R${FormatNumber(totalNaoConciliado, true)}
                        </Col>
                    </Row>
                </div>
            )}
        </div > 
    );
}