import React, { useState } from "react";
import { Form, Row, Col, Input, Select, notification, Upload, Button } from "antd";

import api from '../../services/api';
import { Editar, FormGW, SelectPaginacao, SelectPaginado } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions, manutencaoActions } from "../../actions";
import UploadFoto from "../../components/modals/modalManutencaoPecasCat/UploadFoto";
import { DownloadOutlined } from "@ant-design/icons";

export default function ManutencaoCidade({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [imagem, setImagem] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'CidadeConfigNfse/';

        if (imageUrl != null) {
            values.cnf_brasaocidade = imageUrl;
        }
        if (values.cnf_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "As novas configurações foram salvas com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                    setFileList([]);
                })
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "AS configurações foram incluidas com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        }

    }

    const base64 = (imagem, callback) => {
        const reader = new FileReader();
        reader.onload = () => {
            callback(reader.result.toString());
        };
        reader.onerror = (error) => {
            console.error("Erro ao converter para base64:", error);
            callback(null);
        };
        reader.readAsDataURL(imagem);
    };



    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status !== 'removed') {
            base64(info.fileList[0].originFileObj, (base64Result) => {
                setLoading(false);
                setImageUrl(base64Result);
            });
        }
        if (info.file.status === 'removed')
            setImageUrl(null);

        setFileList(info.fileList);
    };

    return (
        <Form layout="vertical" form={formulario} name="ManutencaoCidade" onFinish={salvarManutencao}>
            <Form.Item name="cnf_id" hidden={true}> <Input />
            </Form.Item>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={18} md={20} lg={20} xl={20}>

                    <Form.Item label="Descrição Cidade" name="cid_id" >
                        <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade" nameLabel="cid_descricao" nameValue="cid_id" form={formulario}
                            conteudo={
                                cid => (<Select.Option value={cid.cid_id} key={cid.key}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                            } />
                    </Form.Item>

                    <Form.Item label="Provedor NFSE" name="cnf_provedornfse" rules={[
                    ]}>
                        <Input placeholder="Informe o provedor de NFSE" />
                    </Form.Item>


                    <Form.Item label="Informe a versão para emissão" name="cnf_versaonfse" rules={[
                    ]}>
                        <Input placeholder="Informe a versão para emissão" />
                    </Form.Item>

                    <Form.Item label="Percentual de Aliquota do ISSQN" name="cnf_percentualissqn" rules={[
                    ]}>
                        <Input placeholder="Percentual de Aliquota do ISSQN" />
                    </Form.Item>

                    <Form.Item label="Quantidade de serviços por NFSE" name="cnf_qtdeserviconfse" rules={[
                    ]}>
                        <Input placeholder="Quantidade de serviços por NFSE" />
                    </Form.Item>

                    <Form.Item label="Imagem Brasão cidade (logo)" name="cnf_brasaocidade" rules={[
                    ]}>
                        <Upload onChange={handleChange}
                            fileList={fileList}
                            listType="picture-card"
                        >
                            <Row align="middle" justify="center">
                                <Col span={22}>
                                    <div>
                                        Arraste a Imagem ou
                                    </div>
                                    <Button type="link" block icon={<DownloadOutlined />}>
                                        Clique Aqui
                                    </Button>
                                </Col>
                            </Row>
                        </Upload>
                    </Form.Item>

                </Col>
            </Row>
        </Form>
    );
}
