import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Tag, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { ModalEndereco } from "../../components/modals/";
import { isObjetoDiffVazio, validarDadosObj } from "../../services/funcoes";

export default function Endereco({produtorRural = false, isEstrangeiro, setIsEstrangeiro }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosEndereco, setDadosEndereco] = useState(!!manutencao.dados && !!manutencao.dados.enderecos ? manutencao.dados.enderecos : []);
    const [form] = Form.useForm();
    const [openModalEndereco, setOpenModalEndereco] = useState(false);
    const [exibirPais, setExibirPais] = useState(true);

    const alterarPropriedadeEndereco = (key, coluna, checked) => {
        let newData = [...dadosEndereco];
        if (coluna === 'pee_enderecoprincipal' && checked) {
            let indexPrincipal = newData.findIndex((item) => true === item.pee_enderecoprincipal);
            if (indexPrincipal !== -1) {
                newData[indexPrincipal][coluna] = false;
            }
            newData[key][coluna] = checked;
        } else {
            let count = 0;
            newData.forEach(dados => {
                if (dados[coluna]) {
                    count++;
                }
            });
            if (count > 1 || (checked && coluna === 'pee_ativo')) {
                newData[key][coluna] = checked;
            }
        }
        setDadosEndereco(newData);
    };

    const editEndereco = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalEndereco(true);
    };

    const removerEndereco = (record, indice) => {
        let dadosTemp = [...dadosEndereco];
        if (dadosTemp.length > 1) {
            dadosTemp = dadosTemp.filter((dados) => dados !== record);
            setDadosEndereco(dadosTemp);
        } else {
            Modal.warning({
                title: 'Aviso',
                icon: <ExclamationCircleOutlined />,
                content: "Deve se manter ao menos um endereço cadastrado!",
                okText: 'Ok',
                centered: true

            });
        }
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ enderecos: dadosEndereco } } } });
    }, [dadosEndereco]);

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.enderecos) {
                setDadosEndereco(manutencao.dados.enderecos);
            }
        }
    }, [manutencao.dados]);

    return (

        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço(s)" /> Endereço(s)
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione o(s) endereço(s) do cliente aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalEndereco(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={
                    [
                        {
                            title: 'Endereço',
                            width: 420,
                            render: (record) => (
                                <div>
                                    <b>
                                        {record.log_logradouro}
                                        {(record.pee_numero !== '' && !!record.pee_numero) ? ', ' + record.pee_numero : ''}
                                        {(record.bai_nome !== '' && !!record.bai_nome) ? ', ' + record.bai_nome : ''}
                                        {(record.pee_complemento !== '' && !!record.pee_complemento) ? ', ' + record.pee_complemento : ''}
                                        {(record.cep !== '' && !!record.cep) ? ' - ' + record.cep :
                                            (record.cep_cep !== '' && !!record.cep_cep) ? ' - ' + record.cep_cep : ''}
                                        {(record.cid_descricao !== '' && !!record.cid_descricao) ? ' - ' + record.cid_descricao : ''}

                                        {(record.est_sigla !== '' && !!record.est_sigla) ? ' - ' + record.est_sigla : ''}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo de Endereço',
                            width: 80,
                            render: ({ tpe_descricao }) => (
                                <div>
                                    <b>{tpe_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: '',
                            width: 80,
                            align: 'center',
                            render: (record, obj, indice) => (
                                <Tag.CheckableTag
                                    key="pee_enderecoprincipal"
                                    checked={record.pee_enderecoprincipal}
                                    onChange={(checked) => alterarPropriedadeEndereco(indice, "pee_enderecoprincipal", checked)}
                                >
                                    Principal
                                </Tag.CheckableTag>
                            ),
                        },
                        {
                            title: '',
                            width: 80,
                            align: 'center',
                            render: (record, obj, indice) => (
                                <Tag.CheckableTag
                                    key="pee_ativo"
                                    checked={record.pee_ativo}
                                    onChange={(checked) => alterarPropriedadeEndereco(indice, "pee_ativo", checked)}
                                >
                                    Ativo
                                </Tag.CheckableTag>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record, obj, indice) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => editEndereco(record, indice)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => removerEndereco(record, indice)} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Ainda não possui Endereço Cadastrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} dataSource={dadosEndereco} scroll={{ x: 960 }} />
            </div>
            <ModalEndereco form={form} listaEndereco={{ dadosEndereco, setDadosEndereco }} exibirModalEndereco={openModalEndereco} fecharModalEndereco={() => setOpenModalEndereco(false)} 
            exibirPais={exibirPais} produtorRural={produtorRural} isEstrangeiro={isEstrangeiro} setIsEstrangeiro={setIsEstrangeiro} />
        </div>

    );

}