import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select, Spin, Steps, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useStateValue } from '../../state';
import { Data, FormGW, SelectPaginacao } from '../../components';
import api from '../../services/api';
import TomadorNFSE from './tomadorNFSE';
import { HasValue, isObjetoDiffVazio, novaAbaNavegador, validaForm, validarNumeros } from '../../services/funcoes';
import ServicosNFSE from './servicosNFSE';
import FaturamentoNFSE from './faturamentoNFSE';
import ResumoNFSE from './resumoNFSE';
import { getCnpjEmpresa, setDadosIntegra } from '../../services/auth';
import apiPayer from '../../services/apiPayer';
import moment from 'moment';
import { selectPaginadoActions } from '../../actions';
import FormaDePagamento from '../../components/enuns/formaDePagamento';

export default function NFServico({ form, showDrawer, carregando, aoSalvar, pages, proximaPag, resume, showDrawerExtra }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [dadosItens, setDadosItens] = useState([]);
    const [listaSerie, setListaSerie] = useState([]);
    const [infoEndereco, setInfoEndereco] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [dadosVendedor, setDadosVendedor] = useState({});
    const listaValidacoesTom = [
        { nome: 'ope_id', obrigatorio: true, label: 'Operação' },
        { nome: 'pes_id', obrigatorio: true, label: 'Destinatário/Tomador' },
        { nome: 'cep_cep', obrigatorio: true, label: 'Cep' },
        { nome: 'ntf_endereco', obrigatorio: true, label: 'Endereço' },
    ];

    const statusPagamentoIntegraPagto = {
        Aprovada: 1,
        Rejeitada: 2,
        Pendente: 3,
        Cancelada: 4,
        Abortada: 5,
        Finalizada: 6,
        NaoAutorizada: 7,
    }

    var modalInstance = null;

    const popularOperacaoFiscal = (id) => {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    }

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.faturamento) {
                setListaFormaPagamento(manutencao.dados.faturamento);
            }
            if (!!manutencao.dados.pes_id && (!!!manutencao.dados.destinatario || (manutencao.dados.destinatario.pes_id !== manutencao.dados.pes_id))) {
                let lista = [];
                lista.push({ name: "ope_id", campo: "CodigoOperacao", value: manutencao.dados.ope_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                popularOperacaoFiscal(manutencao.dados.ope_id);
            }
            if (!!manutencao.dados && !!manutencao.dados.itensNf) {
                if (dadosItens.length === 0) {
                    setDadosItens(manutencao.dados.itensNf);
                }
            }
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (showDrawer) {
            carregarDados();
            if (!!manutencao.dados && !!manutencao.dados.ntf_id) {
                form.setFieldsValue(manutencao.dados);
                setEditando(true);
            } else {
                setEditando(false);
            }
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [showDrawer]);

    useEffect(() => {
        if (!!showDrawerExtra) {
            carregarDados();
            form.setFieldsValue(manutencao.dados);
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
        if (!!showDrawerExtra && !!form.getFieldValue().pes_id) {
            let lista = [];
            if (!!form.getFieldValue().pes_id) {
                lista.push({ name: 'pes_id', campo: 'Filtro', value: form.getFieldValue().pes_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            form.setFieldsValue(manutencao.dados);
        }
    }, [showDrawerExtra]);

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag]);

    async function carregarDados() {
        setCarregarPagina(true);
        let listaVendedor = await api.get('Vendedor/ListarVendedor');
        if (listaVendedor.status === 200) {
            setVendedores(listaVendedor.data.items);
        }
        let listaSerie = await api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=98&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setListaSerie(serie);
            if (serie.length > 0) {
                form.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }
        setCarregarPagina(false);
    }

    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                listaValidacoes = listaValidacoesTom;
                break;
            case 1:
                if (paginaAtual < pagina) {
                    if (!!dadosItens && dadosItens.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Nota Fiscal deve ter ao menos 1 serviço!' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (!!dadosItens && dadosItens.length > 0) {
                        let naoTemCfop = dadosItens.some(item => item.cfo_cfop === null);
                        if (naoTemCfop) {
                            notification.warning({ message: 'Aviso', description: 'Campo CFOP do serviço adicionado é obrigatório!' });
                            setPermiteValidar(false);
                            proximaPag.setProximaPag(paginaAtual);
                            return false;
                        }
                    }
                }
                break;
            case 2:
                if (paginaAtual < pagina) {
                    listaValidacoes = [];
                    let validaFaturamento = await validarFaturamento();
                    if (validaFaturamento === false || listaFormaPagamento.length === 0) {
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    }

    async function validarFaturamento() {
        let formulario = form.getFieldsValue();
        let retorno = true;
        let valorTotal = 0;
        let operacao = operacaoFiscal;
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach(forma => {
                valorTotal += parseFloat(forma.pfp_valor);
                if (forma.editando == true) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor salvar as alterações em andamento nas parcelas(forma pagamento) para continuar!' });
                }
            });
            if (parseFloat(formulario.valorTotal) === 0) {
                retorno = false;
                notification.warning({ message: 'Aviso!', description: 'Valor da Nota Inválido!' });
            } else {
                if ((validarNumeros(formulario.valorTotal) !== parseFloat(valorTotal.toFixed(2))) || operacao.tip_id == !5) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Valor informado nas formas de pagamento é menor que o valor total da nota!' });
                }
            }
        } else {
            if (!!formulario.fpg_id) {
                let formaPagamento = (await api.get(`FormaPagamento/Get?id=${formulario.fpg_id}`)).data;
                if (!listaFormaPagamento.cpg_id) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
                else if (formaPagamento.fpg_tipoavistaaprazo === 1) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
            } else {
                retorno = false;
                notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
            }
        }
        return retorno;
    }

    function alterarVendedor() {
        let dadosVen = vendedores.filter((ven) => (ven.ven_id === parseInt(form.getFieldValue().ven_id)))[0];
        setDadosVendedor(dadosVen);
    }

    async function validarNota(dados) {
        dados = await popularDados();
        dados.gerarBoleto = false;
        dados.ntf_ufdest = dados.notaFiscalDestinatario?.ntf_ufdest;
        dados.notaFiscalFaturamento.forEach((formasPgto) => {
            if (formasPgto.fpg_tipopagamento === FormaDePagamento.fpBoletoBancario) {
                dados.gerarBoleto = true;
            }
        });
        salvarNota(dados);
    }

    async function popularDados() {
        let valores = form.getFieldsValue();
        let dados = form.getFieldValue();
        valores.ntf_docfiscal = !!dados.ser_id;
        valores.cid_idPrestacaoServico = dados.mun_incidencia;
        valores.notafiscalitens = popularDadosItens();
        valores.notaFiscalFaturamento = preencherDadosFaturamento();
        valores.notaFiscalDestinatario = await preencherDadosDestinatario(dados);
        valores.tipooperacao = operacaoFiscal;
        valores.ope_gerarespelho = operacaoFiscal.ope_gerarespelho;
        valores.serieFiscal = preencherDadosSerieFiscal(dados);
        return valores;
    }

    function popularDadosItens() {
        dadosItens.forEach((itens) => {
            itens.nfi_ncm = "00000000";
            itens.nfi_numeropedidocompra = !!itens.nfi_numeropedidocompra ? itens.nfi_numeropedidocompra.toString() : '';
            if (itens.pdi_id) {
                itens.itempedidonota = [{ pdi_id: itens.pdi_id }]
            }
        })
        return dadosItens;
    }

    function preencherDadosFaturamento() {
        let dadosForma = [];
        let recebimentoCartao = !!manutencao.dados.recebimentoCartao ? manutencao.dados.recebimentoCartao[0] : null;
        listaFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.fpg_id = forma.fpg_id;
            aux.cpg_id = forma.cpg_id;
            if (forma.fpg_tipopagamento === FormaDePagamento.fpCheque && !!manutencao.dados.dadosModalCheque) {
                aux.ctc_id = manutencao.dados.dadosModalCheque.contaCorrente;
                aux.cheques = manutencao.dados.dadosModalCheque.cheques;
            }
            aux.nfp_valor = parseFloat(forma.pfp_valor);
            aux.bce_id = !!recebimentoCartao ? recebimentoCartao.bce_id : null;
            aux.rcc_nsu = !!recebimentoCartao ? recebimentoCartao.rcc_nsu : null;
            if (forma.fpg_tipopagamento === FormaDePagamento.fpValePresente || forma.fpg_tipopagamento === FormaDePagamento.fpCreditoEmLoja) {
                aux.antecipacoes = forma.antecipacoes;
            } else {
                aux.antecipacoes = [];
            }
            dadosForma.push(aux);
        });
        return dadosForma;
    }

    async function preencherDadosDestinatario(form) {
        let dadosDestinatario = {};
        let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${form.pes_id}`)).data;
        let enderecoDest = infoEndereco;
        let telefoneDest = cliente.telefones?.filter(pt => pt.psc_id === form.telefoneDestinatario)[0];
        let emailDest = cliente.emails?.filter(pem => pem.pem_id === form.ntf_emaildestinatario)[0];
        dadosDestinatario.pes_id = form.pes_id;
        dadosDestinatario.pes_fisicajuridica = cliente.pes_fisicajuridica;
        dadosDestinatario.ntf_nomedest = cliente.pes_nome;
        dadosDestinatario.ntf_cnpjcpfdest = (!!cliente.pej_cnpj ? cliente.pej_cnpj : cliente.pef_cpf);
        dadosDestinatario.ntf_iedest = form.pej_inscricaoestadual;
        dadosDestinatario.ntf_idestrangeirodest = cliente.pes_idestrangeiro;
        dadosDestinatario.ntf_logradourodest = enderecoDest?.log_logradouro;
        dadosDestinatario.ntf_numeroenderecodest = enderecoDest?.pee_numero ?? form.pee_numero;
        dadosDestinatario.ntf_complementodest = enderecoDest?.complemento ?? form.pee_complemento;
        dadosDestinatario.ntf_bairrodest = enderecoDest?.bai_nome;
        dadosDestinatario.ntf_cepdest = enderecoDest?.cep_cep;
        dadosDestinatario.ntf_ufdest = enderecoDest?.est_sigla;
        dadosDestinatario.ntf_telefonedest = telefoneDest?.psc_numero ;
        dadosDestinatario.ntf_emaildest = emailDest?.pem_email ?? null;
        return dadosDestinatario;
    }

    function preencherDadosSerieFiscal(dados) {
        let dadosSerieFiscal = listaSerie.filter((item) => (item.ser_id === dados.ser_id))[0];
        return dadosSerieFiscal;
    }

    async function salvarNota(dados) {
        if (dados.notaFiscalDestinatario.pes_fisicajuridica === 1) {
            dados.ntf_consumidorfinal = 1;
        } 
        // else {
        //     if (dados.ntf_consumidorfinal !== 0 && dados.ntf_consumidorfinal !== 1) {
        //         notification.warning({ message: 'Aviso', description: 'Não foi selecionado o destino da mercadoria!' });
        //         return;
        //     }
        // }
        // dados.ntf_modalidadefrete = 9;
        await analisaFormaPagamento(dados);
    }

    async function analisaFormaPagamento(dados) {
        let pagamentoMaquininha = false;
        let dadosIntegracaoPos = {};
        let cnpjEmpresa = getCnpjEmpresa();
        let listaIdsIntegracao = [];
        for (const item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito || item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito || item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
                if (item.fpg_tipointegracao != null) {
                    if (item.fpg_tipointegracao == 1) {// vero
                        let tipopgto = 0;
                        let metodopgto = 0;
                        let submetodopgto = 0;

                        if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito) {
                            tipopgto = 2;
                            metodopgto = 1;
                            submetodopgto = 4;
                            if (item.parcelas?.length == 1) {
                                submetodopgto = 1
                            }
                        }
                        if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito) {
                            tipopgto = 3;
                            metodopgto = 1;
                            submetodopgto = 3;
                            if (item.parcelas?.length == 1) {
                                submetodopgto = 1
                            }
                        }
                        if (item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
                            tipopgto = 1;
                            metodopgto = 3;
                            submetodopgto = 1
                        }
                        dadosIntegracaoPos = {
                            imp_cnpjlocal: cnpjEmpresa,
                            imp_valor: item.pfp_valor,
                            imp_tipopgto: tipopgto,
                            imp_metodopgto: metodopgto,
                            imp_submetodopgto: submetodopgto,
                            imp_qtdeparcelas: item.parcelas?.length,
                        }
                        if (!isObjetoDiffVazio(item.integrapgto)) {
                            item.integrapgtook = false;
                            item.integrapgto = await criaRegistroIntegraPagto(dadosIntegracaoPos);
                            listaIdsIntegracao.push(item.integrapgto);
                        }
                        item.imp_id = item.integrapgto.imp_id;
                    } else if (item.fpg_tipointegracao == 2) {//payer localhost
                        await pagamentoPayer(item);
                        pagamentoMaquininha = true;
                        break;
                    }
                    else if (item.fpg_tipointegracao == 4) {//payer Gateway
                        await pagamentoPayer(item);
                        pagamentoMaquininha = true;
                        break;
                    }
                    pagamentoMaquininha = true;
                } else {
                    pagamentoMaquininha = false;
                }
            }
        }
        if (pagamentoMaquininha) {
            setDadosIntegra(dados);
            modalAguardandoPagamento(dados);
        } else {
            concluirVenda(dados);
        }
    }

    async function criaRegistroIntegraPagto(dadosIntegracaoPos) {
        let retorno;
        try {
            retorno = (await api.post(`IntegraPagto/Incluir`, dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Erro ao mandar o pagamento para a máquina!', message: 'AVISO!' });
            console.log(error);
            return false;
        }
        return retorno;
    }

    async function pagamentoPayer(item) {
        let dadosIntegracaoPos = {};
        let _paymentType = "";
        let _paymentMethodSubType = "";
        let _installments = "";
        let _paymentMethod = "";
        let _command = "payment"

        if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito) {
            _paymentMethod = "Card";
            _paymentType = "Credit";
            _paymentMethodSubType = "Financed_no_Fees";

            if (item.parcelas?.length == 1) {
                _paymentMethodSubType = "Full_Payment";
            } else {
                _installments = (item.parcelas?.length).toString();
            }
        }
        if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito) {
            if (item.parcelas?.length > 1) {
                _paymentMethodSubType = "Financed_Debit";
                _installments = (item.parcelas?.length).toString();
            } else {
                _paymentMethodSubType = "Full_Payment";
            }
            _paymentMethod = "Card";
            _paymentType = "Debit"
        }
        if (item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
            _paymentType = "Cash";
            _paymentMethod = "Pix";
        }

        dadosIntegracaoPos = {
            command: _command,
            value: parseFloat(item.pfp_valor),
            paymentMethod: _paymentMethod,
            paymentType: _paymentType,
            paymentMethodSubType: _paymentMethodSubType,
            installments: _installments
        };
        if (!isObjetoDiffVazio(item.integrapgto)) {
            if (item.fpg_tipointegracao == 2) {//payer localhost
                try {
                    let retorno = await apiPayer.post('Client/Request', dadosIntegracaoPos);
                    item.integrapgtook = false;
                } catch (error) {
                    notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
                }
            }
            if (item.fpg_tipointegracao == 4) {//payer Gateway
                try {
                    dadosIntegracaoPos.installments = parseInt(dadosIntegracaoPos.installments);
                    let retorno = (await api.post(`Payer/IncluirPayerRequest`, dadosIntegracaoPos)).data;
                    item.integrapgtook = false;
                    item.integrapgto = retorno;
                } catch (error) {
                    if (!!error.response && !!error.response.data)
                        notification.warning({ description: error.response.data[0], message: 'AVISO!' });
                    else
                        notification.warning({ description: 'Erro ao mandar o pagamento para API Payer!', message: 'AVISO!' });
                }
            }
        }
    }

    function modalAguardandoPagamento(dados) {
        if (modalInstance !== null) {
            modalInstance.destroy();
        }
        modalInstance = Modal.confirm({
            title: 'Aviso',
            content: 'Aguardando Pagamento!',
            okText: 'Pagamento Efetuado',
            cancelText: 'Cancelar Pagamento',
            onOk: () => {
                verificarPagamentos(dados);
            },
            onCancel: () => {
                cancelarPagamentoMaquina(dados);
            }
        });
    }

    async function cancelarPagamentoMaquina(dados) {
        for (var item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito || item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito || item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
                let retorno = (await api.put(`IntegraPagto/CancelarPagamento?Id=${item.integrapgto.imp_id}`)).data;
                if (retorno) {
                    item.integrapgtook = false;
                    item.integrapgto = null;
                } else {
                    notification.warning({ description: 'Erro ao efetuar o cancelamento!', message: 'Aviso!' });
                    modalAguardandoPagamento(dados)
                    break;
                }
            }
        }
    }

    async function verificarPagamentos(dados) {
        for (var item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito || item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito || item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false || item.integrapgtook == null || item.integrapgtook == undefined) && item.fpg_tipointegracao == 1) {// Vero
                    let retorno = (await api.get(`IntegraPagto/Buscar/${item.integrapgto.imp_id}`)).data;
                    if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                        if (modalInstance !== null) {
                            modalInstance.destroy();
                        }
                        modalAguardandoPagamento(dados);
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                        item.integrapgtook = true;
                        item.integrapgto = retorno;
                        item.rcc_nsu = retorno.imp_autorizacao;
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                        notification.warning({ description: 'Pagamento não Autorizado!', message: 'Aviso!' });
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Cancelada) {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                }
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false) && item.fpg_tipointegracao == 2) {// Payer localhost
                    let retorno;
                    try {
                        retorno = (await apiPayer.get('Client/Response')).data;

                    } catch (error) {
                        notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
                        await pagamentoPayer(item);
                        break
                    }
                    if (retorno.statusTransaction == "PENDING") {
                        break
                    } else if (retorno.statusTransaction == "APPROVED") {
                        let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                        item.integrapgtook = true;
                        item.integrapgto = ret;
                        item.rcc_nsu = ret.authorizerId;
                        item.imp_id = ret.imp_id;
                    } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                        notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                        return;
                    } else if (retorno.statusTransaction == "ABORTED") {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                }
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false) && item.fpg_tipointegracao == 4) {// Payer Gateway
                    try {
                        let retorno = (await api.get(`Payer/BuscarOrdemPagamento/${item.integrapgto.imp_id}`)).data;

                        if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                            break
                        } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                            item.integrapgtook = true;
                            item.integrapgto = retorno;
                            item.rcc_nsu = retorno.imp_autorizacao;
                            item.imp_id = retorno.imp_id;
                        } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                            notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                            return;
                        } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Abortada) {
                            notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                        }
                    } catch (error) {
                        notification.warning({ description: 'Erro ao consultar o pagamento na API!', message: 'AVISO!' });
                    }


                }
                if (item.fpg_tipointegracao != null && (item.integrapgtook == null || item.integrapgtook == undefined) && (item.fpg_tipointegracao == 2 || item.fpg_tipointegracao == 4)) {
                    await pagamentoPayer(item);
                }
            }
        }
        await verificaTodosPagamentos(dados);
    }

    async function verificaTodosPagamentos(dados) {
        let finalizarVenda = true;
        let listaFaturamento = dados.notaFiscalFaturamento;
        listaFaturamento.forEach(item => {
            if (item.fpg_tipopagamento === FormaDePagamento.fpCartaoCredito || item.fpg_tipopagamento === FormaDePagamento.fpCartaoDebito || item.fpg_tipopagamento === FormaDePagamento.fpPixDinamico) {
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false || item.integrapgtook == null || item.integrapgtook == undefined)) {
                    finalizarVenda = false;
                }
            }
        });
        if (finalizarVenda == true) {
            concluirVenda(dados);
        } else {
            modalAguardandoPagamento(dados);
        }
    }

    function concluirVenda(dados) {
        if (!!form.getFieldValue().ntf_id) {
            dados.ntf_id = form.getFieldValue().ntf_id;
            editarNota(dados);
        } else {
            dados.ntf_dhsaient = moment(new Date());
            incluirNota(dados);
        }
    }

    function incluirNota(dados) {
        carregando(true);
        api.post(`NotaFiscal/Incluir`, dados).then(
            res => {
                notification.success({ message: 'Sucesso', description: `Nota incluída com sucesso!` });
                if (HasValue(dados.ser_id)) {
                    if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && res.data.ntf_docfiscal == true) {
                        window.open(res.data.controleloterps.clr_linkaprovacao);

                        let formaPagItegrado = res.data.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                        if (formaPagItegrado.length > 0) {
                            novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${res.data.ntf_id}`);
                        }
                    }
                }
                if (dados.gerarBoleto && !!res.data.ntf_id) {
                    novaAbaNavegador(`Boleto/ImprimirBoletos/${res.data.ntf_id}`);
                }
                //COMENTADO POIS ESSE TRATAMENTO ESTÁ SENDO FEITO NO BACK
                // if (res.status === 200 && !!dados.ped_id) {
                //     tratarPedidoFaturado(dados.ped_id);
                // }
                if (res.status === 200 && !!dados.vnc_id) {
                    AlterarSituacaoCondicional(dados.vnc_id);
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data) {
                    Modal.warning({
                        content: erro.response.data,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    }

    function editarNota(dados) {
        carregando(true);
        api.put(`NotaFiscal/Editar`, dados).then(
            res => {
                notification.success({ message: 'Sucesso', description: `Nota editada com sucesso!` });
                if (HasValue(dados.ser_id)) {
                    if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && res.data.ntf_docfiscal == true) {
                        window.open(res.data.controleloterps.clr_linkaprovacao);
                    }
                }
                if (dados.gerarBoleto && !!res.data.ntf_id) {
                    novaAbaNavegador(`Boleto/ImprimirBoletos/${res.data.ntf_id}`);
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    Modal.warning({
                        content: erro.response.data.Message,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    }


    async function tratarPedidoFaturado(id) {
        api.put(`Pedido/TratarHistoricoPedidoFaturado/${id}`).then(
            ok => {
                console.log(ok);
            }).catch(
                error => {
                    console.log(error)
                }).finally(() => {
                    aoSalvar();
                });
    }

    async function AlterarSituacaoCondicional(id) {
        api.put(`VendaCondicional/AlterarSituacaoCondicional?IdCondicional=${id}&SituacaoCondicional=2`).then(
            ok => {
                console.log(ok);
            }).catch(
                error => {
                    console.log(error)
                }).finally(() => {
                    aoSalvar();
                });
    }

    return (
        <div className='pages-col'>
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" name="formNotaFiscalServico" form={form} onFinish={validarNota}>
                    <Row gutter={[8, 0]}>
                        {!!editando && <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                            <Form.Item label="N° da Nota" name="ntf_numero">
                                <Input disabled />
                            </Form.Item>
                        </Col>}
                        <Form.Item hidden name="ped_id"></Form.Item>
                        <Col xs={24} sm={8} md={3} lg={4} xl={4}>
                            <Form.Item label="Série" name="ser_id" rules={[{ required: true, message: 'Informe uma série para emissão de nota de serviço' }]}>
                                <Select disabled={editando} allowClear placeholder="Selecione uma Série">
                                    {listaSerie.map((ser) => (
                                        <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={editando ? 8 : 6} md={4} lg={4} xl={4}>
                            <Data label="Data de Emissão" disabled={editando} name="ntf_dataemissao" initialValue={new Date()} />
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={8}>
                            <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                                <SelectPaginacao url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true&EntradaSaida=1"
                                    placeholder="Selecione a Operação Fiscal"
                                    form={form}
                                    nameValue="ope_id"
                                    nameLabel="ope_descricao"
                                    onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)}
                                    conteudo={
                                        op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={8} xl={8}>
                            <Form.Item label="Vendedor" name="ven_id">
                                <Select placeholder="Selecione o Vendedor" onChange={() => { alterarVendedor() }}>
                                    {vendedores.map((vendedor) => (<Select.Option value={vendedor.ven_id} key={vendedor.ven_id}>{vendedor.pes_nome}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size='small' type='navigation' onChange={(e) => {
                                proximaPag.setProximaPag(e);
                            }} current={pages.tagPages}>
                                <Steps.Step title="Destinatário/Tomador" />
                                <Steps.Step title="Serviço" />
                                <Steps.Step title="Faturamento" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            {pages.tagPages === 0 &&
                                <TomadorNFSE form={form} setInfoEndereco={setInfoEndereco} carregarPagina={carregarPagina} showDrawerExtra={showDrawerExtra} />
                            }
                            {pages.tagPages === 1 &&
                                <ServicosNFSE form={form} dadosItens={{ dadosItens, setDadosItens }} infoEndereco={infoEndereco} operacaoFiscal={operacaoFiscal} dadosVendedor={dadosVendedor}  
                                    carregarPagina={setCarregarPagina} />
                            }
                            {pages.tagPages === 2 &&
                                <FaturamentoNFSE form={form} dadosItens={dadosItens} dadosVendedor={dadosVendedor} operacaoFiscal={operacaoFiscal} resume={resume} />
                            }
                            {pages.tagPages === 3 &&
                                <ResumoNFSE form={form} itensNota={dadosItens} resume={resume} />
                            }
                        </Col>
                    </Row>
                </FormGW>
            </Spin>
        </div>
    );
}