import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Tag, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormatFone } from "../../ValueObjects";
import { manutencaoActions } from "../../actions";
import { ModalTelefone } from "../../components/modals/";

export default function Telefone({ isEstrangeiro = false }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosTelefone, setDadosTelefone] = useState(!!manutencao.dados && !!manutencao.dados.telefones ? manutencao.dados.telefones : []);
    const [form] = Form.useForm();
    const [openModalTelefone, setOpenModalTelefone] = useState(false);
    const tagPrincipal = ['Principal'];
    const tagsAtivo = ['Ativo'];
    const [editingKey, setEditingKey] = useState('');
    const isEditing = record => record.key === editingKey;

    const alterarPropriedadeTelefone = (key, coluna, checked) => {
        const newData = [...dadosTelefone];
        if (coluna === 'psc_principal' && checked) {
            const indexPrincipal = newData.findIndex((item) => true === item.psc_principal);
            if (indexPrincipal !== -1) {
                newData[indexPrincipal][coluna] = false;
            }
            newData[key][coluna] = checked;
        } else {
            let count = 0;
            newData.forEach(dados => {
                if (dados[coluna]) {
                    count++;
                }
            });
            if (count > 1 || (checked && coluna === 'psc_ativo')) {
                newData[key][coluna] = checked;
            }
        }
        setDadosTelefone(newData);
    };

    const editaTelefone = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalTelefone(true);
    };

    const removeTelefone = (record, indice) => {
        const newData = [...dadosTelefone];
        const index = newData.findIndex((item) => record.psc_numero === item.psc_numero);
        newData.splice(index, 1)
        setDadosTelefone(newData);
    };

    const modalExcluirTelefone = (record, indice) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o Telefone ${record.psc_numero} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                removeTelefone(record, indice);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ telefones: dadosTelefone } } } });
    }, [dadosTelefone]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.telefones) {
            setDadosTelefone(manutencao.dados.telefones)
        }
    }, [manutencao.dados]);

    const columns = [
        {
            title: 'Telefone',
            render: ({ psc_numero }) => (
                <div>
                    <b>{(!isEstrangeiro ? FormatFone(psc_numero) : (psc_numero))}</b>
                </div>
            )
        },
        {
            title: 'Tipo de Telefone',
            dataIndex: 'tpt_descricao',
            Key: 'tpt_descricao',
            editable: true,
        },
        {
            align: 'center',
            render: (record, obj, indice) => (
                tagPrincipal.map(tag => (
                    <Tag.CheckableTag
                        key="psc_principal"
                        checked={record.psc_principal}
                        onChange={(checked) => alterarPropriedadeTelefone(indice, "psc_principal", checked)}
                    >
                        {tag}
                    </Tag.CheckableTag>
                ))
            ),
        },
        {
            align: 'center',
            render: (record, obj, indice) => (
                tagsAtivo.map(tag => (
                    <Tag.CheckableTag
                        key="psc_ativo"
                        checked={record.psc_ativo}
                        onChange={(checked) => alterarPropriedadeTelefone(indice, "psc_ativo", checked)}
                    >
                        {tag}
                    </Tag.CheckableTag>
                ))
            ),
        },
        {
            title: 'Ramal',
            editable: true,
            render: ({ psc_ramal }) => (
                <div>
                    <b>{psc_ramal || 'Não Informado'}</b>
                </div>

            ),
        },
        {
            title: 'Observação',
            editable: true,
            render: ({ psc_observacao }) => (
                <div>
                    <b>{psc_observacao || 'Não Informado'}</b>
                </div>
            ),
        },
        {
            title: 'Ações',
            dataIndex: '',
            align: 'center',
            key: 'x',
            width: 65,
            fixed: 'right',
            render: (record, obj, indice) => (
                <div>
                    <Row align="middle" justify="center" gutter={[5, 0]}>
                        <Col>
                            <Button onClick={() => editaTelefone(record, indice)} icon={<EditOutlined />} />
                        </Col>
                        <Col>
                            <Button onClick={() => modalExcluirTelefone(record, indice)} icon={<DeleteOutlined />} />
                        </Col>
                    </Row>
                </div>
            ),
        },
    ];


    const mergedColumns = columns.map(col => {

        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };

    });

    return (

        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-telefone.png").default} alt="Telefone(s)" /> Telefone(s)
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione o(s) telefone(s) do cliente aqui.
                    </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalTelefone(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Form form={form} component={false}>
                    <Table columns={mergedColumns}
                        rowClassName="editable-row"
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Ainda não possui Telefone Cadastrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 960 }} dataSource={dadosTelefone}
                    />
                </Form>
            </div>
            <ModalTelefone form={form} listaTelefones={{ dadosTelefone, setDadosTelefone }} exibirModalTelefone={openModalTelefone} fecharModalTelefone={() => setOpenModalTelefone(false)} isEstrangeiro={isEstrangeiro} />
        </div>
    );
}