import React, { useEffect, useState } from "react";
import { useStateValue } from "../../state";
import { Button, Col, Divider, Form, Row, Select, Steps, Table, Typography } from "antd";
import { Data, FormGW } from "../../components";
import { validaForm } from "../../services/funcoes";
import { PlusOutlined } from "@ant-design/icons";
import { TabRemetenteCTe } from "./tabPage";
import ModalDocsFiscaisCTe from "../../components/modals/modalDocsFiscaisCTe";
import moment from "moment";
import api from "../../services/api";

export default function ManutencaoConhecimentoTransporte({ formConhecimento, carregando, aoSalvar, pages, proximaPag }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [listaDocs, setListaDocs] = useState([]);
    const [serie, setSerie] = useState([]);
    const listaValidacoesAutores = [
        { nome: 'pes_remetente', obrigatorio: true, label: 'Remetente' },
        { nome: 'pes_destinatario', obrigatorio: true, label: 'Destinatário' },
        { nome: 'endereco_remetente', obrigatorio: true, label: 'Endereço Remetente' },
        { nome: 'endereco_destinatario', obrigatorio: true, label: 'Endereço Destinatário' }
    ]

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!formConhecimento.getFieldValue().cte_dataemissao) {
                formConhecimento.setFieldsValue({ cte_dataemissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer])

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag])

    async function carregarDados() {
        let listaSerie = await api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=57&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setSerie(serie);
            if (serie.length > 0) {
                formConhecimento.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }
    }

    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                // if (paginaAtual < pagina) {
                //     if (listaDocs.length === 0) {
                //         notification.warning({ message: 'Atenção!', description: 'Para prosseguir, deve ter pelo menos um Documento Fiscal.' })
                //         setPermiteValidar(false);
                //         proximaPag.setProximaPag(paginaAtual);
                //         return false;
                //     }
                // }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    listaValidacoes = listaValidacoesAutores;
                }
                break;
            case 2:
                break;
        }
        if (validaForm(formConhecimento, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    }

    async function emitirCTe() {

    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formConhecimento} name="formConhecimento" onFinish={emitirCTe}>
                <Row gutter={[8, 0]} hidden={pages.tagPages === 1}>
                    <Col xs={24} sm={8} md={3} lg={4} xl={3}>
                        <Form.Item label="Série" name="ser_id">
                            <Select allowClear placeholder="Selecione uma Série">
                                {serie.map((ser) => (
                                    <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                        <Data label="Data de Emissão" name="mdf_dataemissao" />
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" onChange={(e) => {
                            proximaPag.setProximaPag(e);
                        }} current={pages.tagPages}>
                            <Steps.Step title="Documentos Fiscais" />
                            <Steps.Step title="Remetente/Destinatário" />
                            <Steps.Step title="Dados Gerais" />
                            <Steps.Step title="" />
                        </Steps>
                    </Col>
                </Row>
                <Row gutter={[8, 24]}>
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <div className="m-t-16">
                                <Row align="middle">
                                    <Col span={24}>
                                        <Typography.Title level={3}>
                                            <img src={require("../../assets/i-info.png").default} alt="Adicione os Documentos Fiscais do CT-e" />
                                            Adicione os Documentos Fiscais do CT-e
                                        </Typography.Title>
                                        <Divider orientation="left" plain>Adicione os Documentos Fiscais do CT-e</Divider>
                                    </Col>
                                </Row>
                                <Form layout="vertical" form={form}>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col style={{ marginTop: '14px' }}>
                                            <Button type="primary" size="small" icon={<PlusOutlined />} onClick={() => { form.resetFields(); setOpenModal(true); }} style={{ marginBottom: '-3px' }}> ADICIONAR DOCUMENTO </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="tabela mt-dif">
                                    <Form form={form} component={false}>
                                        <Table />
                                    </Form>
                                </div>
                                <ModalDocsFiscaisCTe form={form} exibirModal={openModal} fecharModal={() => setOpenModal(false)} listaDocs={listaDocs} setListaDocs={setListaDocs} />
                            </div>
                        }
                        {pages.tagPages === 1 &&
                            <TabRemetenteCTe formAutores={formConhecimento} />
                        }
                    </Col>
                </Row>
            </FormGW>
        </div>
    )
}