import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Table, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import { FiltroProdutoNfi } from "../../components";
import { FormatNumber } from '../../ValueObjects';

export default function TabItens({ form, dadosItens, dadosEndereco, dadosOperacao, dadosContribuinte, dadosVendedor, carregarPagina }) {

    const [quantidadeItens, setQuantidadeItens] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});

    useEffect(() => {
        if (dadosItens.dadosItens.length > 0) {
            let total = 0;
            let totalSt = 0;
            setQuantidadeItens(dadosItens.dadosItens.length);
            dadosItens.dadosItens.forEach((item) => {
                total += parseFloat(item.total);
                totalSt += parseFloat(item.nfi_valoricmsst);
            });
            form.setFieldsValue({ valorTotalSt: totalSt });
            setValorTotal(total);
        } else {
            setQuantidadeItens(0);
            setValorTotal(0);
        }
    }, [dadosItens.dadosItens]);

    function editarItemNota(registro) {
        console.log("Clicou no Editar:", registro);
        setDadosEditando(registro);
        setEditando(true);
    };

    function removerItemNota(record, indice) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover o item da nota?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let dadosTemp = [...dadosItens.dadosItens];
                if (record.nfi_numeroitem) {
                    let dadosRet = dadosTemp.filter((item) => item.nfi_numeroitem !== record.nfi_numeroitem);
                    dadosItens.setDadosItens(dadosRet);
                } else if (record.pdi_numeroitem) {
                    let dadosRet = dadosTemp.filter((item) => item.pdi_numeroitem !== record.pdi_numeroitem);
                    dadosItens.setDadosItens(dadosRet);
                }
            }
        });
    };

    return (
        <div className="m-t-16">
            <FiltroProdutoNfi form={form} dadosTabela={dadosItens} dadosEndereco={dadosEndereco} dadosOperacao={dadosOperacao} dadosVendedor={dadosVendedor} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} carregarPagina={carregarPagina} />
            <div className="tabela mt-dif">
                {!!dadosItens.dadosItens && dadosItens.dadosItens.length > 0 &&
                    <Table
                        columns={[
                            {
                                title: 'ITEM',
                                width: '30%',
                                fixed: 'left',
                                render: ({ pro_codigo, pro_descricao, nfi_ean, grade }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                <Tag color="processing" className="w-75">
                                                    <b>{pro_codigo}</b>
                                                </Tag>
                                                <b> {pro_descricao}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                EAN: <b>{nfi_ean}</b>
                                            </Col>
                                            <Col hidden={!!!grade}>
                                                Grade: <b>{grade}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'CFOP',
                                align: 'center',
                                width: '3%',
                                fixed: 'left',
                                render: ({ nfi_cfop }) => (
                                    <div>
                                        <b>{nfi_cfop}</b>
                                    </div>
                                ),
                            },
                            {
                                title: `${dadosContribuinte.emp_regimetributario === 1 ? 'CSOSN' : 'CST'}`,
                                align: 'center',
                                width: '4%',
                                fixed: 'left',
                                render: ({ cst_id, cso_id }) => (
                                    <div>
                                        <Col >
                                            <b>{dadosContribuinte.emp_regimetributario === 1 ? cso_id : cst_id}</b>
                                        </Col>
                                    </div>
                                ),
                            },
                            {
                                title: 'Qtde.',
                                width: '4%',
                                align: 'right',
                                fixed: 'left',
                                render: ({ nfi_qtde }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_qtde, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Unt.(R$)',
                                align: 'right',
                                width: '5%',
                                fixed: 'left',
                                render: ({ nfi_valorunitario }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Desc.',
                                align: 'right',
                                width: '4%',
                                render: ({ percdesc }) => (
                                    <div>
                                        <b>{percdesc}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Acres.',
                                align: 'right',
                                width: '4%',
                                render: ({ percacresc }) => (
                                    <div>
                                        <b>{percacresc}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Frete',
                                align: 'right',
                                width: '4%',
                                render: ({ nfi_valorfrete }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorfrete, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_baseIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_aliquotaIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_valorIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_baseIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_aliquotaIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_valorIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: '6%',
                                fixed: 'right',
                                render: ({ total }) => (
                                    <div>
                                        <b>{FormatNumber(total, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: '5%',
                                fixed: 'right',
                                render: (record, e, indice) => (
                                    <div>
                                        <Row align="center" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarItemNota(record)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => removerItemNota(record, indice)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} dataSource={dadosItens.dadosItens}
                        scroll={{
                            x: 1500,
                            y: 300,
                        }} />}
            </div>
            <div>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Quantidade Itens:</b>
                    </Col>
                    <Col className="f-21">
                        <b>{quantidadeItens}</b>
                    </Col>
                </Row>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Valor Total dos Itens (R$):</b>
                    </Col>
                    <Col className="f-21">
                        <b>{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div >
        </div>
    );
}