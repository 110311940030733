import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Button, Modal, Table, notification, Divider } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined, MailOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { listagemActions } from "../../../actions";
import { FormatNumber } from "../../../ValueObjects";
import { formatarCpf, isObjetoDiffVazio } from "../../../services/funcoes";

export default function ModalEnvioNFeEmail({ exibirModalEnvioNFeEmail, fecharModalEnvioNFeEmail }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosNota, setDadosNota] = useState({});
    const [listaEmail, setListaEmail] = useState([]);
    const [form] = Form.useForm();
    const [modeloDoc, setModeloDoc] = useState(); ;

    useEffect(() => {
        if (!!manutencao.dados && isObjetoDiffVazio(manutencao.dados)) {
            setDadosNota(manutencao.dados);
        }
    }, [manutencao.dados]);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+dest' } }), []);
    
    useEffect(() => {
        if (dadosNota?.ntf_chavenfe) { 
            setModeloDoc(dadosNota.ntf_chavenfe.substring(20, 22));
        }
    }, [dadosNota.ntf_chavenfe]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalEnvioNFeEmail();
            }
        });
    };

    const enviarEmail = () => {
        let dados = {};
        let enderecos = [];
        listaEmail.forEach((email) => {
            enderecos.push(email.dest);
        })
        dados.ntf_id =dadosNota.ntf_id;
        dados.chaveNFe = dadosNota.ntf_chavenfe;
        dados.enderecosDestinatarios = enderecos;
        var url = "NotaFiscal/EnviarEmailXml";
        if (modeloDoc == 57) {
            url = "ConhecimentoTransporte/EnviarEmailXml"
        } 
        api.post(url, dados).then(
            (res) => {
                notification.success({ description: 'Email enviado com sucesso!', message: 'Aviso' });
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(
            () => {
                fecharModalEnvioNFeEmail()
            }
        );
    };

    function adicionarEmail() {
        let endereco = form.getFieldValue().enderecoEmail;
        if (!!endereco) {
            let lista = [...listaEmail];
            lista.push({ id: lista.length, dest: endereco });
            setListaEmail(lista);
            form.setFieldsValue({ enderecoEmail: null });
        }
    };

    function excluirEmail(record) {
        let listaE = [...listaEmail];
        let emailLista = listaE.filter((lista) => (lista.id !== record.id));
        setListaEmail(emailLista);
    };

    return (
        <Modal centered
            title={`Enviar ${modeloDoc=='57'?'/CT-e': 'NF-e/NFC-e'} por E-mail`}
            visible={exibirModalEnvioNFeEmail}
            onCancel={onCloseModal}
            onOk={enviarEmail}
            okText={
                <>
                    <MailOutlined /> Enviar E-mail
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <div>
                    <Descriptions size="small">
                        <Descriptions.Item label="Chave de Acesso">
                            <b>{dadosNota.ntf_chavenfe}</b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="Nº Doc. Fiscal">
                            <b>{dadosNota.ntf_numero}</b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="Série">
                            <b>{dadosNota.ser_serie}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Emissão">
                            <b>{!!dadosNota.ntf_dataemissao ? moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY') : null}</b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="Valor (R$)">
                            <b>{!!dadosNota.valor_total ? FormatNumber(dadosNota.valor_total, true) : null}</b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="Destinatário">
                            <b>{dadosNota.ntf_nomedest}</b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="CPF/CNPJ">
                            <b>{!!dadosNota.ntf_cnpjcpfdest ? formatarCpf(dadosNota.ntf_cnpjcpfdest) : ''}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                <Form layout="vertical" form={form} className="m-t-8">
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={16} md={18} lg={18} xl={18}>
                            <Form.Item label="Endereço de E-mail do Destinatário" name="enderecoEmail">
                                <Input placeholder="Informe o Endereço do Destinatário aqui" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={6} xl={6} className="t-mob-573"> 
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => adicionarEmail()} block>Adicionar</Button>
                        </Col>
                    </Row>
                </Form>
                <div className="tabela">
                    <Table columns={[
                        {
                            title: 'E-mail Destinatário',
                            render: ({ dest }) => (
                                <div>
                                    <b>{dest}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => { excluirEmail(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} dataSource={listaEmail} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} />
                </div>
            </div>
        </Modal>
    );
}