import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Select, Input, Button, Table, Tag, Modal, notification, Switch, InputNumber, Card, Checkbox, Tooltip } from "antd";
import { BarcodeOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, PrinterOutlined, SearchOutlined, TableOutlined } from "@ant-design/icons";
import jsreports from "jsreports/jsreports-all";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { drawerActions } from "../../actions";
import { getEmpresa } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { FormGW, SelectProduto } from "../../components";
import { ModalProdutoGrade } from "../../components/modals"
import { isNotNullOrEmpty, isObjetoDiffVazio, retornaSoNumeros, validaForm } from "../../services/funcoes";
import SelectPaginado from "../../components/selectPaginado";

export default function ImprimirEtiqueta({ formulario }) {
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [listaPreco, setListaPreco] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [dadosAdicionais, setDadosAdicionais] = useState(false);
    const [dadosEmpresa, setDadosEmpresa] = useState({});
    const [multiplosReg, setMultiplosReg] = useState(false);
    const [listaSubGrupo, setListaSubGrupo] = useState([]);
    const [dadosProduto, setDadosProduto] = useState({});
    const [filtroProd, setFiltroProd] = useState("");
    const [atualizarListaProd, setAtualizarListaProd] = useState(false);
    const [consultaProduto, setConsultaProduto] = useState(true);
    const [recordEditando, setRecordEditando] = useState({});
    const [possuiGrade, setPossuiGrade] = useState(false);
    const [abrirModalGrade, setAbrirModalGrade] = useState(false);
    const codBarras = useRef();
    const squemaEtiquetaProduto = [
        {
            nome: 'Codigo_Empresa',
            tipo: 'number'
        },
        {
            nome: 'Empresa',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Produto',
            tipo: 'number'
        },
        {
            nome: 'Produto',
            tipo: 'string'
        },
        {
            nome: 'Grade',
            tipo: 'string'
        },
        {
            nome: 'Marca',
            tipo: 'string'
        },
        {
            nome: 'Preco',
            tipo: 'string'
        },
        {
            nome: 'Ean',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Etiqueta',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Referencia',
            tipo: 'string'
        },
        {
            nome: 'Observacao',
            tipo: 'string'
        },
        {
            nome: 'Quantidade_Parcela',
            tipo: 'string'
        },
        {
            nome: 'Valor_Parcelado',
            tipo: 'string'
        }
    ];

    const listaValidacao = [
        { nome: 'modelo', obrigatorio: true, label: 'Modelo de Etiqueta' }
    ];

    const modalExcluirItem = (record) => {
        Modal.confirm({
            title: 'Remover?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente remover o item selecionado?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerItem(record);
            }
        });
    };

    const togglePesquisa = () => {
        setConsultaProduto(!consultaProduto);
    };

    useEffect(() => {
        buscarListaPreco();
        buscarDadosEmpresa();
    }, []);

    useEffect(() => {
        if (!!recordEditando && isObjetoDiffVazio(recordEditando)) {
            let dados = listaSelectPaginacao?.itens?.filter((item) => item.pro_id === recordEditando.pro_id && ((!!recordEditando.prg_id && recordEditando.prg_id === item.prg_id) || !!!recordEditando.prg_id))[0];
            if (!!dados) {
                prepararDadosEditar(recordEditando, dados);
            }
            setRecordEditando({});
        }
    }, [listaSelectPaginacao.itens]);


    function buscarListaPreco() {
        api.get('ListaPreco/ListarTodasListaPreco').then(
            res => {
                setListaPreco(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarDadosEmpresa() {
        api.get(`Empresa/Buscar/${getEmpresa()}`).then(
            res => {
                setDadosEmpresa(res.data)
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarSubGrupo(grupo) {
        api.get(`SubGrupo/ListarSemPaginacao?&Ativo=true&CodigoGrupo=${grupo}`).then(
            res => {
                setListaSubGrupo(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    async function popularDadosProdutonew(key, editando = false) {
        if (!isNotNullOrEmpty(key)) {
            setAtualizarListaProd(true);
            if (possuiGrade) {
                setPossuiGrade(false);
            }
            return;
        }
        let prod = {};
        if (!editando) {
            prod = listaSelectPaginacao?.itens?.filter(item => key === item.key)[0];
        } else {
            prod = listaItens.filter(item => item.key === key)[0];
            if (!!!prod || !isObjetoDiffVazio(prod)) {
                prod = listaSelectPaginacao?.itens?.filter(item => key === item.key)[0];
                prod = listaItens.filter(item => item.pro_id === prod.pro_id && item.prg_id === prod.prg_id)[0];
            }
        }
        if (prod.pro_usagrade === true) {
            let res = await api.get(`Produto/ListaProdutoComGradeFiltro?Filtro=${prod.prg_ean}`);
            prod = { ...prod, ...res.data.items[0]};
        }

        formulario.setFieldsValue({ ltp_id: null });
        let listaPrecosProduto = await api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${prod.pro_id}${!!prod.prg_id ? '&idProdutoGrade=' + prod.prg_id : ''}`);
        let listaPadraoProduto = await api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${prod.pro_id}${!!prod.prg_id ? '&idProdutoGrade=' + prod.prg_id : ''}`);
        if (!!prod.prg_id) {
            setPossuiGrade(true);
        }
        let idx = 0;
        listaPrecosProduto.data.forEach(item => item.key = idx++);
        setListaPreco(listaPrecosProduto.data);
        setDadosProduto(prod);
        setRecordEditando({});
        formulario.setFieldsValue({ ltp_id: listaPadraoProduto.data[0].ltp_id });
    };

    function adicionarItem(lstPreco = listaPreco, prod = dadosProduto) {
        let dados = [...listaItens];
        let obj = {};
        let editando = true;
        if (!multiplosReg) {
            obj = dados.find((item) => (prod.pro_id === item.pro_id && prod.prg_id === item.prg_id));
            if (!!!obj) {
                obj = prod;
                obj.sequencia = !!dados.length > 0 ? dados[dados.length - 1].sequencia + 1 : 1;
                editando = false;
            } else {
                setAtualizarListaProd(true);
                setFiltroProd("");
            }
            obj.quantidade = parseInt(formulario.getFieldValue().quantidade);
            if (!!lstPreco && lstPreco.length > 0) {
                obj.lpi_valorvenda = lstPreco.filter((preco) => (preco.ltp_id === formulario.getFieldValue().ltp_id))[0].lpi_valorvenda;
                obj.observacao = formulario.getFieldValue().observacao;
                obj.pro_ean = !!obj.pro_ean ? obj.pro_ean : obj.prg_ean;
                obj.descricaograde = obj.grade;
                if (parseFloat(obj.lpi_valorvenda) > 0) {
                    if (!editando) {
                        dados.push(obj);
                    }
                    setListaItens(dados);
                    setPossuiGrade(false);
                    limparCampos();
                } else {
                    notification.warning({ description: 'Tabela de preço sem valor para o produto atual', message: 'Aviso' });
                    return false;
                }
            }
        } else {
            let filtros = '';
            filtros += !!formulario.getFieldValue().ltp_id ? `&listaPrecoId=${formulario.getFieldValue().ltp_id}` : '';
            filtros += !!formulario.getFieldValue().pro_id ? `&produtoId=${formulario.getFieldValue().pro_id}` : '';
            filtros += !!formulario.getFieldValue().mar_id ? `&marcaId=${formulario.getFieldValue().mar_id}` : '';
            filtros += !!formulario.getFieldValue().gru_id ? `&grupoId=${formulario.getFieldValue().gru_id}` : '';
            filtros += !!formulario.getFieldValue().sgp_id ? `&subGrupoId=${formulario.getFieldValue().sgp_id}` : '';
            api.get(`RelatorioModeloEtiqueta/DadosEtiquetaProduto?${filtros}`).then(
                res => {
                    let qtd = 0;
                    res.data.forEach((item) => {
                        if (parseFloat(item.listaPrecoProduto) > 0) {
                            item.sequencia = !!dados.length > 0 ? dados[dados.length - 1].sequencia + 1 : 1;
                            item.quantidade = 1;
                            item.lpi_valorvenda = item.listaPrecoProduto;
                            item.observacao = formulario.getFieldValue().observacao;
                            qtd++;
                            dados.push(item);
                        }
                    });
                    if (qtd === 0) {
                        notification.warning({ description: 'Produtos sem preço de venda', message: 'Aviso' });
                        return false;
                    }
                    setListaItens(dados);
                    setPossuiGrade(false);
                    limparCampos()
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        }
    };

    function editarItem(record) {
        setFiltroProd(record.pro_ean);
        let dados =  listaSelectPaginacao?.itens?.filter((item) => item.pro_id === recordEditando.pro_id && ((!!recordEditando.prg_id && recordEditando.prg_id === item.prg_id) || !!!recordEditando.prg_id))[0];
        if (!!!dados || !isObjetoDiffVazio(dados)) {
            setRecordEditando(record);
        } else {
            prepararDadosEditar(record, dados);
        }
    };

    function prepararDadosEditar(record, dados) {
        formulario.setFieldsValue({ pro_id: dados.key, prg_id: record.prg_id, quantidade: record.quantidade, observacao: record.observacao });
        setMultiplosReg(false);
        popularDadosProdutonew(dados.key, true);
    };

    function removerItem(record) {
        let dados = listaItens.filter((item) => item.pro_id !== record.pro_id || (item.pro_id === record.pro_id && item.prg_id !== record.prg_id));
        setListaItens(dados);
    };

    function limparCampos() {
        formulario.setFieldsValue({ pro_id: null, pro_codigo: null, quantidade: null, ltp_id: null, observacao: '', mar_id: null, gru_id: null, sgp_id: null });
    };


    function imprimir() {

        let idModelo = formulario.getFieldValue().modelo;
        api.get(`RelatorioModeloEtiqueta/Listar?id=${idModelo}`).then(
            res => {
                montarEtiqueta(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarFormulario() {
        if (validaForm(formulario, listaValidacao)) {
            imprimir();
        }
    }

    function retornaObjeto(listaObj) {
        var objRetorno = {};
        for (var i = 0; i < listaObj.length; i++) {
            for (var attrname in listaObj[i]) {
                objRetorno[attrname] = listaObj[i][attrname];
            }
        }
        return objRetorno;
    }

    function montarEtiqueta(dadosRel) {
        let qtd = dadosRel.quantidadeColuna;
        let qtdTot = 0;
        let campos = [];
        let dadosCampos = [];
        let listaDadosImpressora = [];
        let dadosImpressora = {
            id: "etiqueta",
            name: "etiqueta",
        };
        listaItens.forEach((itens) => {
            qtdTot += itens.quantidade;
        });
        squemaEtiquetaProduto.forEach((squema) => {
            for (let i = 1; i <= qtd; i++) {
                campos.push({ name: `${squema.nome}_Coluna_${i}`, type: squema.tipo })
            }
        });
        let numeroColuna = 1;
        let dadosAux = [];
        listaItens.forEach((produto) => {
            for (let idx = 1; idx <= produto.quantidade; idx++) {
                let valorParcelado = 0;
                let quantidadeParcela = '';
                if (dadosAdicionais) {
                    let qtdParcelas = !!formulario.getFieldValue().numeroParcelas ? formulario.getFieldValue().numeroParcelas : 1;
                    if (!!formulario.getFieldValue().possuiEntrada) {
                        quantidadeParcela = `${qtdParcelas}x(1+${qtdParcelas - 1})`;
                    } else {
                        quantidadeParcela = qtdParcelas + 'x';
                    }

                    valorParcelado = FormatNumber(produto.lpi_valorvenda / qtdParcelas, true);
                }
                dadosAux.push({
                    ['Codigo_Empresa_Coluna_' + numeroColuna]: getEmpresa(),
                    ['Empresa_Coluna_' + numeroColuna]: dadosEmpresa.emp_nomefantasia,
                    ['Codigo_Produto_Coluna_' + numeroColuna]: produto.pro_codigo,
                    ['Produto_Coluna_' + numeroColuna]: produto.pro_descricao,
                    ['Grade_Coluna_' + numeroColuna]: produto.descricaograde,
                    ['Marca_Coluna_' + numeroColuna]: produto.mar_nome,
                    ['Preco_Coluna_' + numeroColuna]: FormatNumber(produto.lpi_valorvenda, true),
                    ['Ean_Coluna_' + numeroColuna]: produto.pro_ean,
                    ['Codigo_Etiqueta_Coluna_' + numeroColuna]: produto.pro_codigoetiqueta,
                    ['Codigo_Referencia_Coluna_' + numeroColuna]: produto.pro_referencia,
                    ['Observacao_Coluna_' + numeroColuna]: produto.observacao,
                    ['Quantidade_Parcela_Coluna_' + numeroColuna]: quantidadeParcela,
                    ['Valor_Parcelado_Coluna_' + numeroColuna]: valorParcelado
                });

                if (numeroColuna === qtd || (qtdTot < qtd && qtdTot === numeroColuna)) {
                    if (qtdTot < qtd) {
                        numeroColuna++;
                        for (let idx = numeroColuna; idx <= qtd; idx++) {
                            dadosAux.push(retornaEtiquetaEmBranco(idx));
                        }
                    }
                    dadosCampos.push(retornaObjeto(dadosAux));
                    qtdTot = qtdTot - qtd;
                    dadosAux = [];
                    numeroColuna = 1;
                } else {
                    numeroColuna++;
                }
            }
        });
        dadosImpressora.data = dadosCampos;
        dadosImpressora.schema = {
            fields: campos
        };

        listaDadosImpressora.push(dadosImpressora);
        jsreports.export({
            report_def: JSON.parse(dadosRel.jsonRelatorio),
            datasets: listaDadosImpressora,
            format: 'pdf',
            outputHandler: function (dados) {
                window.open(URL.createObjectURL(dados), '_blank');
            }
        })
    };

    function retornaEtiquetaEmBranco(numeroColuna) {
        return {
            ['Codigo_Empresa_Coluna_' + numeroColuna]: null,
            ['Empresa_Coluna_' + numeroColuna]: '',
            ['Codigo_Produto_Coluna_' + numeroColuna]: null,
            ['Produto_Coluna_' + numeroColuna]: '',
            ['Grade_Coluna_' + numeroColuna]: '',
            ['Marca_Coluna_' + numeroColuna]: '',
            ['Preco_Coluna_' + numeroColuna]: null,
            ['Ean_Coluna_' + numeroColuna]: '',
            ['Codigo_Etiqueta_Coluna_' + numeroColuna]: null,
            ['Codigo_Referencia_Coluna_' + numeroColuna]: '',
            ['Observacao_Coluna_' + numeroColuna]: '',
            ['Quantidade_Parcela_Coluna_' + numeroColuna]: null,
            ['Valor_Parcelado_Coluna_' + numeroColuna]: null
        }
    };

    function limparItens() {
        setListaItens([]);
    };

    async function buscarProdutoCodBarra(codigoBarras) {
        let dados = [...listaItens];
        let res = await api.get(`Produto/ListaProdutoComGradeFiltro?Filtro=${codigoBarras}`);
            codBarras.current.focus();
        if (!!res.data && res.data.items.length > 0) {
            let prod = res.data.items[0];
            let obj = dados.filter((item) => item.pro_id === prod.pro_id && ((!!prod.prg_id && prod.prg_id === item.prg_id) || !!!prod.prg_id))[0];
            let qtd = 1;
            if (!!obj && isObjetoDiffVazio(obj)) {
                qtd = obj.quantidade + 1;
            }
            setDadosProduto(prod);
            
            let listaPrecosProduto = await api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${prod.pro_id}${!!prod.prg_id ? '&idProdutoGrade=' + prod.prg_id : ''}`);
            let listaPadraoProduto = await api.get(`ListaPreco/ListaPrecoPadraoProduto?idProduto=${prod.pro_id}${!!prod.prg_id ? '&IdProdutoGrade=' + prod.prg_id : ''}`);
            let idx = 0;
            listaPrecosProduto.data.forEach(item => item.key = idx++);
            setListaPreco(listaPrecosProduto.data);
            formulario.setFieldsValue({ ltp_id: listaPadraoProduto.data[0]?.ltp_id, quantidade: qtd });
            adicionarItem(listaPrecosProduto.data, prod);
        } else {
            notification.warning({ description: 'Produto não localizado', message: 'Atenção!' })
        }
    };

    function exibirTodasGrades() {
        setAbrirModalGrade(true);
    };

    function aoFecharModalGrade(dados) {
        if (!!dados && dados.length > 0) {
            adicionarItensGrade(dados);
        }
        setAbrirModalGrade(false);
    };

    function adicionarItensGrade(listaGrade) {
        let dados = [...listaItens];
        let obj = {};
        if (!!!listaGrade || listaGrade.length === 0) {
            return false;
        }
        listaGrade.forEach((grade, indice) => {
            obj = dados.find((item) => (grade.pro_id === item.pro_id && grade.prg_id === item.prg_id));
            if (!!!obj) {
                obj = grade;
                obj.sequencia = !!dados.length > 0 ? dados[dados.length - 1].sequencia + 1 : 1;
                obj.observacao = "";
                obj.pro_ean = !!obj.prg_ean ? obj.prg_ean : obj.pro_ean;
            }
            obj.quantidade = parseInt(grade.quantidade);
            dados.push(obj);

            if (indice === listaGrade.length - 1) {
                setListaItens(dados);
                setPossuiGrade(false);
                limparCampos();
            }
        });

    };

    return (
        <div>
            <Row align="middle" justify="end" gutter={[8, 8]}>
                <Col>
                    <Link to="/relatorioEtiqueta">
                        <Button onClick={() => {
                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
                        }} htmlType="button" icon={<PlusOutlined />} size="middle">
                            Gerenciar Modelos de Etiqueta
                        </Button>
                    </Link>
                </Col>
            </Row>
            <FormGW layout="vertical" form={formulario} onFinish={() => { adicionarItem() }}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={10} lg={12} xl={12} xxl={12}>
                        <Form.Item label="Selecione um Modelo" name="modelo" rules={[{ required: true, message: 'Favor selecionar um modelo de impressão!' }]}>
                            <SelectPaginado url="RelatorioModeloEtiqueta/ListarGeral?TipoEtiqueta=0" form={formulario} placeholder="Selecione um Modelo" name="modelo" conteudo={modelo => (
                                <Select.Option value={modelo.id} key={modelo.key}>{modelo.nome}</Select.Option>
                            )} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={12} xl={12} xxl={12}>
                        {!multiplosReg &&
                            <Row gutter={[8, 0]} justify="center">
                                {!!consultaProduto && <Col xs={21} sm={21} md={21} lg={22} xl={21} xxl={21}>
                                    <Form.Item label="Selecione o Produto" name="pro_id" rules={[{ required: true, message: 'Favor selecionar um produto!' }]}>
                                        <SelectProduto 
                                            allowClear={true} 
                                            valueKey={true} 
                                            detalhes={true} 
                                            placeholder="Selecione o Produto" 
                                            name="pro_id" 
                                            form={formulario} 
                                            onChange={(dados) => { popularDadosProdutonew(formulario.getFieldValue().pro_id) }}
                                            ordem={"pro_id"} 
                                            filtroPesq={filtroProd} 
                                            atualizaComponente={atualizarListaProd} 
                                        />
                                    </Form.Item>
                                    <Form.Item hidden label="Código grade" name="prg_id"></Form.Item>
                                </Col>}
                                {!!!consultaProduto && <Col xs={21} sm={21} md={21} lg={22} xl={21} xxl={21}>
                                    <Form.Item label="Localize o Produto" name="pro_codigo">
                                        <Input 
                                        ref={codBarras} 
                                        onPressEnter={(e) => {
                                            const value = e.target.value;
                                            if (value.trim()) {
                                                buscarProdutoCodBarra(value)
                                            } else {
                                                notification.warning({ description: 'Informe o código do Produto', message: 'Atenção!' })
                                            }
                                        }} 
                                        placeholder="Informe o Produto/Mercadoria" 
                                        />
                                    </Form.Item>
                                </Col>}
                                <Col xs={3} sm={3} md={3} lg={2} xl={3} xxl={3} className="i-button">
                                    <Tooltip title={!!consultaProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                                        <Button onClick={togglePesquisa} block>
                                            {consultaProduto ? <BarcodeOutlined /> : <SearchOutlined />}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        }
                    </Col>
                    {!multiplosReg &&
                        <Col span={24}>
                            <Row gutter={[8, 0]} justify="center">
                                {!!consultaProduto && possuiGrade && <Col xs={24} sm={7} md={7} lg={5} xl={5} xxl={4} className="t-mob-573">
                                    <Button onClick={() => { exibirTodasGrades() }} type="primary" icon={<TableOutlined />} block> Ver Todas Grades</Button>
                                </Col>}
                                {!!consultaProduto &&
                                    <>
                                        <Col xs={possuiGrade ? 24 : 24} sm={possuiGrade ? 12 : 16} md={possuiGrade ? 12 : 19} lg={possuiGrade ? 14 : 19} xl={possuiGrade ? 14 : 19} xxl={possuiGrade ? 16 : 20}>
                                            <Form.Item label="Lista de Preço" name="ltp_id" rules={[{ required: true, message: 'Favor selecionar uma lista de preço!' }]}>
                                                <Select placeholder="Selecione uma Lista" showSearch optionFilterProp="children" allowClear>
                                                    {listaPreco.map(listaPre => (
                                                        <Select.Option value={listaPre.ltp_id} key={listaPre.key}>{listaPre.ltp_nome}</Select.Option>
                                                    ))}
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col xs={possuiGrade ? 24 : 24} sm={possuiGrade ? 5 : 8} md={possuiGrade ? 5 : 5} lg={possuiGrade ? 5 : 5} xl={possuiGrade ? 5 : 5} xxl={possuiGrade ? 4 : 4}>
                                            <Form.Item label="Quantidade" name="quantidade" rules={[{ required: true, message: 'Favor informar a quantidade!' }]}>
                                                <Input placeholder="Informe a Quantidade" />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Col>
                    }
                </Row>
                {multiplosReg && <div>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Form.Item label="Lista de Preço" name="ltp_id" rules={[{ required: true, message: 'Favor selecionar uma lista de preço!' }]}>
                                <SelectPaginado url="ListaPreco/ListarTodasListaPreco" name="ltp_id" form={formulario} placeholder="Selecione uma Lista 2" conteudo={listaPre => (
                                    <Select.Option value={listaPre.ltp_id} key={listaPre.key}>{listaPre.ltp_nome}</Select.Option>
                                )} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="Marca" name="mar_id">
                                <SelectPaginado url="Marca/Listar?Ativa=true" name="mar_id" form={formulario} placeholder="Selecione uma Marca" conteudo={(marca) => (<Select.Option value={marca.mar_id} key={marca.mar_id}>{marca.mar_nome}</Select.Option>)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                            <Form.Item label="Grupo" name="gru_id">
                                <SelectPaginado url="GrupoProduto/Listar?apenasAtivos=true" form={formulario} placeholder="Selecione um Grupo" name="gru_id" conteudo={(grupo) => (<Select.Option value={grupo.gru_id} key={grupo.gru_id}>{grupo.gru_nome}</Select.Option>)} onChangeFunction={(rec) => { buscarSubGrupo(rec) }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                            <Form.Item label="Subgrupo" name="sgp_id">
                                <Select placeholder="Selecione um subgrupo" allowClear>
                                    {listaSubGrupo.map((subGrupo) => (<Select.Option key={subGrupo.sgp_id} value={subGrupo.sgp_id}>{subGrupo.sgp_nome}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>}
                <Row>
                    <Col span={24}>
                        <Form.Item label="Observação" name="observacao">
                            <Input.TextArea placeholder="Informe as Observações Referente a Etiqueta de Produtos" />
                        </Form.Item>
                    </Col>
                </Row>
            </FormGW>
            <Row align="middle" justify="end" gutter={[8, 8]} className="m-t-8">
                <Col xs={24} sm={7} md={5} lg={5} xl={5} xxl={4}>
                    <Button type="default" icon={<CopyOutlined />} onClick={() => { setMultiplosReg(!multiplosReg) }} block>
                        Múltiplos Registros
                    </Button>
                </Col>
                <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => { formulario.submit() }} block>
                        Adicionar Item
                    </Button>
                </Col>
            </Row>
            <div className="tabela">
                {listaItens.length > 0 && <Table columns={[
                    {
                        title: 'Produtos',
                        render: ({ sequencia, pro_codigo, pro_descricao, pro_ean, descricaograde }) => (
                            <div>
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        <Tag color="processing">
                                            <b>{sequencia}</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{pro_codigo} - {pro_descricao}</b>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        EAN: <b>{pro_ean}</b>
                                    </Col>
                                    {!!descricaograde && <Col>
                                        Grade: <b>{descricaograde}</b>
                                    </Col>}
                                </Row>
                            </div>
                        )
                    }, {
                        title: 'Valor Unit. (R$)',
                        align: 'right',
                        width: 150,
                        align: 'center',
                        render: ({ lpi_valorvenda }) => (
                            <div>
                                <b>{FormatNumber(lpi_valorvenda, true)}</b>
                            </div>
                        )
                    }, {
                        title: 'Quant. Etiqueta',
                        width: 120,
                        align: 'center',
                        render: ({ quantidade }) => (
                            <div>
                                <b>{quantidade}</b>
                            </div>
                        )
                    }, {
                        title: 'Ações',
                        fixed: 'right',
                        width: 65,
                        align: 'center',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => { editarItem(record) }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => { modalExcluirItem(record) }} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                ]} dataSource={listaItens} scroll={{ x: 900 }} />}
            </div>
            {listaItens.length > 0 && <div>
                <Row>
                    <Col>
                        <Form.Item label="Informações Adicionais" name="mostraDadosAdicionar" valuePropName="checked" initialValue="false">
                            <Switch onChange={() => { setDadosAdicionais(!dadosAdicionais) }} />
                        </Form.Item>
                    </Col>
                </Row>
                {dadosAdicionais && <Row>
                    <Col span={24}>
                        <Card>
                            <Row gutter={[8, 0]} align="middle">
                                <Col>
                                    <Form.Item label="Número de Parcelas" name="numeroParcelas">
                                        <InputNumber step={1} min={1} />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="possuiEntrada" valuePropName="checked">
                                        <Checkbox /> Possui Entrada?
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>}
                <Row gutter={[8, 0]} align="middle" justify="end" className="m-t-16">
                    <Col>
                        <Button type="primary" size="large" icon={<PrinterOutlined />} onClick={() => { validarFormulario() }}>
                            Imprimir
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" size="large" danger icon={<CloseOutlined />} onClick={() => { limparItens() }}>
                            Limpar Itens
                        </Button>
                    </Col>
                </Row>
            </div>}
            <ModalProdutoGrade dados={dadosProduto} exibirModal={abrirModalGrade} acrescimoDesconto={false} fecharModal={(dados) => { aoFecharModalGrade(dados) }} />
        </div>
    );
}