import React from 'react';
import { Modal } from "antd";

import { useStateValue } from '../../state';
import { manutencaoActions } from '../../actions';
import { DetalheRemessa } from "../../components";

export default function ModalDetalheRemessa({ exibirModal, fecharModal, dadosModal }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const onClose = () => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        fecharModal();
    };

    return (

        <Modal destroyOnClose title="Detalhes da Remessa"
            width="80%"
            onCancel={onClose} 
            footer={null}
            maskClosable={false}
            visible={exibirModal}>
            <DetalheRemessa dadosModal={dadosModal} />
        </Modal>

    )
}