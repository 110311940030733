import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Steps, Select, Modal, notification, Spin } from "antd";
import moment from "moment";

import api from '../../services/api';
import { useStateValue } from "../../state";
import { TabFaturamento, TabItens, TabResumo } from './tabPage';
import { Data, FormGW, SelectPaginacao } from '../../components';
import { manutencaoActions, selectPaginadoActions } from '../../actions';
import { validaForm, validarNumeros } from '../../services/funcoes';
import SelectPaginado from '../../components/selectPaginado';
import FormaDePagamento from "../../components/enuns/formaDePagamento";

export default function ManutencaoRecebimentoOrdemCompra({ carregarDados, form, carregando, aoSalvar, pages, proximaPag, alteraData }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [dadosItens, setDadosItens] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [dadosVendedor, setDadosVendedor] = useState({});
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [frete, setFrete] = useState(0);
    const [editando, setEditando] = useState(false);

    const listaValidacoesDest = [
        { nome: 'ntf_dataemissao', obrigatorio: true, label: 'Data Lançamento' },
        { nome: 'ope_id', obrigatorio: true, label: 'Operação' }
    ];

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!!manutencao.dados && !!manutencao.dados.ntf_id) {
                form.setFieldsValue(manutencao.dados);
                setEditando(true);
            } else {
                setEditando(false);
            }
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer]);


    useEffect(() => {
        if (!!ui.showDrawerExtra) {
            carregarDados();
            buscarItensOrdemCompra();
            form.setFieldsValue(manutencao.dados);
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
            if (!!manutencao.dados) {
                let lista = [];
                if (!!manutencao.dados.recebimento.for_id) {
                    lista.push({ name: "for_id", campo: "FornecedorId", value: manutencao.dados.recebimento.for_id });
                }
                if (!!manutencao.dados.recebimento.trn_id) {
                    lista.push({ name: "trn_id", campo: "IdTrasnportador", value: manutencao.dados.recebimento.trn_id });
                }
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                form.setFieldsValue(manutencao.dados);
            }
        }
    }, [ui.showDrawerExtra]);

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.faturamento) {
                setListaFormaPagamento(manutencao.dados.faturamento);
            }
            if (!!manutencao.dados.formaPgto && !!!manutencao.dados.antecipacao) {
                manutencao.dados.formaPgto.forEach((forma) => {
                    if (forma.fpg_tipopagamento === FormaDePagamento.fpCreditoLoja && forma.fpg_quitacaoantecipacao) {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ antecipacao: [{ valorAntecipacao: forma.nfp_valor }] } } } });
                    }
                });
            }
            if (!!manutencao.dados.formaPgto && !!!manutencao.dados.faturamento) {
                manutencao.dados.formaPgto.forEach((formas) => {
                    formas.pfp_valor = parseFloat(formas.nfp_valor);
                });
                let dadosFormaPgto = [...manutencao.dados.formaPgto];
                dadosFormaPgto = manutencao.dados.formaPgto.filter((dados) => dados.fpg_tipopagamento !== FormaDePagamento.fpCreditoLoja && !dados.fpg_quitacaoantecipacao);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: dadosFormaPgto } } } });
            }


            if (!!manutencao.dados && !!manutencao.dados.dadosItens) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: listaItens } } } });
                setListaItens(manutencao.dados.Itens);
                setDadosItens(manutencao.dados.dadosItens);
            }
        }
    }, [manutencao.dados]);


    useEffect(() => {

        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag]);



    async function carregarDados() {
        setCarregarPagina(true);
        buscarItensOrdemCompra();

        let listaFormaPagamento = await api.get('FormaPagamento/Listar?Recebimento=2,3,4');
        if (listaFormaPagamento.status === 200) {
            setFormasPagamento(listaFormaPagamento.data);
        }
        let listaDadosContribuinte = await api.get('dadosContribuinte/Buscar');
        setCarregarPagina(false);
        if (listaDadosContribuinte.status === 200) {
            if (!!listaDadosContribuinte.data) {
                setDadosContribuinte(listaDadosContribuinte.data);
            } else {
                Modal.warning({
                    title: 'Dados Contribuinte não parâmetrizados!',
                    content: 'Favor parametrizar os dados do contribuinte'
                });
                aoSalvar();
            }
        }
    };

    function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                listaValidacoes = listaValidacoesDest;
                if (paginaAtual < pagina) {
                    var recebendo = dadosItens.map(function (e) { return e.ocp_qtdrecebendo });
                    var verifica = recebendo.reduce(function (verifica, i) {
                        return verifica + i;
                    });
                    if (!!dadosItens && verifica === 0) {
                        notification.warning({ message: 'Aviso', description: 'Nota Fiscal deve ter ao menos 1 item!' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    listaValidacoes = [];
                    if (!validarFaturamento()) {
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    };

    function validarFaturamento() {
        let formulario = form.getFieldsValue();
        let retorno = true;
        let valorTotal = 0;
        let operacao = operacaoFiscal;
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach(forma => {
                valorTotal += parseFloat(forma.pfp_valor);
                if (forma.editando == true) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor salvar as alterações em andamento nas parcelas(forma pagamento) para continuar!' });
                }
            });
            if (parseFloat(formulario.valorTotal) === 0) {
                retorno = false;
                notification.warning({ message: 'Aviso!', description: 'Valor da Nota Inválido!' });
            } else {
                if ((validarNumeros(formulario.valorTotal) == !parseFloat(valorTotal.toFixed(2))) || operacao.tip_id == !5) {
                    notification.warning({ message: 'Aviso', description: 'Valor informado nas formas de pagamento é menor que o valor total da nota!' });
                }
            }
        } else {
            if (!!formulario.fpg_id) {
                let formaPagamento = formasPagamento.filter((forma) => (forma.fpg_id === formulario.fpg_id))[0];
                if (formaPagamento.fpg_tipoavistaaprazo === 1) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
            } else {
                retorno = false;
                notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
            }
        }
        return retorno;
    };

    function popularDadosItens() {
        let ordemcompraitens = [];
        dadosItens.forEach(itens => {
            let aux = { ...itens };
            aux.ocp_quantidaderecebidaanteriormente = itens.ocp_quantidaderecebida;
            aux.ocp_desconto = !!itens.ocp_desconto ? itens.ocp_desconto : form.getFieldValue().valorDesconto;
            aux.ocp_valortotal = form.getFieldValue().valorTotal;
            aux.ocp_quantidaderecebida = (itens.ocp_qtdrecebendo);
            aux.nfi_valorseguro = form.getFieldValue().valorSeguro;
            aux.nfi_valoroutros = form.getFieldValue().valorOutrasDespesas;
            ordemcompraitens.push(aux);
        })
        return ordemcompraitens;
    };

    function preencherDadosFaturamento() {
        let dadosForma = [];
        listaFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.fpg_id = forma.fpg_id;
            aux.cpg_id = forma.cpg_id;
            aux.nfp_valor = parseFloat(forma.pfp_valor);
            dadosForma.push(aux);
        });
        return dadosForma;
    };

    function buscarItensOrdemCompra(orc_id) {

        api.get(`OrdemCompraItem/ListarItensOrdemCompra?codigoOrdemCompra=${form.getFieldValue().orc_id}`).then(
            res => {
                let key = 1;
                res.data.forEach((itens) => {
                    if (itens.pro_id != null) {
                        itens.key = key++;
                        itens.ean = !!itens.pro_ean ? itens.pro_ean : itens.prg_ean;
                        itens.grade = itens.mgp_descricao;
                        itens.ocp_qtdrecebendo = 0;
                        itens.ocp_quantidade = itens.ocp_quantidade;
                        itens.nfi_valorunitario = itens.ocp_valorun;
                        itens.total = itens.ocp_valorun * itens.ocp_quantidade;
                        itens.ocp_quantidaderecebida = !!itens.ocp_quantidaderecebida ? itens.ocp_quantidaderecebida : 0;
                        itens.valorTotalDesconto = (itens.ocp_qtdrecebendo * itens.ocp_valorun);
                        itens.ocp_frete = itens.ocp_frete;
                        if (itens.ocp_qtdrecebendo === 0) {
                            itens.bloquear = true;
                        } else {
                            itens.bloquear = false;
                        }
                        setDadosItens(itens);
                    }
                });
                setDadosItens(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    async function salvarDados(values) {
        console.log("values", values);
        carregando(true);
        let dados = {};
        dados = { ...values };
        dados.orc_id = form.getFieldValue().orc_id;
        dados.for_id = form.getFieldValue().for_id;
        dados.orc_valorfrete = values.orc_valorfrete;
        dados.orc_valordesconto = values.valorDesconto;
        dados.orc_valoracrescimo = !!values.valorAcrescimo ? values.valorAcrescimo : form.getFieldValue().valorAcrescimo;
        dados.orc_valorseguro = !!values.orc_valorseguro ? values.orc_valorseguro : form.getFieldValue().orc_valorseguro;
        dados.ntf_numero = !!values.ntf_numero ? parseInt(values.ntf_numero) : form.getFieldValue().orc_id;
        dados.faturamento = preencherDadosFaturamento(values);
        dados.ordemcompraitens = popularDadosItens(dadosItens);

        try {
            var res = await api.post('OrdemCompra/Receber', dados);
            if (res.status == 200) {
                notification.success({ message: 'Operação concluída', description: "Ordem de Compra recebida com sucesso!" });
            }
        } catch (error) {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(error.response) });
        } finally {
            carregando(false);
            aoSalvar();
            alteraData();
        }
    };

    return (
        <div className="pages-col">
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" name="formNotaFiscal" form={form} onFinish={salvarDados}>
                    <Row gutter={[8, 0]}>
                        {!!editando && <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                            <Form.Item label="Nº da Nota" name="ntf_numero">
                                <Input />
                            </Form.Item>
                        </Col>}
                        <Form.Item hidden name="orc_id"></Form.Item>
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                            <Form.Item label="Série" name="ser_id">
                                <SelectPaginado url="SerieFiscal/Listar?EmitenteDocumento=1&SomenteAtivos=true" disabled={editando} placeholder="Selecione uma Série" form={form} name="ser_id" conteudo={
                                    ser => (<Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={editando ? 8 : 6} md={4} lg={4} xl={4}>
                            <Data label="Data de Lançamento" disabled={editando} name="ntf_dataemissao" />
                        </Col>
                        <Col xs={24} sm={editando ? 12 : 10} md={editando ? 6 : 8} lg={editando ? 6 : 8} xl={editando ? 6 : 8}>
                            <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                                <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?TipoOperacao=2&SomenteAtivo=true" placeholder="Selecione a Operação Fiscal" form={form} name="ope_id" conteudo={
                                    op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item label="Selecione o Fornecedor" name="for_id" rules={[{ required: true, message: 'Selecione um Fornecedor' }]}>
                                <SelectPaginacao url="Fornecedor/Listar?Order=%2Bpes_nome"
                                    placeholder="Selecione o Fornecedor"
                                    form={form} nameValue="for_id" nameLabel="for_id" disabled
                                    conteudo={
                                        forn => (<Select.Option value={forn.for_id} key={forn.for_id}>
                                            {forn.for_id} - {forn.pes_nome}
                                            {(!!forn.pej_cnpj ? <div>CNPJ: <b>{forn.pej_cnpj}</b></div> : "")}
                                        </Select.Option>)
                                    }
                                />
                            </Form.Item>
                            {/* <Form.Item label="Fornecedor" name="for_id" rules={[{ required: false, message: 'Selecione o Fornecedor' }]}>
                                <SelectPaginado url="Fornecedor/Listar?Order=+pes_nome" placeholder="Selecione o Fornecedor" form={form} name="for_id" disabled conteudo={
                                    forn => (<Select.Option value={forn.for_id} key={forn.for_id}>{forn.pes_nome}</Select.Option>)
                                } />
                            </Form.Item> */}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={10}>
                            <Form.Item label="Transpordador" name="trn_id" rules={[{ required: false, message: 'Informe o Transportador' }]}>
                                <SelectPaginado url="Transportador/ListarTodos?Order=+pes_nome" placeholder="Selecione o Transportador" form={form} name="trn_id" conteudo={
                                    trn => (<Select.Option value={trn.trn_id} key={trn.trn_id}>{trn.pes_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Col span={24}>
                        <Form.Item label="Observação" name="orc_observacao">
                            <Input.TextArea placeholder="Informe uma observação" />
                        </Form.Item>
                    </Col> */}
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" onChange={(e) => {
                                proximaPag.setProximaPag(e);
                            }} current={pages.tagPages}>
                                <Steps.Step title="Itens" />
                                <Steps.Step title="Faturamento" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            {pages.tagPages === 0 &&
                                <TabItens
                                    form={form}
                                    dadosItens={{ dadosItens, setDadosItens, frete }}
                                    carregarPagina={setCarregarPagina} />
                            }
                            {pages.tagPages === 1 &&
                                <TabFaturamento
                                    form={form}
                                    dadosItens={dadosItens}
                                    dadosContribuinte={dadosContribuinte}
                                    dadosVendedor={dadosVendedor}
                                    dadosOperacao={operacaoFiscal} />
                            }
                            {pages.tagPages === 2 &&
                                <TabResumo
                                    form={form}
                                    dadosItens={dadosItens} />
                            }
                        </Col>
                    </Row>
                </FormGW>
            </Spin>
        </div>
    );
}