import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Button, Form, Input, notification, Alert } from "antd";
import { ArrowLeftOutlined, CheckOutlined, EyeOutlined, ShoppingOutlined, CloudUploadOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { getEmailUsuario } from "../../services/auth";
import { ModalPlano } from "../../components/modals/";
import { compararDatas ,encodeBase64} from "../../services/funcoes";

export default function Planos() {

    const [openModal, setOpenModal] = useState(false);
    const [recursosPlano, setRecursosPlano] = useState([]);
    const [alterarPlano, setAlterarPlano] = useState(false);
    const [listaPlanos, setListaPlanos] = useState([]);
    const [planoAtual, setPlanoAtual] = useState({});
    const [planoSelecionado, setPlanoSelecionado] = useState({});
    const [formAlterarPlano] = Form.useForm();
    const [comparaPlano, setComparaPlano] = useState(null);

    useEffect(() => {
        listarPlanos();
    }, []);

    async function listarPlanos() {
        let lista = [];
        try {
            var planoUsuario = await api.get(`LocalPlano/VerificarStatusPlano`);
            setPlanoAtual({ pla_nome: planoUsuario.data.plano.pla_nome, pla_valor: planoUsuario.data.plano.pla_valor, lcp_id: planoUsuario.data.lcp_id, validade: planoUsuario.data.lcp_validadeplano });

            var retornoPlanos = await api.get(`Plano/ListarPlanosAtivos`);
            retornoPlanos.data.items.forEach((listaPlano) => {
                let plano = listaPlano;
                plano.planoUsuario = false;
                if (plano.pla_id === planoUsuario.data.plano.pla_id) {
                    plano.planoUsuario = true;
                };
                if (plano.pla_nivel >= planoUsuario.data.plano.pla_nivel) {
                    lista.push(plano);
                }
            });
            lista.forEach((item) => {
                item.diferenciais = JSON.parse(item.pla_diferenciais);
            });
            lista = lista.reverse();
            setListaPlanos(lista);

        } catch (erro) {
            console.log(erro);
        };
    };

    async function confirmarMudancaPlano(values) {
        try {
            var senhaValida = await api.get(`Usuario/ValidaSenhaAtual?email=${getEmailUsuario()}&senha=${encodeBase64(values.senhaUsuario)}`);
            if (senhaValida.data != "") {
                var retornoMudancaPlano = await api.put(`LocalPlano/MudarLocalPlanoUsuario?novoPlano=${planoSelecionado.pla_id}`);
                if (retornoMudancaPlano.status == 200) {
                    notification.success({ message: 'Seu plano foi alterado com sucesso', description: `Bem-vindo ao plano ${planoSelecionado.pla_nome}` });
                    setAlterarPlano(false);
                    listarPlanos();
                    formAlterarPlano.resetFields();
                } else {
                    notification.warning({ message: 'Não foi possível mudar o plano', description: 'Tente mais tarde. Se o problema persistir contate o suporte do Gestor Web.' });
                }
            } else {
                notification.error({ message: 'Senha inválida!', description: 'Verifique a senha informada.' });
            }
        } catch (erro) {
            notification.error({ message: 'Não foi possível mudar o plano', description: 'Tente mais tarde. Se o problema persistir contate o suporte do Gestor Web.' });
        };
    };

    return (

        <div>
            {!alterarPlano &&
                <div className="plane m-t-8">
                    <Row align="middle" justify="center" gutter={[0, 16]}>
                        {compararDatas(planoAtual.validade, null, "<") &&
                            <Col>
                                <Alert message="OPS! Seu Plano está vencido" showIcon type="warning" />
                            </Col>
                        }
                        <Col span={24}>
                            <Card>
                                <Row align="middle" justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <Typography.Text>
                                            Você possui o Plano:
                                        </Typography.Text>
                                    </Col>
                                    <Col>
                                        <div className="titlePlano">
                                            <Typography.Title level={2}>
                                                {planoAtual.pla_nome}
                                            </Typography.Title>
                                        </div>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <Typography.Text>
                                            R$
                                        </Typography.Text>
                                    </Col>
                                    <Col>
                                        <Typography.Title level={2}>
                                            {FormatNumber(planoAtual.pla_valor, true, false)}
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <div className="m-t-8">
                        <Row align="middle" justify="center" gutter={[8, 8]}>
                            {listaPlanos.map((plano) => (
                                <Col xs={24} sm={8} md={8} lg={8} xl={4}>
                                    <Card hoverable>
                                        <Row align="middle" justify="center">
                                            <Col>
                                                <div className="titlePlano">
                                                    <Typography.Title level={2}>
                                                        {plano.pla_nome}
                                                    </Typography.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row align="bottom" justify="center" gutter={[8, 0]}>
                                            {/* <Col>
                                                <Typography.Text>
                                                    R$
                                                </Typography.Text>
                                            </Col>
                                            <Col>
                                                <Typography.Title level={2}>
                                                    {FormatNumber(plano.pla_valor, true, false)}
                                                </Typography.Title>
                                            </Col> */}
                                        </Row>
                                        <Row align="middle" justify="center" gutter={[0, 8]}>
                                            <Col>
                                                <Typography.Text>
                                                    <small>Por CNPJ</small>
                                                </Typography.Text>
                                            </Col>
                                            {/* <Col span={24}>
                                                <Button type="default" size="middle" icon={<CloudUploadOutlined />} block>
                                                    Realizar Upgrade
                                                </Button>
                                            </Col> */}
                                            <Col span={24}>
                                                <Button type="primary" disabled={plano.planoUsuario} size="middle" icon={<ShoppingOutlined />} block onClick={() => {
                                                    setPlanoSelecionado(plano);
                                                    setAlterarPlano(true);
                                                }}>
                                                    Adquirir Agora
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Button type="link" size="middle" icon={<EyeOutlined />} block onClick={() => {
                                                    setRecursosPlano(plano);
                                                    setOpenModal(true);
                                                    setComparaPlano(plano.pla_id);
                                                }}>
                                                    Ver Comparativo
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <ModalPlano exibirModal={openModal} plano={comparaPlano} fecharModal={() => setOpenModal(false)} recursosPlano={recursosPlano} />
                </div>
            }
            {alterarPlano &&
                <div className="m-t-16">
                    <Form layout="vertical" form={formAlterarPlano} onFinish={confirmarMudancaPlano} >
                        <Row align="middle" justify="center" gutter={[0, 8]}>
                            <Col span={24}>
                                <Typography.Text>
                                    Para confirmar a mudança de plano é necessário informar sua senha.
                                </Typography.Text>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Senha" name="senhaUsuario" rules={[{ required: true, message: 'Informe a Senha de Acesso' }]}>
                                    <Input.Password placeholder="Informe sua a Senha de Acesso" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <Form.Item>
                                            <Button size="middle" onClick={() => setAlterarPlano(false)}>
                                                <ArrowLeftOutlined /> Voltar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <Button type="primary" size="middle" icon={<CheckOutlined />} onClick={() => formAlterarPlano.submit()}>
                                                Confirmar Mudança de Plano
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
        </div>

    );
}