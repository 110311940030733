import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, notification } from 'antd';

import api from '../../services/api';
import { getEmpresa, getNomeEmpresa, getTelaAnterior, login, setCnpjEmpresa, setEmpresa, setLocal, setNomeEmpresa, setNomeLocal, setRegimeTributario, setStatusFilial } from '../../services/auth';
import { selectPaginadoActions } from '../../actions';
import { useStateValue } from '../../state';
import { SelectPaginacao } from '../../components';

export default function SelecoesEmpresa({ veioDaHome = false, exibirModal = false }) {
    const [codigoEmpresa, setCodigoEmpresa] = useState(null);
    const [codigoLocal, setCodigoLocal] = useState(null);
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();

    const [formLogin] = Form.useForm();
    const acessar = values => {
        buscarTela();
    };

    useEffect(() => {
        if (!!formLogin.getFieldValue()?.emp_id) {
            carregarLocais(formLogin.getFieldValue()?.emp_id);
            setCodigoEmpresa(formLogin.getFieldValue()?.emp_id);
        }

    }, [formLogin.getFieldValue().emp_id]);

    // useEffect(() => {
    //     if (!!listaSelectPaginacao?.itens && !veioDaHome) {
    //         validaLocalUnico();
    //     }
    // }, [listaSelectPaginacao]);

    useEffect(() => {
        setCodigoLocal(formLogin.getFieldValue().filial);
    }, [formLogin.getFieldValue().filial]);

    useEffect(() => {
        if (!!codigoLocal) {
            carregarDadosEmpresa();
        }
    }, [codigoLocal]);

    useEffect(() => {
        if (!!veioDaHome) {
            buscarEmpresaUnica();
        }
    }, [exibirModal]);

    function buscarEmpresaUnica() {
        let lista = [];
        let codigo = getEmpresa();
        lista.push({ name: "emp_id", campo: "idEmpresa", value: codigo });
        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        setCodigoEmpresa(codigo);
    }
    async function carregarDadosEmpresa() {
        let values = {};
        values.emp_id = codigoEmpresa;
        values.loc_id = codigoLocal;
        const listaEmpresa = (await api.get(`Empresa/Buscar/${codigoEmpresa}`)).data;
        await api.post('Local/GerarTokenLogin', values).then(res => {
            if (res.status === 200) {
                login(res.data);
                setEmpresa(codigoEmpresa);
                setLocal(codigoLocal);
                setNomeEmpresa(listaEmpresa.emp_razaosocial);
                setRegimeTributario(listaEmpresa.emp_regimetributario);
                api.get(`Local/GetLocalById/${codigoLocal}`).then(
                    res => {
                        if (!!res.data) {
                            let listaLocal = res.data;
                            setNomeLocal(listaLocal.loc_descricao);
                            setCnpjEmpresa(listaLocal.loc_cnpj?.numero);
                            setStatusFilial(listaLocal.loc_ativo);
                            buscarTela();
                        }
                    }
                )
            }
        }).catch(err => {
            if (!!err && !!err.response && !!err.response.data) {
                notification.error({ message: err.response.data || 'Falha ao efetuar o login' });
            } else {
                notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
            }
        });
    }

    function carregarLocais(codEmpresa) {
        let lista = [];
        lista.push({ name: "filial", campo: "codigoEmpresa", value: codEmpresa });
        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
    }

    function buscarTela() {
        if (!!getTelaAnterior()) {
            if (window.location.hash.replace('#', '') === getTelaAnterior()) {
                window.location.reload();
            } else {
                window.location = `/Home#${getTelaAnterior()}`;
                if (window.location.hash !== '' && window.location.hash !== '#/') {
                    window.location.reload();
                }
            }
        } else {
            window.location = "/Home";
        }
    };

    function validaLocalUnico() {
        if (listaSelectPaginacao?.itens?.length === 1 && !!listaSelectPaginacao?.itens[0].loc_id) {
            setCodigoLocal(listaSelectPaginacao?.itens[0].loc_id);
        }
    };

    return (
        <div>
            <Form layout="vertical" name="login" size="middle" onFinish={acessar} form={formLogin}>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="empresa" label="Selecione sua Empresa">
                            <SelectPaginacao url="Empresa/ListarEmpresaUsuario" allowClear={true} placeholder="Selecione uma Empresa" nameLabel="emp_nomefantasia" nameValue="emp_id" form={formLogin} retornaUnico={"emp_id"} name="emp_id" onChangeFunction={(value) => {
                                setCodigoEmpresa(value);
                            }} order="emp_razaosocial"
                                conteudo={
                                    e => (<Select.Option value={e.emp_id} key={e.emp_id}>{e.emp_nomefantasia} <br /> Razao Social: {e.emp_razaosocial} </Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                </Row>
                {formLogin.getFieldValue()?.emp_id && <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="filial" label="Selecione sua Filial">
                            <SelectPaginacao url="Local/ListarLocalUsuario" allowClear={true} nameLabel="loc_descricao" placeholder="Selecione uma Filial" form={formLogin} nameValue="filial" retornaUnico={!veioDaHome?"loc_id":""} filtroExtra={`&codigoEmpresa=${formLogin.getFieldValue()?.emp_id}`} onChangeFunction={(valor) => 
                                setCodigoLocal(valor)} 
                                conteudo={
                                    Local => (<Select.Option key={Local.loc_id} value={Local.loc_id}>{Local.loc_descricao}{!Local.loc_ativo ? ' (INATIVO)' : ''}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                </Row>}
            </Form>
        </div>
    );
}