import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Form, Table, Tag, Button, Modal, Input, Select } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import FiltroSeguroMDF from "../../components/filtroSeguroMDF";
import { InputPreco, SelectPaginacao } from "../../components";
import InputNumber from "rc-input-number";

export default function TabSegEProdPredominante({ formManifesto, listaSeguro, setListaSeguro, responsavelSeguro, tipoCarga, unidadePeso, listaDocs }) {
    const [form] = Form.useForm();
    const [editando, setEditando] = useState(false);

    useEffect(() => {
        let dadosDocs = [...listaDocs];
        let totalValor = dadosDocs.reduce((vlr, item) => { let valor = parseFloat(item.doc_valortotal) || 0; return vlr + valor; }, 0);
        let totalPeso = dadosDocs.reduce((vlr, item) => { let peso = parseFloat(item.doc_pesobruto) || 0; return vlr + peso; }, 0);

        formManifesto.setFieldsValue({
            valorTotal: totalValor,
            pesoBruto: totalPeso
        });

        if (dadosDocs[0].doc_modelodoc === "55") {
            formManifesto.setFieldsValue({
                totalNfe: dadosDocs.length
            });
        } else if (dadosDocs[0].doc_modelodoc === "57") {
            formManifesto.setFieldsValue({
                totalCte: dadosDocs.length
            });
        }
    }, [])

    function editarSeguro(record) {
        setEditando(true);
        form.setFieldsValue(record);
    }

    function modalRemove(record) {
        const index = listaSeguro.findIndex(seg => seg.seg_sequencia === record.seg_sequencia)
        Modal.confirm({
            title: 'Excluir',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir a Seguradora?',
            okText: 'Escluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                removeSeguro(index);
            }
        })
    }

    function removeSeguro(index) {
        let dadosTemp = [...listaSeguro];
        dadosTemp.splice(index, 1)

        dadosTemp.forEach((item, index) => {
            item.seg_sequencia = index + 1;
        })

        setListaSeguro(dadosTemp);
    }

    return (
        <div className="pages-col">
            <Form layout="vertical" form={formManifesto} name="formSegEProd">
                <Row align="middle" style={{ marginBottom: '5px' }}>
                    <Col span={24}>
                        <Divider orientation="left">Produto Predominante</Divider>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle">
                    <Col xs={24} sm={24} md={16} lg={12} xl={10}>
                        <Form.Item label="Descrição Produto" name="pme_descricao" rules={[{ required: true }]}>
                            <Input placeholder="Informe o Nome do Produto" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                        <Form.Item name="pme_ean" label="EAN do Produto">
                            <Input placeholder="Informe o Código de Barras" maxLength={13} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={7} xl={9}>
                        <Form.Item label="NCM do Produto" name="pme_ncm">
                            <SelectPaginacao url="ncm/listar"
                                allowClear
                                nameLabel="ncm_descricao"
                                nameValue="pme_ncm"
                                form={formManifesto}
                                filtroExtra={"&ativo=1"}
                                usaDados={true}
                                placeholder="NCM - Nomenclatura Comum do Mercosul"
                                conteudo={
                                    ncm => (<Select.Option value={ncm.ncm_id} key={ncm.ncm_id}>{ncm.ncm_codigo.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Tipo de Carga" name="pme_tipocarga" initialValue={5} rules={[{ required: true }]}>
                            <Select placeholder="Selecione a Tipo de Carga" showSearch allowClear optionFilterProp="children">
                                {tipoCarga.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                        <Form.Item initialValue={0} label="Total NF-e" name="totalNfe">
                            <InputNumber disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                        <Form.Item initialValue={0} label="Total CT-e" name="totalCte">
                            <InputNumber disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <InputPreco disabled label="Valor total da carga" name="valorTotal" />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item initialValue={0} label="Peso bruto total da carga" name="pesoBruto">
                            <InputNumber disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Unidade de Peso" name="mdf_unidadepeso" initialValue={1} rules={[{ required: true }]}>
                            <Select placeholder="Selecione a unidade de peso" allowClear optionFilterProp="children">
                                {unidadePeso.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row align="middle" style={{ marginTop: '20px', marginBottom: '5px' }}>
                <Col span={24}>
                    <Divider orientation="left">Dados Seguradora</Divider>
                </Col>
            </Row>
            <FiltroSeguroMDF formularioSeg={form} listaSeguro={listaSeguro} setListaSeguro={setListaSeguro} editando={editando} setEditando={setEditando} responsavelSeguro={responsavelSeguro} />
            <Row align="middle" gutter={[0, 16]} style={{ marginBottom: '30px' }}>
                <Col span={24}>
                    <div className="tabela mt-dif">
                        <Form form={form} component={false}>
                            <Table dataSource={listaSeguro} columns={[
                                {
                                    title: 'Sequência',
                                    align: 'center',
                                    width: 75,
                                    render: ({ seg_sequencia }) => (
                                        <Tag color="processing" className="w-100">
                                            <b>{seg_sequencia}</b>
                                        </Tag>
                                    ),
                                },
                                {
                                    title: 'Responsável',
                                    render: ({ seg_responsavel }) => (
                                        <b>{seg_responsavel}</b>
                                    )
                                },
                                {
                                    title: 'Seguradora',
                                    render: ({ seg_nomeseguradora }) => (
                                        <b>{seg_nomeseguradora}</b>
                                    )
                                },
                                {
                                    title: 'Nº Apólice',
                                    align: 'center',
                                    width: 100,
                                    render: ({ seg_numeroapolice }) => (
                                        <b>{seg_numeroapolice}</b>
                                    )
                                },
                                {
                                    title: 'Nº Averbação',
                                    align: 'center',
                                    width: 100,
                                    render: ({ seg_numeroaverbacao }) => (
                                        <b>{seg_numeroaverbacao}</b>
                                    )
                                },
                                {
                                    title: 'Ações',
                                    align: 'center',
                                    width: 65,
                                    dataIndex: '',
                                    key: 'x',
                                    fixed: 'right',
                                    render: (record) => {
                                        return (
                                            <div>
                                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                                    <Col>
                                                        <Button icon={<EditOutlined />} onClick={() => editarSeguro(record)} />
                                                    </Col>
                                                    <Col>
                                                        <Button icon={<DeleteOutlined />} onClick={() => modalRemove(record)} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }
                                }
                            ]} pagination={false} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                )
                            }} scroll={{ y: 100 }} />
                        </Form>
                    </div>
                </Col>
            </Row>
            <Form form={formManifesto} layout="vertical">
                <Row gutter={[8, 0]} align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Informação Adicional" name="mdf_infadicionalfisco">
                            <Input.TextArea maxLength={2000} autoSize={{ maxRows: 3 }} placeholder="Digite aqui..." />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="Informação Complementar" name="mdf_infcomplentar">
                            <Input.TextArea maxLength={5000} autoSize={{ maxRows: 3 }} placeholder="Digite aqui..." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}