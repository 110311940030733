import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table, Tag } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import FiltroServicoNFSE from "../../components/filtroServicoNfse";
import { FormatNumber } from "../../ValueObjects";

export default function ServicosNFSE({ form, dadosItens, infoEndereco, operacaoFiscal, dadosVendedor, carregarPagina }) {
    const [quantidadeItens, setQuantidadeItens] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});

    useEffect(() => {
        if (dadosItens.dadosItens.length > 0) {
            let total = 0;
            setQuantidadeItens(dadosItens.dadosItens.length);
            dadosItens.dadosItens.forEach((item) => {
                total += parseFloat(item.total);
            });
            setValorTotal(total);
            form.setFieldsValue({ valorTotal: total });
        } else {
            setQuantidadeItens(0);
            setValorTotal(0);
        }
    }, [dadosItens.dadosItens]);

    function editarServicoNota(registro) {
        setDadosEditando(registro);
        setEditando(true);
    }

    function removerServicoNota(record) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover o item da nota?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let dadosTemp = [...dadosItens.dadosItens];
                if (record.nfi_numeroitem) {
                    let dadosRet = dadosTemp.filter((item) => item.nfi_numeroitem !== record.nfi_numeroitem);
                    dadosItens.setDadosItens(dadosRet);
                } else if (record.pdi_numeroitem) {
                    let dadosRet = dadosTemp.filter((item) => item.pdi_numeroitem !== record.pdi_numeroitem);
                    dadosItens.setDadosItens(dadosRet);
                }
            }
        });
    };

    return (
        <div className="m-t-16">
            <FiltroServicoNFSE form={form} dadosTabela={dadosItens} infoEndereco={infoEndereco} operacaoFiscal={operacaoFiscal} dadosVendedor={dadosVendedor} carregarPagina={carregarPagina} 
                btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
            <div className="tabela mt-dif">
                {!!dadosItens.dadosItens && dadosItens.dadosItens.length > 0 && <Table columns={[
                    {
                        title: 'Serviço',
                        width: 220,
                        render: ({ pro_codigo, pro_descricao }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                        <b> {pro_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'CFOP',
                        align: 'center',
                        width: 40,
                        render: ({ nfi_cfop }) => (
                            <div>
                                <b>{nfi_cfop}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Qtde.',
                        width: 40,
                        align: 'center',
                        render: ({ nfi_qtde }) => (
                            <div>
                                <b>{FormatNumber(nfi_qtde, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unt.(R$)',
                        align: 'right',
                        width: 40,
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Desc.',
                        align: 'right',
                        width: 40,
                        render: ({ percdesc }) => (
                            <div>
                                {/* <b>{percdesc}%</b> */}
                                <b>{FormatNumber(percdesc, true)}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Acres.',
                        align: 'right',
                        width: 40,
                        render: ({ percacresc }) => (
                            <div>
                                <b>{FormatNumber(percacresc, true)}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Bc. Issqn',
                        align: 'right',
                        width: 40,
                        render: ({ impostos }) => (
                            <div>
                                <b>{FormatNumber(impostos?.retornoISSQN?.ret_baseIssqn, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: '% Issqn',
                        align: 'right',
                        width: 40,
                        render: ({ impostos }) => (
                            <div>
                                <b>{FormatNumber(impostos?.retornoISSQN?.ret_aliquotaIssqn, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Vlr Issqn',
                        align: 'right',
                        width: 40,
                        render: ({ impostos }) => (
                            <div>
                                <b>{FormatNumber(impostos?.retornoISSQN?.ret_valorIssqn, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        width: 60,
                        render: ({ total }) => (
                            <div>
                                <b>{FormatNumber(total, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 40,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => editarServicoNota(record)} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => removerServicoNota(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={dadosItens.dadosItens} scroll={{ x: 900 }} />}
            </div>
            <div>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Quantidade Serviços:</b>
                    </Col>
                    <Col className="f-21">
                        <b>{quantidadeItens}</b>
                    </Col>
                </Row>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Valor Total dos Serviços (R$):</b>
                    </Col>
                    <Col className="f-21">
                        <b>{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div>
        </div>
    )
}