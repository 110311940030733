import React, { useEffect, useState } from "react";
import { Row, Col, Form, Collapse, Button, Tag, Menu, Dropdown } from "antd";
import { DownloadOutlined, ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, EyeOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { Modal } from 'antd';
import DrawerRemessa from './drawer';
import { useStateValue } from '../../state';
import { linkApi } from "../../services/auth";
import { listagemActions, manutencaoActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import ModalDetalheRemessa from "./modalDetalheRemessa";
import { BreadcrumbIncluirDados, TabelaDados, Download, Data, FiltroSearch, ComboData } from "../../components";

export default function Remessa() {

    const [open, setOpen] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [clientes, setClientes] = useState([]);
    const [formulario] = Form.useForm();
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [dadosDetalhesRemessa, setDadosDetalhesRemessa] = useState({});

    useEffect(() => {
        api.get(`Cliente/Listar?filtro=&ordem=%2brem_id`).then(
            res => {
                setClientes(res.data.items);
            }).catch(error => {
                console.log(error);
            })
        listarRemessas();
        formulario.setFieldsValue({ dataFinal: moment(new Date()), dataInicial: moment(formulario.getFieldValue().dataPesquisa) });
    }, [])

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function listarRemessas() {
        let cliente = !!formulario.getFieldValue().codigoCliente ? formulario.getFieldValue().codigoCliente : 0;
        let notaFiscal = !!formulario.getFieldValue().notaFiscal ? formulario.getFieldValue().notaFiscal : 0;
        let dataInicial = !!formulario.getFieldValue().dataInicial ? moment(formulario.getFieldValue().dataInicial).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let dataFinal = !!formulario.getFieldValue().dataFinal ? moment(formulario.getFieldValue().dataFinal).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataInicial=${dataInicial}&dataFinal=${dataFinal}&IdCliente=${cliente}&IdNota=${notaFiscal}`, ordem: '-rem_id' } });
    }

    function downloadArquivo(dados) {
        api.post(`RemessaBoleto/DownloadArquivo`, dados).then((retornoRemessa) => {
            if (!!retornoRemessa) {
                var parts = retornoRemessa.headers['content-type'].split('/');
                var filename = parts[0];
                var contentType = parts[1];
                try {
                    var blob = new Blob([retornoRemessa.data], { type: contentType });
                    //verifica se o usuário usa IE
                    var ua = window.navigator.userAgent;
                    var msie = ua.indexOf("MSIE ");
                    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else  //se for outro navegador, return 0
                    {
                        //Cria a url para o blob
                        var url = window.URL.createObjectURL(blob);
                        var linkElement = document.createElement('a');
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", filename);
                        //Força o download
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function menuTableOutros(dados) {
        return (
            <Menu className="text-right">
                <Menu.Item key="4">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizarRemessa(dados); }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="10">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => {
                        ExcluirRemessa(dados)
                    }}
                    >Excluir</Button>
                </Menu.Item>
            </Menu>
        )
    };

    async function visualizarRemessa(dados) {
        let retorno = [];
        if (!!dados.rem_id) {
            retorno = await api.get(`RemessaBoleto/ListarDetalhes?Id=${dados.rem_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data.items;
                setDadosDetalhesRemessa(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    async function ExcluirRemessa(dados) {
        if (!!dados.rem_id) {
            Modal.confirm({
                title: "Confirmação",
                content: "Deseja realmente excluir a remessa?",
                okText: "Sim",
                cancelText: "Não",
                onOk: async () => {
                    try {
                        await api.delete(`RemessaBoleto/Excluir/${dados.rem_id}`);
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+rem_nome' } });
                        listarRemessas();
                    } catch (erro) {
                        console.error(erro); // Opcional: log de erro
                    }
                },
                onCancel: () => {
                    console.log("Exclusão cancelada"); // Opcional: log de cancelamento
                },
            });
        }
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <div className="b-col">
                <Form layout="vertical">
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={17} md={17} lg={18} xl={18}>
                                    <FiltroSearch />
                                </Col>
                                <Col xs={24} sm={7} md={7} lg={6} xl={6}>
                                    <ComboData form={formulario} eventoAoAlterar={() => {
                                        formulario.setFieldsValue({ dataFinal: moment(new Date()) });
                                        formulario.setFieldsValue({ dataInicial: moment(formulario.getFieldValue().dataPesquisa) });
                                        listarRemessas();
                                    }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <Form layout="vertical" form={formulario} onFinish={listarRemessas}>
                    <Row>
                        <Col span={24}>
                            <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={togglePesquisa} block>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                    </Button>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                                            <Data label="Data Inicial" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                                            <Data label="Data Final" name="dataFinal" />
                                        </Col>
                                        {/* <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={5}>
                                            <Form.Item label="Cliente" name="codigoCliente">
                                                <Select showSearch optionFilterProp="children" allowClear={true} placeholder="Selecione um Cliente">
                                                    {clientes.map((cliente) => (
                                                        <Select.Option value={cliente.cli_id} key={cliente.cli_id}>{cliente.pes_nome}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col> */}
                                        {/* <Col xs={24} sm={16} md={8} lg={8} xl={6} xxl={4}>
                                            <Form.Item label="Nota Fiscal" name="notaFiscal">
                                                <Input placeholder="Nota Fiscal" />
                                            </Form.Item>
                                        </Col> */}
                                        <Col xs={24} sm={8} md={24} lg={24} xl={4} xxl={2} className="btn-b-mob">
                                            <Row justify="end">
                                                <Col xs={24} sm={24} md={5} lg={4} xl={24}>
                                                    <Button type="primary" block icon={<SearchOutlined />} htmlType="submit">
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                    <div className="tabela mt-dif">
                        <TabelaDados url="RemessaBoleto/Listar" colunas={
                            [
                                {
                                    title: 'Código',
                                    width: '8%',
                                    align: 'center',
                                    render: ({ rem_id }) => (
                                        <Tag color="processing" className="w-100">
                                            <b>{rem_id}</b>
                                        </Tag>
                                    ),
                                },
                                {
                                    title: '',
                                    width: '40%',
                                    render: ({ usu_id, ban_id, ban_descricao }) => (
                                        <div>
                                            <Row align="middle" gutter={[8, 0]}>
                                                <Col>
                                                    <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${ban_id}`} alt={ban_descricao} />
                                                </Col>
                                                <Col>
                                                    <b>Usuário {usu_id}</b>
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Nome Arquivo',
                                    width: '20%',
                                    render: ({ rem_nomearquivoremessa }) => (
                                        <div>
                                            <b>{rem_nomearquivoremessa}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Data',
                                    width: '8%',
                                    render: ({ rem_datageracao }) => (
                                        <div>
                                            <b>{moment(rem_datageracao).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Quant.',
                                    align: 'center',
                                    width: '8%',
                                    render: ({ rem_qtdeboletos }) => (
                                        <div>
                                            <b>{rem_qtdeboletos}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor (R$)',
                                    align: 'right',
                                    width: '20%',
                                    render: ({ rem_valortotal }) => (
                                        <div>
                                            <b className="f-18">{FormatNumber(rem_valortotal, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: '8%',
                                    fixed: 'right',
                                    render: (dados) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                {/* <Col>
                                                    <Imprimir icon={<PrinterOutlined />} />
                                                </Col> */}
                                                <Col>
                                                    <Download icon={<DownloadOutlined />} onClick={() => downloadArquivo(dados)} />
                                                </Col>
                                                <Col>
                                                    <Dropdown overlay={menuTableOutros(dados)} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                            ]
                        } />
                    </div>
                </Form>
            </div>
            <DrawerRemessa listarRemessas={listarRemessas} />
            <ModalDetalheRemessa exibirModal={openModalDetalhes} fecharModal={() => { setOpenModalDetalhes(false) }} dadosModal={dadosDetalhesRemessa} />
        </div >
    );

}