import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Table, Tooltip, Divider } from "antd";
import { EditOutlined, DeleteOutlined, UndoOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormatNumber } from '../../ValueObjects';
import { FiltroProdutoPdi, Tabela } from "../../components";

export default function TabItens({ formulario, dadosEndereco, dadosTabela, totalPedido, custo, dadosOperacao }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});
    const [quantidade, setQuantidade] = useState(0);
    const [mostrarFaturado, setMostrarFaturado] = useState(false);
    const [mostrarDevolvido, setMostrarDevolvido] = useState(false);

    useEffect(() => {
        if (!!totalPedido?.setTotalPedido) {
            let valorTotal = 0;
            let qtd = 0;
            let seqKey = 0;

            if (dadosTabela?.dadosItens) {
                dadosTabela.dadosItens.forEach((dado) => {
                    valorTotal += dado.pdi_quantidade - dado.pdi_qtdedevolvido === 0 ? 0 : dado.valorItemDesconto || 0;
                    qtd += dado.pdi_quantidade - dado.pdi_qtdedevolvido === 0 ? 0 : dado.pdi_quantidade || 0;
                    dado.key = seqKey++;

                    if (dado.pdi_qtdeentregue > 0) {
                        setMostrarFaturado(true);
                    }
                });
            }
            totalPedido.setTotalPedido(valorTotal);
            setQuantidade(qtd);
        } else {
            totalPedido.setTotalPedido(0);
            setQuantidade(0);
        }
    }, [dadosTabela.dadosItens]);

    useEffect(() => {
        const temDevolvido = dadosTabela?.dadosItens.some(item => item.pdi_qtdedevolvido > 0);
        setMostrarDevolvido(temDevolvido || false);
    }, [dadosTabela.dadosItens]);

    function editarProdutos(item) {
        setDadosEditando(item);
        setEditando(true);
    };

    function calcularTotais(itens) {
        return itens.reduce((acc, item) => {
            const quantidade = item.pdi_quantidade - (item.pdi_qtdedevolvido || 0);
            acc.qtd += quantidade;
            acc.valor += item.pdi_valorunitario * quantidade;
            return acc;
        }, { qtd: 0, valor: 0 })
    }

    function removerItem(item) {
        let dadosTemp = [...dadosTabela.dadosItens];

        if (!item.pdi_id) {
            dadosTemp = dadosTemp.filter((dados) => dados !== item);
            dadosTemp = dadosTemp
                .sort((a, b) => b.pdi_numeroitem - a.pdi_numeroitem)
                .map((dados, index) => ({
                    ...dados,
                    pdi_numeroitem: dadosTemp.length - index
                }));
        } else {
            let itemEncontrado = dadosTemp.find((i) => i.pdi_id === item.pdi_id);
            if (itemEncontrado) {
                itemEncontrado.pdi_qtdedevolvido = item.pdi_quantidade;
                setMostrarDevolvido(true);
            }
        }

        let { qtd, valor } = calcularTotais(dadosTemp);
        totalPedido.setTotalPedido(valor);
        setQuantidade(qtd);
        dadosTabela.setDadosItens(dadosTemp);
    }

    function desfazerDevolucao(record) {
        let dadosTemp = [...dadosTabela.dadosItens];
        let itemEncontrado = dadosTemp.find((item) => item.pdi_id === record.pdi_id);

        if (itemEncontrado) {
            itemEncontrado.pdi_qtdedevolvido = 0;
        }

        dadosTabela.setDadosItens(dadosTemp);

        let { qtd, valor } = calcularTotais(dadosTemp); 
        totalPedido.setTotalPedido(valor);
        setQuantidade(qtd);

        if (!dadosTemp.some((item) => item.pdi_qtdedevolvido > 0)) {
            setMostrarDevolvido(false);
        }
    }

    return (
        <div>
            <FiltroProdutoPdi form={formulario} dadosTabela={dadosTabela} dadosEndereco={dadosEndereco} custo={custo} dadosOperacao={dadosOperacao} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
            <div className="tabela mt-dif">
                {!!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 &&
                    <Table dataSource={dadosTabela.dadosItens}
                        columns={[
                            {
                                title: 'ITEM',
                                width: '25%',
                                fixed: 'left',
                                render: ({ pro_codigo, pro_descricao, pro_ean, descricaograde, prg_ean, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                <Tag color={pdi_qtdedevolvido === pdi_quantidade ? "default" : "processing"} className="w-75">
                                                    <b>{pro_codigo}</b>
                                                </Tag>
                                                {pdi_qtdedevolvido === pdi_quantidade ? <b><s> {pro_descricao}</s></b> : <b> {pro_descricao}</b>}
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                {pdi_qtdedevolvido == pdi_quantidade ?
                                                    <s>
                                                        EAN: <b> {pro_ean ? pro_ean : prg_ean}</b>
                                                    </s> :
                                                    <span>
                                                        EAN:  <b>{pro_ean ? pro_ean : prg_ean}</b>
                                                    </span>
                                                }
                                            </Col>
                                            <Col hidden={!!!descricaograde}>
                                                {pdi_qtdedevolvido == pdi_quantidade ?
                                                    <s>
                                                        Grade: <b> {descricaograde}</b>
                                                    </s> :
                                                    <span>
                                                        Grade: <b>{descricaograde}</b>
                                                    </span>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'Faturado',
                                align: 'center',
                                width: '5%',
                                fixed: 'left',
                                hidden: !mostrarFaturado,
                                render: ({ pdi_qtdeentregue }) => (
                                    <div>
                                        <Row gutter={[8, 0]} justify="center">
                                            <Col>
                                                {pdi_qtdeentregue > 1 ? <b>{pdi_qtdeentregue} itens</b> : pdi_qtdeentregue === 1 ? <b>{pdi_qtdeentregue} item</b> : <b>0</b>}
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'Devolvido',
                                align: 'center',
                                width: '5%',
                                fixed: 'left',
                                hidden: !mostrarDevolvido,
                                render: ({ pdi_quantidade, pdi_qtdedevolvido, pdi_qtdeentregue }, record) => (
                                    <div>
                                        <Row gutter={[8, 0]} justify="center">
                                            <Col>
                                                {pdi_qtdedevolvido > 1 ? <b>{pdi_qtdedevolvido} itens</b> : pdi_qtdedevolvido === 1 ? <b>{pdi_qtdedevolvido} item</b> : <b>0</b>}
                                                {pdi_qtdedevolvido > 0 &&
                                                    <Tooltip title="Desfazer a devolução">
                                                        <Button type="link" onClick={() => desfazerDevolucao(record)} icon={<UndoOutlined />} />
                                                    </Tooltip>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'N°',
                                align: 'center',
                                width: '2%',
                                fixed: 'left',
                                render: ({ pdi_numeroitem }) => (
                                    <div>
                                        <b>{pdi_numeroitem}</b>
                                    </div>
                                )
                            },
                            // {
                            //     title: 'CFOP',
                            //     align: 'center',
                            //     width: '4%',
                            //     fixed: 'left',
                            //     render: ({ pdi_cfop }) => (
                            //         <div>
                            //             <b>{nfi_cfop}</b>
                            //         </div>
                            //     ),
                            // },
                            // {
                            //     title: `${dadosContribuinte.emp_regimetributario === 1 ? 'CSOSN' : 'CST'}`,
                            //     align: 'center',
                            //     width: '5%',
                            //     fixed: 'left',
                            //     render: ({ cst_id, cso_id }) => (
                            //         <div>
                            //             <Col >
                            //                 <b>{dadosContribuinte.emp_regimetributario === 1 ? cso_id : cst_id}</b>
                            //             </Col>
                            //         </div>
                            //     ),
                            // },
                            {
                                title: 'Qtde.',
                                width: '4%',
                                align: 'right',
                                fixed: 'left',
                                render: ({ pdi_quantidade, pdi_qtdedevolvido }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ? <b><s>{FormatNumber(pdi_quantidade, true)}</s></b> : <b>{FormatNumber(pdi_quantidade, true)}</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Unt.(R$)',
                                align: 'right',
                                width: '5%',
                                fixed: 'left',
                                render: ({ pdi_valorunitario, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ? <b><s>{FormatNumber(pdi_valorunitario, true)}</s></b> : <b>{FormatNumber(pdi_valorunitario, true)}</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Desc.',
                                align: 'right',
                                width: '4%',
                                render: ({ percdesc, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ? <b><s>{FormatNumber(percdesc, true)}%</s></b> : <b>{FormatNumber(percdesc, true)}%</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Acres.',
                                align: 'right',
                                width: '4%',
                                render: ({ pdi_percacresc, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ? <b><s>{FormatNumber(pdi_percacresc, true)}%</s></b> : <b>{FormatNumber(pdi_percacresc, true)}%</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Frete',
                                align: 'right',
                                width: '4%',
                                render: ({ pdi_valorfrete, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ? <b><s>{FormatNumber(pdi_valorfrete, true)}</s></b> : <b>{FormatNumber(pdi_valorfrete, true)}</b>}
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMS?.ret_baseIcms, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMS?.ret_baseIcms, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: '% Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMS?.ret_aliquotaIcms, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMS?.ret_aliquotaIcms, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMS?.ret_valorIcms, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMS?.ret_valorIcms, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMSST?.ret_baseIcmsSt, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMSST?.ret_baseIcmsSt, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: '% ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMSST.ret_aliquotaIcmsSt, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMSST?.ret_aliquotaIcmsSt, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(impostos?.retornoICMSST.ret_aliquotaIcmsSt, true)}</s></b>
                                            :
                                            <b>{FormatNumber(impostos?.retornoICMSST?.ret_valorIcmsSt, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: '6%',
                                fixed: 'right',
                                render: ({ valorItemDesconto, pdi_qtdedevolvido, pdi_quantidade }) => (
                                    <div>
                                        {pdi_qtdedevolvido === pdi_quantidade ?
                                            <b><s>{FormatNumber(valorItemDesconto, true)}</s></b>
                                            :
                                            <b>{FormatNumber(valorItemDesconto, true)}</b>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: '5%',
                                fixed: 'right',
                                render: ({ pdi_qtdedevolvido, pdi_quantidade }, record, e, indice) => (
                                    <div>
                                        <Row align="center" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button disabled={pdi_qtdedevolvido === pdi_quantidade} icon={<EditOutlined />} onClick={() => editarProdutos(record)} />
                                            </Col>
                                            <Col>
                                                <Button disabled={pdi_qtdedevolvido === pdi_quantidade} icon={<DeleteOutlined />} onClick={() => removerItem(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ].filter(item => !item.hidden)}
                        scroll={{
                            x: 1500,
                            y: 300,
                        }}
                    />
                }
            </div>
            <div className="m-t-16">
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col className="f-16">
                        Quantidade:
                    </Col>
                    <Col>
                        <b className="f-21">{quantidade}</b>
                    </Col>
                </Row>
                <Row justify="end" align="middle" gutter={[8, 0]}>
                    <Col>
                        <span className="f-14"> Valor Total dos Itens (R$):</span>
                    </Col>
                    <Col>
                        <b className="f-16">
                            {FormatNumber(totalPedido.totalPedido, true)}
                        </b>
                    </Col>
                </Row>
            </div>
        </div>
    );
}