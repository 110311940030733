import React, { useEffect, useState } from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout, Modal } from "antd";

import api from '../../services/api';
import { useStateValue } from "../../state";
import { MenuPage, HeaderPage, Data } from "../../components";
import { getEmpresa, getValidadeCertificado, setNavegacao, setPermissoes, setStatusPlano, setValidadeCertificado, logout } from '../../services/auth'
import { tributacaoActions, parametrosEmpresaActions } from "../../actions";
import {
    Login, Inicial, Dashboard, Clientes, ResumoCliente, Fornecedor, Transportador, Funcionario, Vendedor, TributacaoIcms, TributacaoPisCofins, NcmSubtituicaoTributaria,
    Marca, GrupoProduto, SubGrupo, Produto, GradeProduto, Preco, Etiqueta, ResumoProduto, RelatorioEtiqueta, ContaCorrente, ContaGerencial, OperacaoFiscal, OperacaoDebitoCredito,
    FormaPagamento, CondicaoPagamento, SerieFiscal, Historico, NotaFiscal, ImportarNotaFiscalEntrada, MonitoramentoNfe, NotaFiscalTransferencia, CartaCorrecaoNotaFiscal, InutilizacaoNfe, OrdemCompra,
    NotaFiscalDevolucao, Orcamento, StatusPedido, Pedidos, Condicional, ValePresente, Metas, Comissao, ConsultaComissao, ContaReceber, RecebimentoParcela, ContaPagar,
    PagamentoParcela, LancamentoCaixa, LancamentoCaixaManutencao, LancamentoCaixaTransferencia, FluxoCaixa, ConciliacaoCartoes, Cheque, Boleto, Remessa, RetornoBancario,
    RetornoBancarioLista, ArmazemEstoque, Estoque, EstoqueManutencao, Inventario, MatrizFilial, ConfiguracaoNfeNfce, GerenciarPdv, LimiteCreditoCliente, Contador, Plano,
    BandeiraCartaoEmpresa, CentroDistribuicao, Relatorios, AnaliseInadimplencia, PersonalizacaoRelatorios, FiltrosRelatorio, Usuario, GrupoUsuario, EfdIcmsIpi, Notificacao,
    ParametrosEmpresa, CamposPersonalizados, RelacionamentoFornecedorProduto, Restaurante, Hotel, ManutencaoProduto, VigenciaFiscal, TabelaRetencao, ParametrosLocal, ImportacaoNotaFiscalEntrada, AcaoUsuario, DownloadXml, KitComposicao,
    Balanca, MarcaVeiculo, Veiculo, ConsultaRecebimento, ManifestoEletronico, RetencaoPessoaProduto, ConsultaCartoesConciliados, ImportarNotaFiscalSuporte, ParcelaCartao, Servico, Cidade, ConhecimentoDeTransporte
} from "../";
import EtiquetaServidor from '../etiquetaServidor';
import ImportarPessoa from '../importarPessoa';
import AntecipacaoClienteForncedor from '../antecipacao';
import SemAcesso from '../semAcesso';
import AliquotaSimples from '../aliquotaSimplesNacional';
import { diferencaDatas, isObjetoDiffVazio, retornaCep } from '../../services/funcoes';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { format } from 'date-fns';




export default function Home() {

    const [, dispatch] = useStateValue();

    useEffect(() => {
        empresaUsuario();
        buscarParametros();
        resetarNav();
        buscarPermissoesUsuario();
        buscarStatusPlano();
        buscarDadosCertificado();
    }, []);

    function empresaUsuario() {
        api.get('/Empresa/Buscar/' + parseInt(getEmpresa())).then(
            res => {
                if (res.status === 200) {
                    dispatch({
                        type: tributacaoActions.CHANGE,
                        data: { empRegimeTributario: res.data.emp_regimetributario, empEnquadramentoFiscal: res.data.emp_enquadramentofiscal }
                    });
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    };
    async function retornaConfiguracaoCertificado() {
        try {
            const res = await api.get('/LocalConfigNfe/RetornaConfiguracaoNfe');
            if (res.status === 200 && res.data.lcn_validadecertificado) {
                console.log("Retorno certificado:", res.data);
                var clickNaoExibir = {
                    dataValidade: res.data.lcn_validadecertificado,
                    naoMostrarNovamente: false,
                    diferencaDias: [15, 10, 5, 1, 0],
                };
                setValidadeCertificado(clickNaoExibir);
            } else {
                console.error("Resposta inesperada da API:", res);
            }
        } catch (erro) {
            console.error("Erro ao buscar configuração do certificado:", erro);
        }
    }
    function naoExibirMensagem() { //quando chamada função joga "naoMostrarNovamente"=true e nao exibe a mensagem até o proximo logout
        let dados = getValidadeCertificado();
        dados.naoMostrarNovamente = true;  
        setValidadeCertificado(dados);
    }
    function exibirModalAviso(diasParaVencimento, dataValidade) { // Aviso exibido sempre que o valor de dif estiver dentro de [15, 10, 5, 1,]
        let formattedDate = moment(dataValidade).format("DD/MM/YYYY");
        const diaOuDias = diasParaVencimento === 1 ? "dia" : "dias"; //trata plural ou singular
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    <p>O certificado expira em: {diasParaVencimento} {diaOuDias}!</p>
                    <p>Vencimento em: {formattedDate}</p>
                </>
            ),
            cancelText: 'Não exibir novamente',
            okText: 'Ok',
            onCancel: () => {
                exibirModalAvisoDiferencaDias(diasParaVencimento); // clicando em "nao exibir novamente" chama função de aviso2
            },
        });
    }
    function exibirModalAvisoDiferencaDias(diferencaDias) { //aviso2, mostra em quantos dias a mensagem sera exibida de novo
        const diaOuDias = diferencaDias === 1 ? "dia" : "dias";
        Modal.info({
            title: 'Atenção!',
            content: (
                <div>
                    <p>
                        A mensagem será exibida novamente em {diferencaDias} {diaOuDias} ou no próximo acesso ao sistema.
                    </p>
                </div>
            ),
            okText: 'Ok',
            onOk: () => {
                naoExibirMensagem(); //clicando em "ok" sistema não mostra a mensagem novamente até o proximo logout;
            }
        });
    }
    function exibirModalVencimentoHoje() { //aviso exibido no dia de vencimento (dif===0)
        Modal.confirm({
            title: 'Certificado Digital vence hoje!',
            content: (
                <div>
                    <p>Após o vencimento, a emissão de notas será bloqueada. Contate seu contador!</p>
                </div>
            ),
            cancelText: 'Não exibir novamente',
            okText: 'Ok',
            onCancel: () => {
                naoExibirMensagem(); 
            }
        });
    }
    async function buscarDadosCertificado() {
    
        let dados = getValidadeCertificado();
        if (!isObjetoDiffVazio(dados)) {
            return
        }
    
        if (dados.naoMostrarNovamente === true) {
            return;
        }
    
        if (!dados.dataValidade || !dados.diferencaDias) {
            await retornaConfiguracaoCertificado();
            dados = getValidadeCertificado();
    
            if (!isObjetoDiffVazio(dados) || !dados.dataValidade) {
                console.error("Erro ao obter dados do certificado.");
                return;
            }
        }
        const dif = diferencaDatas(dados.dataValidade);
    
        if (dif === 0) {
            exibirModalVencimentoHoje();
        } else if ([15, 10, 5, 1, 0].includes(dif)) {
            exibirModalAviso(dif, dados.dataValidade);
        }
    }

    function buscarParametros() {
        api.get('/ParametroEmpresa/BuscarParametros').then(
            res => {
                if (res.status === 200) {
                    dispatch({ type: parametrosEmpresaActions.CHANGE, data: { markupProduto: res.data.par_markupproduto, par_diastoleranciacobrancajuro: res.data.par_diastoleranciacobrancajuro, par_percjuromesparcelavencida: res.data.par_percjuromesparcelavencida, par_multaparcelavencida: res.data.par_multaparcelavencida, par_tipocalculomulta: res.data.par_tipocalculomulta } });
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    };

    function resetarNav() {
        if (!!!localStorage.getItem("@GestorWeb-Navegacao")) {
            setNavegacao({ geral: {} });
        }
    };


    function buscarPermissoesUsuario() {
        api.get(`PermissaoUsuario/Listar`).then(
            (res) => {
                setPermissoes(res.data);
            }
        ).catch(
            erro => {
                if (erro.response.status === 426) {
                    Modal.warn({
                        title: erro.response.data,
                    });
                    window.location = '/Home#/semAcesso'
                } else {
                    console.log(erro);
                }
            }
        );
    };

    function buscarStatusPlano() {
        api.get(`LocalPlano/VerificarStatusPlano`).then(
            () => {

            }
        ).catch(
            erro => {
                if (erro.response.status === 402) {
                    setStatusPlano('VENCIDO');
                    window.location = '/renovarPlano#'
                }
            }
        );
    };
    return (

        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-55">
                        <MenuPage />
                        <Layout className="site-layout">
                            <Layout.Content>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/" component={Inicial} />
                                    <Route exact path="/dashboard" component={Dashboard} />
                                    <Route exact path="/cliente" component={Clientes} />
                                    <Route exact path="/resumoCliente" component={ResumoCliente} />
                                    <Route exact path="/fornecedor" component={Fornecedor} />
                                    <Route exact path="/transportador" component={Transportador} />
                                    <Route exact path="/funcionario" component={Funcionario} />
                                    <Route exact path="/vendedor" component={Vendedor} />
                                    <Route exact path="/tributacaoIcms" component={TributacaoIcms} />
                                    <Route exact path="/tributacaoPisCofins" component={TributacaoPisCofins} />
                                    <Route exact path="/ncm" component={NcmSubtituicaoTributaria} />
                                    <Route exact path="/marca" component={Marca} />
                                    <Route exact path="/marcaVeiculo" component={MarcaVeiculo} />
                                    <Route exact path="/veiculo" component={Veiculo} />
                                    <Route exact path="/manifestoEletronico" component={ManifestoEletronico} />
                                    <Route exact path="/grupoProduto" component={GrupoProduto} />
                                    <Route exact path="/subGrupo" component={SubGrupo} />
                                    <Route exact path="/produto" component={Produto} />
                                    <Route exact path="/gradeProduto" component={GradeProduto} />
                                    <Route exact path="/preco" component={Preco} />
                                    <Route exact path="/etiqueta" component={Etiqueta} />
                                    <Route exact path="/resumoProduto" component={ResumoProduto} />
                                    <Route exact path="/servico" component={Servico} />
                                    <Route exact path="/conhecimentoTransporte" component={ConhecimentoDeTransporte} />
                                    <Route exact path="/relatorioEtiqueta" component={RelatorioEtiqueta} />
                                    <Route exact path="/contaCorrente" component={ContaCorrente} />
                                    <Route exact path="/contaGerencial" component={ContaGerencial} />
                                    <Route exact path="/operacaoFiscal" component={OperacaoFiscal} />
                                    <Route exact path="/operacaoDebitoCredito" component={OperacaoDebitoCredito} />
                                    <Route exact path="/formaPagamento" component={FormaPagamento} />
                                    <Route exact path="/condicaoPagamento" component={CondicaoPagamento} />
                                    <Route exact path="/serieFiscal" component={SerieFiscal} />
                                    <Route exact path="/historico" component={Historico} />
                                    <Route exact path="/notaFiscal" component={NotaFiscal} />
                                    <Route exact path="/importarNotaFiscalEntrada" component={ImportarNotaFiscalEntrada} />
                                    <Route exact path="/importarNotaFiscalSuporte" component={ImportarNotaFiscalSuporte} />
                                    <Route exact path="/monitoramentoNfe" component={MonitoramentoNfe} />
                                    <Route exact path="/notaFiscalTransferencia" component={NotaFiscalTransferencia} />
                                    <Route exact path="/notaFiscalDevolucao" component={NotaFiscalDevolucao} />
                                    <Route exact path="/importacaonNotaFiscalEntrada" component={ImportacaoNotaFiscalEntrada} />
                                    <Route exact path="/cartaCorrecaoNotaFiscal" component={CartaCorrecaoNotaFiscal} />
                                    <Route exact path="/inutilizacaoNfe" component={InutilizacaoNfe} />
                                    <Route exact path="/ordemCompra" component={OrdemCompra} />
                                    <Route exact path="/orcamento" component={Orcamento} />
                                    <Route exact path="/pedido" component={Pedidos} />
                                    <Route exact path="/statusPedido" component={StatusPedido} />
                                    <Route exact path="/condicional" component={Condicional} />
                                    <Route exact path="/valePresente" component={ValePresente} />
                                    <Route exact path="/metasVendas" component={Metas} />
                                    <Route exact path="/comissao" component={Comissao} />
                                    <Route exact path="/consultaComissao" component={ConsultaComissao} />
                                    <Route exact path="/contaReceber" component={ContaReceber} />
                                    <Route exact path="/recebimentoParcela" component={RecebimentoParcela} />
                                    <Route exact path="/contaPagar" component={ContaPagar} />
                                    <Route exact path="/pagamentoParcela" component={PagamentoParcela} />
                                    <Route exact path="/lancamentoCaixa" component={LancamentoCaixa} />
                                    <Route exact path="/lancamentoCaixaManutencao" component={LancamentoCaixaManutencao} />
                                    <Route exact path="/lancamentoCaixaTransferencia" component={LancamentoCaixaTransferencia} />
                                    <Route exact path="/fluxoCaixa" component={FluxoCaixa} />
                                    <Route exact path="/conciliacaoCartoes" component={ConciliacaoCartoes} />
                                    <Route exact path="/cheque" component={Cheque} />
                                    <Route exact path="/boleto" component={Boleto} />
                                    <Route exact path="/remessa" component={Remessa} />
                                    <Route exact path="/retornoBancario" component={RetornoBancario} />
                                    <Route exact path="/retornoBancarioLista" component={RetornoBancarioLista} />
                                    <Route exact path="/armazemEstoque" component={ArmazemEstoque} />
                                    <Route exact path="/estoque" component={Estoque} />
                                    <Route exact path="/estoqueManutencao" component={EstoqueManutencao} />
                                    <Route exact path="/inventario" component={Inventario} />
                                    <Route exact path="/matrizFilial" component={MatrizFilial} />
                                    <Route exact path="/configuracaoNfeNfce" component={ConfiguracaoNfeNfce} />
                                    <Route exact path="/gerenciarPdv" component={GerenciarPdv} />
                                    <Route exact path="/limiteCreditoCliente" component={LimiteCreditoCliente} />
                                    <Route exact path="/contador" component={Contador} />
                                    <Route exact path="/plano" component={Plano} />
                                    <Route exact path="/bandeiraCartaoEmpresa" component={BandeiraCartaoEmpresa} />
                                    <Route exact path="/centroDistribuicao" component={CentroDistribuicao} />
                                    <Route exact path="/relatorios" component={Relatorios} />
                                    <Route exact path="/analiseInadimplencia" component={AnaliseInadimplencia} />
                                    <Route exact path="/personalizacaoRelatorios" component={PersonalizacaoRelatorios} />
                                    <Route exact path="/camposPersonalizados" component={CamposPersonalizados} />
                                    <Route exact path="/filtrosRelatorio" component={FiltrosRelatorio} />
                                    <Route exact path="/usuario" component={Usuario} />
                                    <Route exact path="/grupoUsuario" component={GrupoUsuario} />
                                    <Route exact path="/efdIcmsIpi" component={EfdIcmsIpi} />
                                    <Route exact path="/notificacao" component={Notificacao} />
                                    <Route exact path="/parametrosEmpresa" component={ParametrosEmpresa} />
                                    <Route exact path="/cidade" component={Cidade} />
                                    <Route exact path="/etiquetaservidor" component={EtiquetaServidor} />
                                    <Route exact path="/importarPessoa" component={ImportarPessoa} />
                                    <Route exact path="/antecipacao" component={AntecipacaoClienteForncedor} />
                                    <Route exact path="/semAcesso" component={SemAcesso} />
                                    <Route exact path="/relacionamentoFornecedorProduto" component={RelacionamentoFornecedorProduto} />
                                    <Route exact path="/aliquotaSimplesNacional" component={AliquotaSimples} />
                                    <Route exact path="/restaurante" component={Restaurante} />
                                    <Route exact path="/hotel" component={Hotel} />
                                    <Route exact path="/manutencaoProduto" component={ManutencaoProduto} />
                                    <Route exact path="/vigenciaFiscal" component={VigenciaFiscal} />
                                    <Route exact path="/tabelaRetencao" component={TabelaRetencao} />
                                    <Route exact path="/retencaoPessoaProduto" component={RetencaoPessoaProduto} />
                                    <Route exact path="/parametrosLocal" component={ParametrosLocal} />
                                    <Route exact path="/acaoUsuario" component={AcaoUsuario} />
                                    <Route exact path="/downloadXml" component={DownloadXml} />
                                    <Route exact path="/kitComposicao" component={KitComposicao} />
                                    <Route exact path="/balanca" component={Balanca} />
                                    <Route exact path="/consultaRecebimento" component={ConsultaRecebimento} />
                                    <Route exact path="/consultaCartoesConciliados" component={ConsultaCartoesConciliados} />
                                    <Route exact path="/parcelaCartao" component={ParcelaCartao} />
                                </Switch>
                            </Layout.Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>
    );
};