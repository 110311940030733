const FormaDePagamento = {
    fpDinheiro: 1,
    fpCheque: 2,
    fpCartaoCredito: 3,
    fpCartaoDebito: 4,
    fpCreditoLoja: 5,
    fpValeAlimentacao: 10,
    fpValeRefeicao: 11,
    fpValePresente: 12,
    fpValeCombustivel: 13,
    fpDuplicataMercantil: 14,
    fpBoletoBancario: 15,
    fpDepositoBancario: 16,
    fpPixDinamico: 17,
    fpTransferenciaBancaria: 18,
    fpProgramaFidelidade: 19,
    fpPixEstatico: 20,
    fpCreditoEmLoja: 21, 
    fpFalhaDeHardware: 22,
    fpSemPagamento: 90,
    fpOutro: 99,
}

export default FormaDePagamento;