import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Button, Table, Checkbox, notification } from "antd";
import { InfoCircleOutlined, RedoOutlined, InfoOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { Data } from "../../components";
import { linkApi } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { ModalGerarRemessa, ModalInstrucaoRemessa } from "../../components/modals/";
import SelectPaginado from "../../components/selectPaginado";

export default function ManutencaoRemessa({ formulario, aoSalvar, carregando }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [openModalInstrucaoRemessa, setOpenModalInstrucaoRemessa] = useState(false);
    const [openModalGerarRemessa, setOpenModalGerarRemessa] = useState(false);
    const [listaBancos, setListaBancos] = useState([]);
    const [listaParcelas, setListaParcelas] = useState([]);
    const [parcelaEditar, setParcelaEditar] = useState(null);
    const [dadosSelecionados, setDadosSelecionados] = useState({});
    const [conta, setConta] = useState(null);
    const [idBanco, setIdBanco] = useState(null);
    const [selecionado, setSelecionado] = useState(false);

    useEffect(() => {
        formulario.setFieldsValue({ dataEmissao: moment(new Date()) });
        listarParcelas();
    }, []);

    useEffect(() => {
        if (ui.showDrawer) {
            if (!!manutencao.dados && !!manutencao.dados.bol_dataInicio) {
                formulario.setFieldsValue({ bol_dataInicio: moment(manutencao.dados.bol_dataInicio), bol_dataFim: moment(manutencao.dados.bol_dataFim) });
            } else if (!!!formulario.getFieldsValue().bol_datainicio) {
                formulario.setFieldsValue({ bol_dataInicio: moment(new Date()), bol_dataFim: moment(new Date()) });
            }
        }
    }, [ui.showDrawer]);

    function selecionarItem(parcela) {
        let parcelas = [...listaParcelas];
        let itemEditar = parcelas.find((item) => { return item.bol_id === parcela });
        if (idBanco === itemEditar.ban_id || idBanco === null || idBanco === undefined) {
            itemEditar.selecionado = !itemEditar.selecionado;
            if (itemEditar.selecionado === false) {
                setIdBanco(null);
            }
            else {
                setIdBanco(itemEditar.ban_id);
            }
            setSelecionado(true);
            setListaParcelas(parcelas);
        } else {
            notification.warning({ message: 'Aviso', description: 'Selecione somente boletos do mesmo banco!' });
            return;
        }
    };

    function selecionarTodos(checked) {
        let parcelas = [...listaParcelas];
        // Verifica se todas as parcelas possuem o mesmo ban_id
        let verificaBanco = VerificaBanco(parcelas);
        if (verificaBanco === true) {
            parcelas.map((item) => {
                item.selecionado = (checked ? true : false);
            });
            setSelecionado(true);
            setListaParcelas(parcelas);
        }
        else {
            notification.warning({ message: 'Aviso', description: 'Não é possível Selecionar todos, pois ha parcelas de bancos diferentes!' });
            return;
        }
    };

    // Função para verificar se todos os IDs dos bancos são iguais
    const VerificaBanco = (parcelas) => {
        let firstId = parcelas[0].ban_id;
        return parcelas.every(item => item.ban_id === firstId);
    };

    function salvarInstrucao(instrucao) {
        let parcelas = [...listaParcelas];
        if (Array.isArray(parcelaEditar) === true) {
            parcelas.forEach((parcela, indice) => {
                parcela.isp_codigo = instrucao.isp_codigo;
                parcela.isp_id = instrucao.isp_id;
                parcela.isp_descricao = instrucao.isp_descricao;
                if (indice === parcelas.length - 1) {
                    setListaParcelas(parcelas);
                }
            });
        }
        if (!!parcelaEditar && typeof parcelaEditar === 'object' && parcelaEditar !== null && Array.isArray(parcelaEditar) === false) {
            let novasParcelas = parcelas.map((parcela) => {
                if (parcela.bol_id === parcelaEditar.bol_id) {
                    return {
                        ...parcela, // Copia os outros campos da parcela original
                        isp_id: instrucao?.isp_id, // Atualiza isp_id
                        isp_descricao: instrucao?.isp_descricao, // Atualiza isp_descricao
                    };
                }
                return parcela; // Retorna a parcela inalterada
            });
            setListaParcelas(novasParcelas);
        }
        setOpenModalInstrucaoRemessa(false);
    };

    async function gerarRemessa() {
        carregando(true);
        if (listaParcelas.filter((parcela) => { return parcela.selecionado }).length === 0) {
            notification.warning({ message: 'Aviso', description: 'É preciso selecionar pelo menos uma parcela!' });
            return;
        }
        let bancoId = listaParcelas.filter((parcela) => { return parcela.selecionado })[0].ban_id;
        if (!!formulario.getFieldValue().ban_id || !!bancoId) {
            let dados = {};
            //pega somente as parcelas selecionadas
            let parcelas = listaParcelas.filter((parcela) => { return parcela.selecionado });
            dados.ban_id = parcelas[0].ban_id;
            dados.ban_descricao = parcelas[0].ban_descricao;
            dados.rem_qtdeboletos = parcelas.length;
            dados.rem_valortotal = parcelas.reduce((total, bol) => total + bol.bol_valor, 0);
            let padraoArquivoRemessa = parcelas[0].dbl_padraoarquivoremessa;
            dados.padraoarquivoremessa = padraoArquivoRemessa;
            dados.dbl_extensaoarquivoremessa = parcelas[0].dbl_extensaoarquivoremessa;
            dados.parcelaboleto = tratarParcelasBoletos(parcelas);
            setDadosSelecionados(dados);
            setOpenModalGerarRemessa(true);
        }
        carregando(false);
    };

    function tratarParcelasBoletos(parcelas) {
        let parcelaboletos = [];
        parcelas.forEach((item) => {
            let dados = {
                prc_id: item.prc_id,
                bol_id: item.bol_id,
                isp_id: item.isp_id
            };
            parcelaboletos.push(dados);
        });
        return parcelaboletos;
    };

    function downloadArquivo() {
        let dados = dadosSelecionados;
        api.post(`RemessaBoleto/GerarRemessa`, dados).then((retornoRemessa) => {
            if (!!retornoRemessa) {
                var parts = retornoRemessa.headers['content-type'].split('/');
                var filename = parts[0];
                var contentType = parts[1];

                try {
                    var blob = new Blob([retornoRemessa.data], { type: contentType });
                    //verifica se o usuário usa IE
                    var ua = window.navigator.userAgent;
                    var msie = ua.indexOf("MSIE ");
                    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else  //se for outro navegador, return 0
                    {
                        //Cria a url para o blob
                        var url = window.URL.createObjectURL(blob);
                        var linkElement = document.createElement('a');
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", filename);
                        //Força o download
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                        setOpenModalGerarRemessa(false);
                        aoSalvar();
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function buscarContaCorrente(ctc_id) {
        let filtro = [];
        filtro = !!formulario.getFieldValue().ctc_id ? `Filtro=${formulario.getFieldValue().ctc_id}` : `Filtro=${(!!ctc_id ? ctc_id : '')}`;
        api.get(`ContaCorrente/Listar?${filtro}&ordem=%2Bban_descricao`).then((res) => {
            if (res.status === 200) {
                setListaBancos(res.data);
                setConta(ctc_id);
                formulario.setFieldsValue({ ban_id: res.data.items[0].ban_id, ctc_id: ctc_id });
                listarParcelas(ctc_id);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function listarParcelas(ctc_id) {
        let DataInicial = !!formulario.getFieldValue().bol_dataInicio ? formulario.getFieldValue().bol_dataInicio.format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let DataFinal = !!formulario.getFieldValue().bol_dataFim ? formulario.getFieldValue().bol_dataFim.format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let filtro = !!conta ? `Codigo=${conta}` : (!!ctc_id ? `Codigo=${ctc_id}` : '');
        filtro += `&DataInicial=${DataInicial}&DataFinal=${DataFinal}`
        api.get(`Boleto/ListarDados?${filtro}&ordem=%2Bbol_id`).then((res) => {
            if (res.status === 200) {
                setListaParcelas(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <div>
            <Form layout="vertical" form={formulario} name="manutencaoRemessa" onFinish={gerarRemessa}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={5} lg={4} xl={3}>
                        <Data label="Data de Inicio" name="bol_dataInicio" onChange={listarParcelas} />
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={4} xl={3}>
                        <Data label="Data de Fim" name="bol_dataFim" onSelect={listarParcelas} />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Conta Corrente" name="ctc_id">
                            <SelectPaginado url="ContaCorrente/Listar?ordem=%2Bctc_descricao" placeholder="Selecione a Conta Corrente" form={formulario} name="ctc_id"
                                onChangeFunction={(dados) => {
                                    buscarContaCorrente(dados);
                                }} conteudo={
                                    cc => (<Select.Option value={cc.ctc_id} key={cc.key} label={cc.ctc_descricao}>{cc.ctc_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5} className="t-mob-573">
                        <Button
                            icon={<InfoCircleOutlined />}
                            disabled={!selecionado} // Botão desativado quando `selecionado` for falso
                            onClick={() => {
                                if (selecionado) { // Apenas executa a ação se `selecionado` for verdadeiro
                                    setParcelaEditar(listaParcelas);
                                    setOpenModalInstrucaoRemessa(true);
                                }
                            }} block >
                            Instrução
                        </Button>
                    </Col>
                </Row>
                <div className="tabela">
                    <Table pagination={true} dataSource={listaParcelas} columns={
                        [
                            {
                                title: () => { return <div><Checkbox onChange={(event) => { selecionarTodos(event.target.checked) }} /></div> },
                                align: 'center',
                                width: 25,
                                render: ({ bol_id, selecionado }) => (
                                    <div>
                                        <Checkbox checked={selecionado} onChange={() => { selecionarItem(bol_id) }} />
                                    </div>
                                ),
                            },
                            {
                                title: "Cliente",
                                width: 180,
                                render: ({ pes_nome }) => (
                                    <div>
                                        <Row align="middle">
                                            <Col>
                                                <b>{pes_nome}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: "Série",
                                width: 40,
                                dataIndex: "ser_serie",
                                render: (ser_serie) => <b>{ser_serie}</b>,
                            },
                            {
                                title: "N.° Doc.",
                                width: 50,
                                dataIndex: "ntf_numero",
                                render: (ntf_numero) => <b>{ntf_numero}</b>,
                            },
                            {
                                title: "Parcela",
                                width: 50,
                                dataIndex: "prc_numeroparcela",
                                render: (prc_numeroparcela) => <b>{prc_numeroparcela}</b>,
                            },
                            {
                                title: "Banco, Data Venc.",
                                width: 105,
                                render: ({ bol_datavencimento, ban_id, ban_descricao }) => (
                                    <div>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col>
                                                <img
                                                    className="imgLogoListaBanco"
                                                    src={`${linkApi}Banco/RetornaLogo/${ban_id}`}
                                                    alt={ban_descricao}
                                                />
                                            </Col>
                                            <Col>
                                                <b>{moment(bol_datavencimento).format("DD/MM/YYYY")}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: "Data Remessa",
                                width: 90,
                                render: ({ rem_datageracao }) => (
                                    <div>
                                        <b>{moment(rem_datageracao).format("DD/MM/YYYY")}</b>
                                    </div>
                                ),
                            },
                            {
                                title: "Instrução Remessa",
                                width: 150,
                                render: ({ isp_descricao }) => (
                                    <div>
                                        <b>{isp_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: "Valor (R$)",
                                align: "right",
                                width: 90,
                                render: ({ bol_valor }) => (
                                    <div>
                                        <b className="f-18">{FormatNumber(bol_valor, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: "Ações",
                                dataIndex: "",
                                key: "x",
                                align: "center",
                                width: 58,
                                fixed: "right",
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button
                                                    icon={<InfoOutlined />}
                                                    onClick={() => {
                                                        setParcelaEditar(record);
                                                        setSelecionado(true);
                                                        setOpenModalInstrucaoRemessa(true);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                        scroll={{ x: 900 }}
                    />
                </div>
            </Form>
            <ModalInstrucaoRemessa exibirModalInstrucaoRemessa={openModalInstrucaoRemessa} fecharModalInstrucaoRemessa={() => setOpenModalInstrucaoRemessa(false)} form={formulario} parcela={parcelaEditar} onOk={salvarInstrucao} selecionado={selecionado} />
            <ModalGerarRemessa exibirModalGerarRemessa={openModalGerarRemessa} fecharModalGerarRemessa={() => setOpenModalGerarRemessa(false)} dadosSelecionados={dadosSelecionados} download={downloadArquivo} />
        </div>
    );

}