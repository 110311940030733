import React, { useState } from "react";
import { Button, Col, Row, Table, Tag } from "antd";

import { FormatNumber } from "../../ValueObjects";

export default function TabDetalheServico({ itensNF }) {
    const [percentualDesc, setPercentualDesc] = useState(true);

    function retornarPercentual(perc_desconto, nfi_valordesconto) {
        if (percentualDesc) {
            return (
                <div>
                    <b>{perc_desconto}</b>%
                </div>
            )
        } else {
            return (
                <div>
                    <b>{FormatNumber(nfi_valordesconto, true)}</b>
                </div>
            )
        }
    };

    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        width: '4%',
                        align: 'center',
                        fixed: 'left',
                        render: ({ pro_codigo }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: '',
                        fixed: 'left',
                        width: '20%',
                        render: ({ pro_descricao }) => (
                            <div>
                                <Row align="middle" gutter={[5, 5]}>
                                    <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <b>{(pro_descricao)}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'N°',
                        width: '1.5%',
                        align: 'center',
                        fixed: 'left',
                        render: ({ nfi_numeroitem }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{(nfi_numeroitem)}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'CFOP',
                        width: '2%',
                        align: 'center',
                        render: ({ nfi_cfop }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{nfi_cfop}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'UM.',
                        width: '2%',
                        align: 'center',
                        render: ({ nfi_unidademedida }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{nfi_unidademedida}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Qtde.',
                        width: '3%',
                        align: 'right',
                        render: ({ nfi_qtde }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{nfi_qtde}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unit.(R$)',
                        align: 'right',
                        width: '4%',
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Acrés.(R$)',
                        align: 'right',
                        width: '3%',
                        render: ({ nfi_valoroutros }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <b>{FormatNumber(nfi_valoroutros, true)}</b>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: () => {
                            return (
                                <div>
                                    <Row align="middle" justify="end" gutter={[4, 0]}>
                                        <Col>
                                            Desc.
                                        </Col>
                                        <Col>
                                            <Button size="small" onClick={() => { setPercentualDesc(!percentualDesc) }}>{(percentualDesc ? '%' : 'R$')}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        width: '3%',
                        align: 'right',
                        render: ({ perc_desconto, nfi_valordesconto }) => (
                            retornarPercentual(perc_desconto, nfi_valordesconto)
                        ),
                    },
                    {
                        title: 'BC Issqn',
                        width: '3%',
                        align: 'right',
                        render: ({ nfs_valorbc }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nfs_valorbc, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: '% Issqn',
                        width: '3%',
                        align: 'right',
                        render: ({ nfs_percissqn }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nfs_percissqn, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Vlr Issqn',
                        width: '3%',
                        align: 'right',
                        render: ({ nfs_valorissqn }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{FormatNumber(nfs_valorissqn, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        fixed: 'right',
                        width: '5%',
                        render: ({ nfi_valorunitario, nfi_qtde }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <b>{FormatNumber(nfi_qtde * nfi_valorunitario, true)}</b>
                                </Row>
                            </div>
                        ),
                    },
                ]
            } dataSource={itensNF}
                scroll={{
                    x: 1000,
                    y: 300,
                }} 
                />
        </div>
    )
}