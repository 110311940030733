import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, Select, Typography } from "antd";
import { SelectPaginado } from "../../components";

export default function TabServicoTributacao({ formulario }) {

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-bill.png").default} alt="Dados Tributários" /> Dados Tributários
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina as Tributações do Serviço.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="Tipo de Serviço" name="csv_id" rules={[{ required: true, message: 'Informe o tipo de Serviço LC116' }]}>
                                <SelectPaginado url="ServicoLc116/Listar"
                                    name="csv_id"
                                    form={formulario}
                                    placeholder="Selecione o tipo de Serviço"
                                    allowClear={true}
                                    conteudo={
                                        ser => (<Select.Option value={ser.csv_id} key={ser.csv_id}>{ser.csv_id} - {ser.csv_descricao}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Classificação PIS / COFINS" name="pis_id">
                                <SelectPaginado url="tributacaoPisCofins/Listar"
                                    name="pis_id"
                                    form={formulario}
                                    placeholder="Selecione o Pis/Cofins"
                                    allowClear={true}
                                    conteudo={
                                        pis => (<Select.Option value={pis.pis_id} key={pis.pis_id}>{pis.pis_id} - {pis.pis_descricao}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}