import React, { useEffect, useState } from "react";
import { Card, Col, Descriptions, Divider, Row } from "antd";

export default function TabDetalheTransporte({ transporteNF }) {
    const [transporte, setTransporte] = useState({});

    useEffect(() => {
        if (transporteNF) {
            setTransporte(transporteNF[0]);
        }
    }, [])

    return (
        <Row gutter={[16, 0]} justify="center">
            <Col span={11}>
                <Card bordered={false} title={
                    <Row align="middle" justify="center">
                        <Col><b>Veículo</b></Col>
                    </Row>}>
                    <Row align="middle">
                        <Col>
                            <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} size="small">
                                <Descriptions.Item label="Modelo">
                                    <b>{transporte?.trv_modelo || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Marca">
                                    <b>{transporte?.trv_marca || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Placa">
                                    <b>{transporte?.trv_placa || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="RNTC">
                                    <b>{transporte?.trv_rntc || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="UF">
                                    <b>{transporte?.trv_uf || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Observação">
                                    <b>{transporte?.trv_observacao || 'Não Informado'}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Divider type="vertical" style={{ display: "flex", alignItems: "center", height: "90px" }}/>
            <Col span={11}>
                <Card bordered={false} title={
                    <Row align="middle" justify="center">
                        <Col><b>Volumes</b></Col>
                    </Row>}>
                    <Row align="middle" justify="center">
                        <Col>
                            <Descriptions column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} size="small" style={{ textAlign: 'center' }}>
                                <Descriptions.Item label="Numeração">
                                    <b>{transporte?.nvl_numeracaovolume || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Marca">
                                    <b>{transporte?.nvl_marcavolume || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Especie">
                                    <b>{transporte?.nvl_especievolume || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Quantidade">
                                    <b>{transporte?.nvl_qtdevolumes || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Peso Líquido">
                                    <b>{transporte?.nvl_pesoliquido || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Peso Bruto">
                                    <b>{transporte?.nvl_pesobruto || 'Não Informado'}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}