import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Checkbox, notification, Select } from "antd";
import InputColor from 'react-input-color';

import api from "../../services/api";
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { isObjetoDiffVazio } from "../../services/funcoes";

export default function ManutencaoStatusPedido({ formulario, carregando, aoSalvar }) {

    const [color, setColor] = useState({});
    const [initial, setInitial] = useState('#11a3b3');
    const [{ manutencao }, dispatch] = useStateValue();
    const [situacao, setSituacao] = useState([]);
    const [possuiSituacao, setPossuiSituacao] = useState(false);

    const salvarManutencao = values => {
        let editarIncluir = 'Incluir';
        if (!!formulario.getFieldValue().stp_id) {
            editarIncluir = 'Editar';
            values.stp_id = formulario.getFieldValue().stp_id;
        }
        values.somenteAtivar = false;
        values.stp_statusanterior = null;

        let retorno;
        let enderecoUrl = 'StatusPedido/';

        if (values.stp_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Status pedido salvos com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    aoSalvar();
                    recarregarDados();
                    carregando(false);
                }
            );
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Status pedido incluído com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    aoSalvar();
                    recarregarDados();
                    carregando(false);
                }
            );
        }
    };

    useEffect(() => {
        api.get(`Enum/Listar?nome=SituacaoPedido`).then(res => {
            if (res.status === 200) {
                setSituacao(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, [])

    useEffect(() => {
        if (!!manutencao.dados && isObjetoDiffVazio(manutencao.dados)) {
            setColor({ hex: manutencao.dados.stp_corstatus });
            setInitial(manutencao.dados.stp_corstatus);

            if (manutencao.dados.stp_situacao !== null) {
                setPossuiSituacao(true);
            }
        }
    }, [manutencao.dados]);

    function recarregarDados() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+stp_descricao' } });
    };

    function verificarStatus(record) {
        let status = record;
        api.get(`StatusPedido/Listar`).then(res => {
            if (res.data.items.length > 0) {
                let dadosTemp = res.data.items;
                let jaPossuiSituacao = dadosTemp.some(item => item.stp_situacao === status);
                if (jaPossuiSituacao) {
                    formulario.setFieldsValue({ stp_situacao: undefined });
                    notification.warning({ message: 'Atenção!', description: 'Essa situação já está definida em outro Status de pedido' });
                }
            }
        }).catch((erro) => console.error(erro));
    }

    useEffect(() => {
        if (!!color) {
            formulario.setFieldsValue({ stp_corstatus: color.hex });
        }
    }, [color]);

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoStatusPedido" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={16} md={16} lg={10} xl={9}>
                    <Form.Item label="Informe uma descrição para o Status do Pedido" name="stp_descricao" rules={[{ required: true, message: 'Informe uma descrição para o Status do Pedido' }]}>
                        <Input placeholder="Informe uma descrição para o Status do Pedido" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                    <Form.Item className="colorInp" name="stp_corstatus" label="Cor para o Status" initialValue={initial}>
                        <Input value={color.hex}
                            suffix={
                                <InputColor initialValue={initial} onChange={setColor} />
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={16} lg={10} xl={9}>
                    <Form.Item label="Situação do Pedido" name="stp_situacao">
                        <Select placeholder="Selecione a situação do pedido" showSearch disabled={possuiSituacao} optionFilterProp="children" allowClear={true} onChange={(record) => verificarStatus(record)}>
                            {situacao.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                    <Col xs={24} sm={7} md={5} lg={5} xl={4}>
                        <Form.Item name="stp_permiteemissaonf" valuePropName="checked" initialValue={false}>
                            <Checkbox>Permite emissão da NF-e?</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={5} lg={5} xl={4}>
                        <Form.Item name="stp_permiteeditarpedido" valuePropName="checked" initialValue={false}>
                            <Checkbox>Permite edição do Pedido?</Checkbox>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={8} md={6} lg={5} xl={6}>
                        <Form.Item name="stp_pedidoconcluido" valuePropName="checked" initialValue={false}>
                            <Checkbox>Concluir pedido neste Status?</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={5} xl={6}>
                        <Form.Item name="stp_pedidocancelado" valuePropName="checked" initialValue={false} >
                            <Checkbox>Cancelar pedido neste Status?</Checkbox>
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={4} md={3} lg={3} xl={2}>
                        <Form.Item name="stp_ativo" valuePropName="checked" initialValue={true}>
                            <Checkbox>Status Ativo?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
        </FormGW>
    );

}