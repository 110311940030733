import React, { useState, useEffect } from "react";
import { Col, Divider, Form, Input, Modal, Row, Select, Typography, notification } from 'antd';
import { ExclamationOutlined } from "@ant-design/icons";

import { DrawerCliente, InputCep, InputCpfCnpj, SelectIncluir, SelectPaginacao, SelectPaginado } from '../../components';
import { MaskFormat } from '../../ValueObjects';
import { useStateValue } from '../../state';
import api from '../../services/api';
import { manutencaoActions, selectPaginadoActions } from '../../actions';
import { ModalEndereco } from '../../components/modals';
import { retornaCep } from '../../services/funcoes';

export default function TomadorNFSE({ form, setInfoEndereco, carregarPagina, showDrawerExtra }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formTomador] = Form.useForm();
    const [openDrawerCliente, setOpenDrawerCliente] = useState(false);
    const [openModalEndereco, setOpenModalEndereco] = useState(false);
    const [listaCidade, setListaCidade] = useState(false);
    const [cepInformado, setCepInformado] = useState(false);
    const [codigoCliente, setCodigoCliente] = useState(null);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const [clientes, setClientes] = useState([]);
    const [dadosEndereco, setDadosEndereco] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [email, setEmail] = useState([]);
    const [pessoaFisica, setPessoaFisica] = useState(1);

    useEffect(() => {
        if (!!codigoCliente) {
            api.get(`Cliente/ListarClientesAtivos?filtro=&ordem=%2Bpes_nome`).then(listaCliente => {
                setClientes(listaCliente.data);
            }).finally(() => {
                form.setFieldsValue({ pes_id: codigoCliente });
                let lista = [];
                lista.push({ name: 'pes_id', campo: 'Filtro', value: codigoCliente });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            })
        }
    }, [codigoCliente]);

    useEffect(() => {
        if (!!codigoCliente) {
            buscarDadosCliente(codigoCliente);
        }
    }, [clientes]);

    useEffect(() => {
        if (!!clientes && clientes.length > 0 && (!!manutencao.dados || !!form.getFieldValue().pes_id)) {
            let cliente = !!manutencao.dados ? manutencao.dados.pes_id : form.getFieldValue().pes_id;
            buscarDadosCliente(cliente);
        }
    }, [clientes]);

    useEffect(() => {
        if (!carregarPagina) {
            setTimeout(() => {
                let element = document.getElementById("cep_cep");
                element?.blur();
            }, 100);
        }
    }, [carregarPagina]);

    useEffect(() => {
        if (!!showDrawerExtra && !!form.getFieldValue().pes_id) {
            if (!!form.getFieldValue().pes_id) {
                buscarDadosCliente(form.getFieldValue().pes_id);
            }
        }
    }, [showDrawerExtra])

    async function buscarCep(cep) {
        let retornoCep = await retornaCep(cep);
        if (retornoCep !== null) {
            form.setFieldsValue({ cidade: `${retornoCep.cid_descricao} / ${retornoCep.est_sigla}` });
        } else {
            form.setFieldsValue({ cep_cep: undefined });
        }
    }

    async function verificaCepIncidencia(cep) {
        let retornoCep = await retornaCep(cep);
        if (retornoCep !== null) {
            form.setFieldsValue({ uf_incidencia: retornoCep.est_descricao, mun_incidencia: retornoCep.cid_descricao });
            setCepInformado(true);
        } else {
            form.setFieldsValue({ uf_incidencia: '', mun_incidencia: '', cep_incidencia: '' });
            setCepInformado(false);
        }
    }

    async function buscarDadosCliente(registro) {
        if (!!registro) {
            let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${registro}`)).data;
            if (!!cliente) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: cliente } } } });
                form.setFieldsValue({ 'pej_inscricaoestadual': cliente.pej_inscricaoestadual });
                form.setFieldsValue({ 'pes_nome': cliente.pes_nome });
                form.setFieldsValue({ 'cli_id': cliente.cli_id });
                setarCpfCnpj(cliente);
                setarEnderecos(cliente.enderecos);
                setInfoEndereco(cliente.enderecos[0]);
                setarTelefones(cliente.telefones);
                setarEmails(cliente.emails);
                if (cliente.pes_fisicajuridica === 1) {
                    if (cliente.pef_produtorrural) {
                        form.setFieldsValue({ 'pej_inscricaoestadual': cliente.enderecos[0].pie_inscricaoestadual });
                    }
                }
            } else {
                limparCliente();
            }
        } else {
            limparCliente();
        }
    }

    function limparCliente() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: null } } } });
        form.setFieldsValue({
            'pej_inscricaoestadual': null, 'pes_nome': null, 'ntf_cpfcnpj': null, 'telefoneDestinatario': null, 'ntf_emaildestinatario': null, 'ntf_endereco': null, 'pse_id': null,
            'cep_cep': null, 'cidade': null, 'bai_nome': null, 'pee_numero': null, 'pee_complemento': null
        });
        setarEnderecos([]);
        setarTelefones([]);
        setarEmails([]);
    }

    function setarCpfCnpj(cliente) {
        let tiposPessoa = {
            1: cliente.pef_cpf,
            2: cliente.pej_cnpj,
            3: cliente.pes_idestrangeiro
        };

        let cpfCnpj = tiposPessoa[cliente.pes_fisicajuridica];

        if (cpfCnpj) {
            setPessoaFisica(cliente.pes_fisicajuridica);
            form.setFieldsValue({ ntf_cpfcnpj: cpfCnpj });
        } else {
            notification.warning({ message: 'Atenção', description: "Destinatário/Tomador informado com cadastro de pessoa física/jurídica/estrangeira incorreto!" });
            form.setFieldsValue({ pes_id: null, ntf_cpfcnpj: null });
            return false;
        }
    }

    function setarEnderecos(enderecos) {
        if (enderecos?.length) {
            setDadosEndereco(enderecos);
            const { cep_cep, pee_id, pse_id, bai_nome, pee_numero, pee_complemento } = enderecos[0];
            buscarCep(cep_cep);

            form.setFieldsValue({
                ntf_endereco: pee_id,
                cep_cep,
                pse_id,
                bai_nome,
                pee_numero,
                pee_complemento
            });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: [{ name: "pse_id", campo: "pse_id", value: pse_id }] } });
        } else {
            setDadosEndereco([]);
            if (form.getFieldValue().pes_id) {
                Modal.confirm({
                    title: 'Endereço não encontrado',
                    icon: <ExclamationOutlined />,
                    content: 'Deseja incluir um endereço para este destinatário/tomador?',
                    okText: 'Sim',
                    cancelText: 'Não',
                    centered: true,
                    onOk() {
                        setOpenModalEndereco(true);
                    }
                });
            }
        }
    }

    function setarTelefones(telefones) {
        setTelefone(telefones);
        if (telefones?.length) {
            const telefone = telefones.find(f => f.psc_principal) ?? telefones[0];
            form.setFieldsValue({ telefoneDestinatario: telefone.psc_id });
        }
    }

    function setarEmails(emails) {
        setEmail(emails);
        if (emails?.length) {
            const email = emails.find(f => f.pem_emailprincipal) ?? emails[0];
            form.setFieldsValue({ ntf_emaildestinatario: email.pem_id });
        }
    }

    function buscarDadosEndereco(pee_id) {
        let end = dadosEndereco.filter((end) => (end.pee_id === pee_id))[0];
        if (!!end) {
            form.setFieldsValue({ pse_id: end.pse_id });
            if (end.pse_id) {
                let lista = [];
                lista.push({ name: "pse_id", campo: "pse_id", value: end.pse_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            if (end.pie_inscricaoestadual) {
                form.setFieldsValue({ 'pej_inscricaoestadual': end.pie_inscricaoestadual });
            }
            form.setFieldsValue({ cep_cep: end.cep_cep });
            form.setFieldsValue({ bai_nome: end.bai_nome });
            form.setFieldsValue({ pee_numero: end.pee_numero });
            form.setFieldsValue({ pee_complemento: end.pee_complemento });
            buscarCep(end.cep_cep);
        }
    }

    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidade(true);
            setFiltroCdd(`&UfEstado=${UfEstado}`);
        } else {
            setListaCidade(false);
        }
    }

    return (
        <div className="m-t-16">
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={12} lg={7} xl={9}>
                    <Form.Item name="pes_id" label="Destinatário/Tomador Serviço">
                        <SelectPaginacao url="Cliente/Listar"
                            placeholder="Selecione um Destinatário/Tomador"
                            form={form}
                            nameLabel="pes_nome"
                            funcaoIncluir={() => setOpenDrawerCliente(true)}
                            nameValue="pes_id"
                            allowClear={true}
                            onChangeFunction={(dados) => { buscarDadosCliente(dados) }}
                            conteudo={
                                pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                    {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                </Select.Option>)
                            } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={4} xl={3}>
                    {pessoaFisica === 1 &&
                        <InputCpfCnpj cpf label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }
                    {pessoaFisica === 2 &&
                        <InputCpfCnpj label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }
                    {pessoaFisica === 3 &&
                        <InputCpfCnpj mask='00.000.000-0' label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }
                </Col>
                <Col xs={24} sm={6} md={6} lg={4} xl={3}>
                    <Form.Item label="Inscrição Estadual" name="pej_inscricaoestadual">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={4} lg={3} xl={3}>
                    <SelectIncluir dadosSelect={telefone}
                        campoDescricao={"psc_numero"}
                        campoValue={"psc_id"}
                        campoKey={"psc_id"}
                        placeHolder={"Selecione o Telefone"}
                        labelFormItem={"Telefone"}
                        nameFormItem={"telefoneDestinatario"} />
                </Col>
                <Col xs={24} sm={16} md={8} lg={6} xl={6}>
                    <Form.Item label="E-mail" name="ntf_emaildestinatario">
                        <Select disabled={email.length === 0} placeholder="Selecione o E-mail" >
                            {email.map((mail) => (
                                <Select.Option value={mail.pem_id} key={mail.pem_id}>{mail.pem_email}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                    </Typography.Title>
                    <Divider />
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-8">
                <Col xs={24} sm={16} md={8} lg={8} xl={8}>
                    <Form.Item label="Endereço" name="ntf_endereco" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                        <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value)}>
                            {dadosEndereco.map(
                                (endereco) => (
                                    <Select.Option value={endereco.pee_id} key={endereco.pee_id}>
                                        {endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                    <Form.Item label="País" name="pse_id" rules={[{ required: true, message: 'Informe o País' }]}>
                        <SelectPaginacao url="Pais/Listar" placeholder="Informe o País" form={form} nameValue="pse_id" nameLabel={"pse_nome"} conteudo={
                            pse => (<Select.Option value={pse.pse_id} key={pse.key}>{pse.pse_nome}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                    <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={event => { buscarCep(event.currentTarget.value) }} />
                </Col>
                <Col xs={24} sm={7} md={3} lg={3} xl={3}>
                    <Form.Item className="text-center t-mob-573">
                        <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                            Não sei o CEP
                        </Typography.Link>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={9} md={6} lg={6} xl={6}>
                    {<Form.Item label="Cidade" name="cidade">
                        <Input disabled={true}></Input>
                    </Form.Item>}
                </Col>
                <Col xs={24} sm={6} md={6} lg={5} xl={5}>
                    <Form.Item label="Bairro" name="bai_nome"  rules={[{ required: true, message: 'Informe o Bairro' }]}>
                        <Input placeholder="Informe o Bairro" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={4} md={4} lg={2} xl={2}>
                    <Form.Item label="Número" name="pee_numero" rules={[{ required: true, message: 'Informe o Nº Endereço' }]}>
                        <Input placeholder="Informe o Número" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={14} md={5} lg={8} xl={8}>
                    <Form.Item label="Complemento" name="pee_complemento">
                        <Input placeholder="Informe o Complemento" maxLength={60} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-city.png").default} alt="Endereço" /> Cidade da Prestação do Serviço
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Informe o CEP ou selecione a UF e o Município 
                    </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-8">
                <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                    <InputCep label="CEP Incidência" name="cep_incidencia" onBlur={(cep) => verificaCepIncidencia(cep.currentTarget.value)} />
                </Col>
                <Col className="text-center m-t-19">
                    <b>ou</b>
                </Col>
                <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                    <Form.Item label="UF de Incidência" name="uf_incidencia">
                        <SelectPaginacao url="Estado/Listar" 
                            placeholder="Selecione um Estado" 
                            disabled={cepInformado} 
                            allowClear={true} 
                            form={form} 
                            nameLabel="est_sigla" 
                            nameValue="uf_incidencia" 
                            selecionarRegUnico="est_sigla" 
                            onClearFunction={(UfEstado) => carregarCidades(UfEstado)} 
                            onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                            conteudo={
                                est => (
                                    <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                            } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={18} md={8} lg={8} xl={6}>
                    <Form.Item label="Município de Incidência" name="mun_incidencia" disabled={listaCidade == true}>
                        <SelectPaginacao url="Cidade/Listar" 
                            allowClear 
                            placeholder="Selecione a Cidade" 
                            nameLabel="cid_descricao" 
                            nameValue="mun_incidencia" 
                            form={form} 
                            filtroExtra={filtroCdd} 
                            selecionarRegUnico="cid_id"
                            disabled={cepInformado}
                            conteudo={
                                cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                            } />
                    </Form.Item>
                </Col>
            </Row>
            <DrawerCliente form={formTomador} openDrawer={openDrawerCliente} setOpenDrawer={setOpenDrawerCliente} setCodigoCliente={setCodigoCliente} />
            <ModalEndereco form={formTomador} listaEndereco={{ dadosEndereco, setDadosEndereco }} exibirModalEndereco={openModalEndereco} fecharModalEndereco={() => setOpenModalEndereco(false)} />
        </div>
    )
}