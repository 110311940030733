import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography, InputNumber, Form, notification, Modal, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormasPagamento, InputPreco } from "../../components";
import { calculaValorProporcional, isObjetoDiffVazio, validarNumeros } from "../../services/funcoes";
import { FormatNumber } from "../../ValueObjects";
import { CalcularImpostoItem } from "../../services/calculaImposto";

export default function FaturamentoNFSE({ form, dadosItens, dadosVendedor, operacaoFiscal, resume }) {
    const [{ manutencao }] = useStateValue();
    const [valorNota, setValorNota] = useState(0);
    const [valorTotalIssqn, setValorTotalIssqn] = useState(0);
    const [listaFormaPagamento, setListaFormaPagamento] = useState(!!manutencao.dados && !!manutencao.dados.faturamento && manutencao.dados.faturamento.length > 0 ? manutencao.dados.faturamento : []);
    const [controlModal, setControlModal] = useState(false);

    useEffect(() => {
        resume(false);
        recalcularTotal();
    }, [valorNota])

    useEffect(() => {
        rateios();
        calcularFaturamento();
    }, [manutencao.dados])

    function calcularFaturamento() {
        let formulario = form.getFieldValue();
        let subTotal = 0;
        dadosItens.forEach((item) => {
            subTotal += item.total;
        });
        form.setFieldsValue({ subTotalNotaFiscal: subTotal });
        setValorTotalIssqn(formulario.valorTotalIssqn);
        setValorNota(parseFloat(subTotal) + parseFloat(formulario.valorOutrasDespesas));
    }

    function calcularDesconto(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorDesconto);
        let perc = validarNumeros(formulario.percentualDesconto);
        let tot = parseFloat(valorNota);
        let result = 0;

        if (listaFormaPagamento.length > 0 && !!!controlModal) {
            setControlModal(true);
            Modal.confirm({
                title: 'Aviso',
                icon: <ExclamationCircleOutlined />,
                content: 'Tem certeza que deseja adicionar desconto? As formas de pagamento terão que ser incluídas novamente.',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    setListaFormaPagamento([]);
                },
                onCancel() {
                    form.setFieldsValue({ valorDesconto: 0, percentualDesconto: 0, valorTotal: listaFormaPagamento[0].pfp_valor });
                }
            })
        }

        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorDesconto: result.toFixed(2) });
            } else {
                result = (valor * 1001) / tot;
                form.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            }
        }
        recalcularTotal();
    }

    function calcularAcrescimo(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorAcrescimo);
        let perc = validarNumeros(formulario.percentualAcrescimo);
        let tot = (!!valorNota ? parseFloat(valorNota) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            }
        }
        recalcularTotal();
    }

    function recalcularTotal() {
        let formulario = form.getFieldValue();
        let valor = 0;
        if (!!formulario) {
            valor = valorNota - validarNumeros(formulario.valorDesconto) + validarNumeros(formulario.valorAcrescimo);
            form.setFieldsValue({ valorTotal: valor.toFixed(2) });
            let valorPago = 0;
            if (!!manutencao.dados && !!manutencao.dados.faturamento) {
                manutencao.dados.faturamento.forEach((dadosFat) => {
                    valorPago += parseFloat(dadosFat.pfp_valor);
                    if (listaFormaPagamento.length == 0 || form.getFieldValue().valorDesconto > 0);
                    {
                        valorPago = 0;
                    }
                })
            }
            form.setFieldsValue({ pfp_valor: (valor - valorPago).toFixed(2) });
        }
    }

    function vendedorDescMaxFinal() {
        let totalDesconto = 0;
        let totalValorDescontoItens = 0;
        dadosItens.forEach((itens) => {
            totalValorDescontoItens += parseFloat(itens.nfi_valordesconto);
        });
        let percentualDescontoConcedido = parseFloat(((totalValorDescontoItens * 100) / parseFloat(form.getFieldsValue().subTotalNotaFiscal)).toFixed(2));
        totalDesconto = percentualDescontoConcedido + parseFloat(form.getFieldValue().percentualDesconto);
        if (!!dadosVendedor && JSON.stringify(dadosVendedor) !== '{}' && !!dadosVendedor.ven_maximodesconto) {
            if (totalDesconto > (dadosVendedor.ven_maximodesconto * 100)) {
                notification.warning({ description: `O desconto máximo para este vendedor não pode exceder ${dadosVendedor.ven_maximodesconto * 100}% !`, message: 'Aviso' });
                form.setFieldsValue({ percentualDesconto: (dadosVendedor.ven_maximodesconto * 100) - parseFloat(percentualDescontoConcedido) });
                calcularDesconto('PERC');
            }
        }
    }

    async function rateios() {
        await rateioDesconto();
        await recalcularImpostos();
    }

    async function rateioDesconto() {
        let formulario = form.getFieldsValue();
        let _numeroItem = 1;
        let _totalDesconto = 0;
        if (dadosItens.length > 0) {
            let _valorDesconto = parseFloat(formulario.valorDesconto);
            if (_valorDesconto > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.nfi_valorunitario * item.nfi_qtde;
                    return total + resultadoMultiplicacao;
                }, 0);

                itens.forEach(item => {
                    let valorTotalItem = parseFloat(item.nfi_valorunitario * item.nfi_qtde);
                    item.nfi_valordescontorateado = calculaValorProporcional(valorTotalItem, _valorDesconto, somaTotal);
                    if (_numeroItem == dadosItens.length) {
                        item.nfi_valordescontorateado = parseFloat((_valorDesconto - _totalDesconto).toFixed(2))
                    }
                    _totalDesconto += item.nfi_valordescontorateado;
                    _numeroItem++;
                });
            }
        }
    }

    async function recalcularImpostos() {
        if (dadosItens.length > 0) {
            let total = 0;
            let totalIssqn = 0;
            let possuiIe = !!form.getFieldValue().pej_inscricaoestadual ? true : false;
            let fisicaJuridica = form.getFieldValue().ntf_cpfcnpj.length === 14 ? 2 : form.getFieldValue().ntf_cpfcnpj.length === 11 ? 1 : 3;
            for (const p of dadosItens) {
                try {
                    let impostos = await CalcularImpostoItem("0", null, null, null, null, p.nfi_qtde, p.nfi_valorunitario, null, null, p.nfi_valordesconto, null, null, null,
                        null, null, null, null, null, fisicaJuridica, 0, possuiIe, form.getFieldValue().ntf_indicadorpresenca, false, false, true, p.aliquotaIssqn || 0);

                    if (isObjetoDiffVazio(impostos)) {
                        p.total = (p.nfi_qtde * p.nfi_valorunitario) - p.nfi_valordesconto + p.nfi_valoroutros;
                        p.impostos = impostos;
                    }
                    totalIssqn += parseFloat(impostos.retornoISSQN.ret_valorIssqn);
                    total += parseFloat(p.total);
                } catch (error) {
                    console.error("Error item:", error);
                }
            }
            form.setFieldsValue({ valorTotalIssqn: totalIssqn });
            calcularFaturamento();
        }
    }

    return (
        <div className="m-t-16 faturar">
            <Row align="middle" justify="center" gutter={[8, 24]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Totais da Nota Fiscal
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]} justify="space-between">
                            <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                                <InputPreco disabled label="Subtotal (R$)" name="subTotalNotaFiscal" className="text-right" />
                            </Col>
                            <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                                <InputPreco disabled label="Outras Despesas (R$)" name="valorOutrasDespesas" className="text-right" />
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Desconto" name="percentualDesconto">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onBlur={() => { vendedorDescMaxFinal(); rateios(); }}
                                        onChange={() => { calcularDesconto('PERC'); }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center m-t-19">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Desconto (R$)" name="valorDesconto" onBlur={() => { vendedorDescMaxFinal(); rateios(); }} onChange={() => { calcularDesconto('VALOR') }} />
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Acréscimo" name="percentualAcrescimo">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={() => { calcularAcrescimo('PERC') }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center m-t-19">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Acréscimo (R$)" name="valorAcrescimo" onChange={() => { calcularAcrescimo('VALOR') }} />
                            </Col>
                        </Row>
                        <Row justify="end" gutter={[0, 16]}>
                            <Col>
                                Valor Issqn (R$): <b>{FormatNumber(valorTotalIssqn, true)}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={4} className="m-0">
                                    Total da Nota (R$)
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end" className="inpDis">
                            <InputPreco className="f-18" name="valorTotal" disabled />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <FormasPagamento form={form} dadosOperacao={operacaoFiscal} listaFormaPagamento={listaFormaPagamento} setListaFormaPagamento={setListaFormaPagamento} />
        </div>
    )
}