import React, { useState } from "react";
import { Button, Col, Drawer, Form, Modal, Row } from "antd";
import { useStateValue } from "../../state";
import { CloseOutlined, ExclamationCircleOutlined, LeftOutlined, MinusOutlined, RightOutlined, SaveOutlined } from "@ant-design/icons";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import ManutencaoConhecimentoTransporte from "./manutencao";

export default function DrawerConhecimentoTransporte({ }) {
    const [formConhecimento] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [proximaPag, setProximaPag] = useState(0);

    const onCloseDrawer = () => {
        Modal.confirm({
            title: "Cancelar?",
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        })
    }

    function fecharDrawer() {
        formConhecimento.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        setCarregando(false);
    }

    return (
        <Drawer title="Emissão de Conhecimento de Transporte Eletrônico"
            width="70%"
            visible={!!ui.showDrawer}
            closeIcon={<MinusOutlined />}
            onClose={() => onCloseDrawer()}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onCloseDrawer} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={tagPages === 3} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formConhecimento.submit()} hidden={tagPages !== 3} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Emitir CTe
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        >
            <ManutencaoConhecimentoTransporte formConhecimento={formConhecimento} carregando={setCarregando} aoSalvar={fecharDrawer} pages={{ tagPages, setTagPages}} proximaPag={{ proximaPag, setProximaPag }} />
        </Drawer>
    )
}