import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../state";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { CloseOutlined, ExclamationCircleOutlined, LeftOutlined, MinusOutlined, RightOutlined, SaveOutlined } from "@ant-design/icons";
import { DrawerNav } from "../../components";
import NFServico from "../notaFiscalServico/manutencao";

export default function DrawerNFSE({ showDrawerExtra, setShowDrawerExtra, dadosNFSE }) {
    const [formServico] = Form.useForm();
    const [formulario] = Form.useForm();
    const [{ ui, manutencao}, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [resume, setResume] = useState(false);
    const [proximaPag, setProximaPag] = useState(0);

    const ActionsController = () => {
        setProximaPag(tagPages - 1);
        setResume(false);
    }

    useEffect(() => {
        if (!!dadosNFSE) {
            formulario.setFieldsValue(dadosNFSE);
        }
    }, [dadosNFSE])

    function fecharDrawer() {
        formServico.resetFields();
        setTagPages(0);
        setProximaPag(null);
        setResume(false);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bped_datainclusao' } });
        setShowDrawerExtra(false);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        })
    }

    return (
        <DrawerNav title="Nota Fiscal de Serviço"
            width="70%"
            visible={showDrawerExtra}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => ActionsController()} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={resume} size="large" onClick={() => { setProximaPag(tagPages + 1) }}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} hidden={!resume} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Emitir Nota
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
                <NFServico form={formulario} aoSalvar={fecharDrawer} carregando={setCarregando} pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }} 
                    showDrawerExtra={showDrawerExtra} resume={setResume} />
        </DrawerNav>
    )
}