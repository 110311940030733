import React, { useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { InfoNotaNFe } from "../../../components";
import { novaAbaNavegador } from "../../../services/funcoes";

export default function ModalEstornoNfe({ dadosNota, exibirModal, fecharModal }) {
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formulario.resetFields();
                fecharModal();
            }
        });
    };

    function salvar(valores) {
        let dados = {};
        dados.ntf_id = dadosNota.ntf_id;
        dados.textoEstorno = formulario.getFieldValue().justificativa;
        setCarregando(true);
        api.post(`NotaFiscal/IncluirEstorno`, dados).then(
            res => {
                if (res.data.ntf_status === 1) {
                    notification.success({ description: `NF-e Nº: ${dadosNota.ntf_numero} estorna com sucesso!`, message: 'Aviso' });
                    if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && res.data.ntf_docfiscal == true) {
                        novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                    }
                } else if (res.data.ntf_status != 1) {
                    notification.success({ description: res.data.ntf_cstat + res.data.ntf_xmotivo, message: 'Aviso' });
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data) {
                    notification.error({ description: erro.response.data, message: `Aviso` });
                } else {
                    notification.warning({ description: 'Ocorreu um erro ao tentar estornar a nota fiscal.', message: 'Aviso' });
                }
                setCarregando(false);
            }
        ).finally(
            () => {
                fecharModal();
                setCarregando(false);
            }
        )
    };

    return (
        <Modal centered
            title="Estorno de NF-e"
            visible={exibirModal}
            confirmLoading={carregando}
            onCancel={onCloseModal}
            okText={
                <>
                    <CheckOutlined /> Realizar o Estorno
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            onOk={salvar}
            destroyOnClose
        >
            <div>
                <Form layout="vertical" form={formulario} name="formEstornoNfe">
                    <Row justify="center">
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item>
                                    ATENÇÃO: <br />
                                    O prazo legal para o Cancelamento de um Nota Fiscal Eletrônica é de 168 horas. <br />
                                    A Nota Fiscal selecionada ultrapassou este prazo, de modo que, apenas é permitido seu Estorno.
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <InfoNotaNFe dadosNota={dadosNota} />
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Motivo do Estorno" name="justificativa">
                                <Input.TextArea placeholder="Informe aqui o motivo do Estorno da Nf-e" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}