import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Dropdown, Menu, notification, Modal } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, CloseOutlined, ExclamationCircleOutlined, PrinterOutlined, MoreOutlined, EyeOutlined, CopyOutlined, DollarOutlined, FileDoneOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from '../../state';
import ModalDetalhePedido from "./modalDetalhePedido";
import { imprimirRelatorioJasper } from "../../services/funcoes";
import { FormatNumber, MaskFormat, FormatFone } from "../../ValueObjects";
import { DrawerNF, DrawerPedidos, DrawerTransferencia } from './drawerPage';
import { listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacaoData, TabelaDados, Imprimir, Data, PesquisaAvancada, BreadcrumbPage } from "../../components";
import ModalSelecionarNF from "./modalSelecionarNF";
import DrawerNFSE from "./drawerNFSE";

export default function Pedidos() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [openModalDetalhePedido, setOpenModalDetalhePedido] = useState(false);
    const [dadosDetalhesPedido, setDadosDetalhesPedido] = useState({});
    const [formPedidos] = Form.useForm();
    const [idPedido, setIdPedido] = useState(0);
    const [status, setStatus] = useState([]);
    const [showDrawerNF, setShowDrawerNF] = useState(false);
    const [showDrawerNFSE, setShowDrawerNFSE] = useState(false);
    const [openModalEscolha, setOpenModalEscolha] = useState(false);
    const [drawerTransferencia, setDrawerTransferencia] = useState(false);
    const [dadosPedido, setDadosPedido] = useState({});
    const [dadosNFSE, setDadosNFSE] = useState({});
    const [dadosNF, setDadosNF] = useState({});
    const [exibirDrawerPedidos, setExibirDrawerPedidos] = useState(false);

    useEffect(() => {
        carregarStatus()
        formPedidos.setFieldsValue({ ativoPedido: ["1"] });
    }, []);

    function carregarPrimeiraVez() {
        let ativo = 2;//Toodos
        if (formPedidos.getFieldValue().ativoPedido.length == 1) {
            if (formPedidos.getFieldValue().ativoPedido.find(e => e === "0"))
                ativo = 0;
            if (formPedidos.getFieldValue().ativoPedido.find(e => e === "1"))
                ativo = 1;
        }
        let filtro =  `&Ativo=${ativo}`;
        let status = !!formPedidos.getFieldValue().status ? formPedidos.getFieldValue().status : '';
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao', outrosFiltros: `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${moment(formPedidos.getFieldValue().dataPesquisa).format("YYYY-MM-DD")}&filtro=&status=${status}` + filtro } })
        formPedidos.setFieldsValue({ dataFinal: moment(new Date) });
        formPedidos.setFieldsValue({ dataInicial: moment(formPedidos.getFieldValue().dataPesquisa) });
    };

    function carregarStatus() {
        let key = 0;
        api.get(`StatusPedido/Listar`).then(
            res => {
                let listaStatus = []
                res.data.items.forEach(item => {
                    item.key = key++;
                    listaStatus.push(item.stp_id);
                });
                setStatus(res.data.items);
                formPedidos.setFieldsValue({ status: listaStatus });
                carregarPrimeiraVez();
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function editarPedido(record) {
        record.ped_datainclusao = moment(record.ped_datainclusao);
        record.ped_dataprevisaoentrega = moment(record.ped_dataprevisaoentrega);
        record.ped_datavalidade = moment(record.ped_datavalidade);
        setExibirDrawerPedidos(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
    };

    const modalConfirm = (record) => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Tem certeza que deseja cancelar esse pedido?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                cancelarPedido(record);
            }
        });
    }

    function cancelarPedido(record) {
        api.post(`Pedido/CancelarPedido?IdPedido=${record.ped_id}`).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Pedido cancelado com sucesso!" });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao' } });
            }
        );
    };

    //#region faturar pedidos
    async function emitirNf(record) {
        let itensPedido = await api.get(`PedidoItem/ListarItensPedido?codigoPedido=${record.ped_id}`);
        record.itensNf = itensPedido.data;

        record.ped_datavalidade = moment(record.ped_datavalidade);
        record.ped_dataprevisaoentrega = moment(record.ped_dataprevisaoentrega);
        record.ped_datainclusao = moment(record.ped_datainclusao);
        record.ntf_modalidadefrete = record.ped_modalidadefrete;
        record.itensNf.forEach((item) => {
            item.nfi_valorunitario = item.pdi_valorunitario;
            item.nfi_qtde = item.pdi_quantidade;
            item.nfi_valordesconto = item.pdi_valordesconto;
            item.nfi_valoroutros = item.pdi_valoracrescimo;
            item.nfi_ean = !!item.prg_ean ? item.prg_ean : item.pro_ean;
            item.total = (item.pdi_valorunitario * item.pdi_quantidade) - item.pdi_valordesconto + item.pdi_valoracrescimo;
            item.percdesc = !!item.nfi_valordesconto ? (item.nfi_valordesconto * 100) / item.total : 0;
            item.percacresc = !!item.nfi_valoroutros ? (item.nfi_valoroutros * 100) / item.total : 0;
            item.nfi_numeropedidocompra = record.ped_numero;
        });
        record.faturamento = record.formaPgto;

        tratarDevolvidoFaturado(record);
        setDadosPedido(record);

        let temServico = record.itensNf.some(item => item.pro_servico === true);
        let soServico = record.itensNf.every(item => item.pro_servico === true);

        if (temServico && !soServico) {
            setOpenModalEscolha(true);
        } else if (soServico) {
            tratarFaturamentoNFSe(record);
            setDadosNFSE(record);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
            setShowDrawerNFSE(true);
        } else {
            tratarFaturamentoNFe(record);
            setDadosNF(record);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
            setShowDrawerNF(true);
        }
    };

    function tratarDevolvidoFaturado(dados) {
        if (!dados || !dados.itensNf) return dados;

        dados.itensNf = dados.itensNf.map(item => {
            let result = item.pdi_quantidade - item.pdi_qtdedevolvido - item.pdi_qtdeentregue;
            if (result === 0 ) {
                return null;
            } else if (result > 0) {
                item.pdi_quantidade = result;
                return item;
            }
        }).filter(item => item !== null);

        return dados;
    }

    function tratarFaturamentoNFSe(record) {
        if (record.formaPgto.length > 1) { //CONDIÇÃO PARA QUANDO SE EXISTE MAIS DE UMA FORMA DE PAGAMENTO
            let valorTotal = record.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0); //VALOR TOTAL INICIAL
            let porcentagens = record.formaPgto.map(fp => (fp.pfp_valor || 0) / valorTotal); //CALCULA A PORCENTAGEM DE CADA FORMA

            let somaValorPagos = 0; //CALCULA OS NOVOS VALORES DAS FORMAS DE PAGAMENTO
            for (let i = 0; i < record.formaPgto.length - 1; i++) {
                let novoValor = parseFloat(((porcentagens[i] || 0) * valorTotal).toFixed(2));
                record.formaPgto[i].pfp_valor = novoValor;
                somaValorPagos += novoValor;
            }

            let novoValorUltimaForma = parseFloat((valorTotal - somaValorPagos).toFixed(2)); //DEFINE O VALOR DA ULTIMA FORMA COM O RESTANTE
            record.formaPgto[record.formaPgto.length - 1].pfp_valor = novoValorUltimaForma;
            record.faturamento[record.faturamento.length - 1].pfp_valor = novoValorUltimaForma;
        } else {
            let valorTotal = record.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);
            record.formaPgto[0].pfp_valor = valorTotal;
            record.faturamento[0].pfp_valor = valorTotal;
        }

        return record;
    }

    function tratarFaturamentoNFe(record) {
        if (record.formaPgto.length > 1) {
            let valorTotal = record.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0); 
            let porcentagens = record.formaPgto.map(fp => (fp.pfp_valor || 0) / valorTotal);

            let somaValorPagos = 0;
            for (let i = 0; i < record.formaPgto.length - 1; i++) {
                let novoValor = parseFloat(((porcentagens[i] || 0) * valorTotal).toFixed(2));
                record.formaPgto[i].pfp_valor = novoValor;
                record.faturamento[i].pfp_valor = novoValor;
                somaValorPagos += novoValor;
            }

            let novoValorUltimaForma = parseFloat((valorTotal - somaValorPagos).toFixed(2));
            record.formaPgto[record.formaPgto.length - 1].pfp_valor = novoValorUltimaForma;
            record.faturamento[record.faturamento.length - 1].pfp_valor = novoValorUltimaForma;
        } else {
            let valorTotal = record.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);
            record.formaPgto[0].pfp_valor = valorTotal;
            record.faturamento[0].pfp_valor = valorTotal;
        }

        return record;
    }

    function nfseEscolhida() {
        //FUNÇÃO EXECUTADA QUANDO NF DE SERVIÇO É ESCOLHIDA
        let dadosTemp = {}
        if (dadosPedido.formaPgto.length > 1) { //CONDIÇÃO PARA QUANDO SE EXISTE MAIS DE UMA FORMA DE PAGAMENTO
            let valorTotal = dadosPedido.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0); //VALOR TOTAL INICIAL
            let porcentagens = dadosPedido.formaPgto.map(fp => (fp.pfp_valor || 0) / valorTotal); //CALCULA A PORCENTAGEM DE CADA FORMA

            dadosTemp = { 
                ...dadosPedido,
                itensNf: dadosPedido.itensNf.filter(item => item.pro_servico === true) //FILTRA APENAS OS SERVIÇOS
            };

            let novoValorTotal = dadosTemp.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);

            let somaValorPagos = 0; //CALCULA OS NOVOS VALORES DAS FORMAS DE PAGAMENTO
            for (let i = 0; i < dadosTemp.formaPgto.length - 1; i++) {
                let novoValor = parseFloat(((porcentagens[i] || 0) * novoValorTotal).toFixed(2));
                dadosTemp.formaPgto[i].pfp_valor = novoValor;
                somaValorPagos += novoValor;
            }

            let novoValorUltimaForma = parseFloat((novoValorTotal - somaValorPagos).toFixed(2)); //DEFINE O VALOR DA ULTIMA FORMA COM O RESTANTE
            dadosTemp.formaPgto[dadosTemp.formaPgto.length - 1].pfp_valor = novoValorUltimaForma;
            dadosTemp.faturamento[dadosTemp.faturamento.length - 1].pfp_valor = novoValorUltimaForma;
        } else {
            dadosTemp = { 
                ...dadosPedido,
                itensNf: dadosPedido.itensNf.filter(item => item.pro_servico === true)
            };
            let novoValorTotal = dadosTemp.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);
            dadosTemp.formaPgto[0].pfp_valor = novoValorTotal;
            dadosTemp.faturamento[0].pfp_valor = novoValorTotal;
        }

        setDadosNFSE(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
        setShowDrawerNFSE(true);
        setOpenModalEscolha(false);
    }

    function nfEscolhida() {
        //FUNÇÃO EXECUTADA QUANDO NF É ESCOLHIDA
        let dadosTemp = {}
        if (dadosPedido.formaPgto.length > 1) {
            let valorTotal = dadosPedido.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0); 
            let porcentagens = dadosPedido.formaPgto.map(fp => (fp.pfp_valor || 0) / valorTotal);

            dadosTemp = { 
                ...dadosPedido,
                itensNf: dadosPedido.itensNf.filter(item => item.pro_produto === true)
            };

            let novoValorTotal = dadosTemp.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);

            let somaValorPagos = 0;
            for (let i = 0; i < dadosTemp.formaPgto.length - 1; i++) {
                let novoValor = parseFloat(((porcentagens[i] || 0) * novoValorTotal).toFixed(2));
                dadosTemp.formaPgto[i].pfp_valor = novoValor;
                dadosTemp.faturamento[i].pfp_valor = novoValor;
                somaValorPagos += novoValor;
            }

            let novoValorUltimaForma = parseFloat((novoValorTotal - somaValorPagos).toFixed(2));
            dadosTemp.formaPgto[dadosTemp.formaPgto.length - 1].pfp_valor = novoValorUltimaForma;
            dadosTemp.faturamento[dadosTemp.faturamento.length - 1].pfp_valor = novoValorUltimaForma;
        } else {
            dadosTemp = { 
                ...dadosPedido,
                itensNf: dadosPedido.itensNf.filter(item => item.pro_produto === true)
            };
            let novoValorTotal = dadosTemp.itensNf.reduce((total, item) => total + (item.pdi_valortotal || 0), 0);
            dadosTemp.formaPgto[0].pfp_valor = novoValorTotal;
            dadosTemp.faturamento[0].pfp_valor = novoValorTotal;
        }

        setDadosNF(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
        setShowDrawerNF(true);
        setOpenModalEscolha(false);
    }
    //#endregion

    async function emitirTransferencia(record) {
        let itensPedido = await api.get(`PedidoItem/ListarItensPedido?codigoPedido=${record.ped_id}`);
        record.itensNf = itensPedido.data;
        record.loc_id = record.loc_id_transferencia;
        record.custo = record.ped_precoutilizado;
        record.ped_datavalidade = moment(record.ped_datavalidade);
        record.ped_dataprevisaoentrega = moment(record.ped_dataprevisaoentrega);
        record.ped_datainclusao = moment(record.ped_datainclusao);
        record.ntf_modalidadefrete = record.ped_modalidadefrete;
        record.itensNf.forEach((item) => {
            item.nfi_valorunitario = item.pdi_valorunitario;
            item.nfi_qtde = item.pdi_quantidade;
            item.nfi_valordesconto = item.pdi_valordesconto;
            item.nfi_valoroutros = item.pdi_valoracrescimo;
            item.nfi_ean = item.pro_ean;
            item.total = item.pdi_valorunitario * item.pdi_quantidade;
            item.percdesc = !!item.nfi_valordesconto ? (item.nfi_valordesconto * 100) / item.total : 0;
            item.percacresc = !!item.nfi_valoroutros ? (item.nfi_valoroutros * 100) / item.total : 0;
            item.nfi_numeropedidocompra = record.ped_numero;
        });
        record.faturamento = record.formaPgto;
        setDadosPedido(record);
        setDrawerTransferencia(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
    };

    async function visualizarPedido(record) {
        let itensPedido = await api.get(`PedidoItem/ListarItensPedido?codigoPedido=${record.ped_id}`);
        record.itensNf = itensPedido.data;
        setDadosDetalhesPedido(record);
        setOpenModalDetalhePedido(true);
    };

    function imprimirPedido(record) {
        imprimirRelatorioJasper(29, { idPedido: record.ped_id });
    };

    function abrirDrawerPedido() {
        setExibirDrawerPedidos(true)
    }
    
    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CopyOutlined />} onClick={() => duplicarPedido(record)}> Duplicar </Button>
                </Menu.Item>
                {(record.ped_ativo === true && record.stp_pedidoconcluido === false && record.stp_permiteemissaonf === true && !!!record.loc_id_transferencia) &&
                    <Menu.Item key="2">
                        <Button type="text" icon={<DollarOutlined />} onClick={() => emitirNf(record)}>Faturar </Button>
                    </Menu.Item>
                }
                  {(record.ped_ativo === true && record.stp_permiteeditarpedido === true) &&
                    <Menu.Item key="3">
                        <Button type="text" icon={<EditOutlined />} onClick={() => editarPedido(record) }> Editar </Button>
                    </Menu.Item>
                }
                {(record.ped_ativo === true && record.stp_pedidoconcluido === false && record.stp_permiteemissaonf === true && !!record.loc_id_transferencia) &&
                    <Menu.Item key="4">
                        <Button type="text" icon={<FileDoneOutlined />} onClick={() => emitirTransferencia(record)}> Emitir Transferência </Button>
                    </Menu.Item>
                }
                <Menu.Item key="5">
                    <Button hidden={record.ped_ativo === false} type="text" icon={<CloseOutlined />} onClick={() => modalConfirm(record)}> Cancelar </Button>
                </Menu.Item>
                <Menu.Item key="6">
                    <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => imprimirPedido(record)}> Imprimir </Imprimir>
                </Menu.Item>
            </Menu>
        )
    };

    async function duplicarPedido(record) {
        let pedidoDuplicar = { ...record }
        pedidoDuplicar.ped_datainclusao = moment(new Date());
        pedidoDuplicar.ped_dataprevisaoentrega = moment(pedidoDuplicar.ped_dataprevisaoentrega);
        pedidoDuplicar.ped_datavalidade = moment(pedidoDuplicar.ped_datavalidade);
        pedidoDuplicar.ped_id_duplicar = pedidoDuplicar.ped_id;
        pedidoDuplicar.ped_id = null;
        setExibirDrawerPedidos(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: pedidoDuplicar } });
    };

    function retornoPesquisaAvancada(expressao) {
        let dados = formPedidos.getFieldValue();
        let filtro = '';
        let dataInicial = dados.dataInicial;
        let dataFinal = dados.dataFinal;
        let status = dados.status;
        let ativo = 2;//Toodos
        if (dados.ativoPedido.length == 1) {
            if (dados.ativoPedido.find(e => e === "0"))
                ativo = 0;
            if (dados.ativoPedido.find(e => e === "1"))
                ativo = 1;
        }

        filtro += '&dataInicial=' + (!!dataInicial ? moment(dataInicial).format('YYYY-MM-DD') : moment(dados.dataPesquisa).format("YYYY-MM-DD"));
        filtro += '&dataFinal=' + (!!dataFinal ? moment(dataFinal).format('YYYY-MM-DD') : moment(new Date).format('YYYY-MM-DD'));
        filtro += (!!status ? `&status=${status}` : '');
        filtro += (!!ativo ? `&Ativo=${ativo}` : '');
        filtro += !!expressao ? `&filtroAdicional=${btoa(expressao)}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao', outrosFiltros: filtro } });
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ``, ordem: '+ped_datainclusao' } })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <Button type="primary" icon={<PlusOutlined />} size="middle" onClick={() => abrirDrawerPedido()}>NOVO</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                    <PesquisaOrdenacaoData opcoesOrdenacao={[
                        { label: "Código Pedido Crescente", value: "+ped_numero" },
                        { label: "Código Pedido Decrescente", value: "-ped_numero" },
                        { label: "Data Inclusão Crescente", value: "+ped_datainclusao" },
                        { label: "Data Inclusão Decrescente", value: "-ped_datainclusao" },
                        { label: "Nome do Cliente A - Z", value: "+pes_nome" },
                        { label: "Nome do Cliente Z - A", value: "-pes_nome" }
                    ]} form={formPedidos} eventoAoAlterar={() => {
                        let filtro = '';
                        let ativo = 2;//Toodos
                        if (!!formPedidos.getFieldValue().ativoPedido) {
                            if (formPedidos.getFieldValue().ativoPedido.length == 1) {
                                if (formPedidos.getFieldValue().ativoPedido.find(e => e === "0"))
                                    ativo = 0;
                                if (formPedidos.getFieldValue().ativoPedido.find(e => e === "1"))
                                    ativo = 1;
                            }
                        }
                        filtro += `&Ativo=${ativo}`;
                        let status = !!formPedidos.getFieldValue().status ? formPedidos.getFieldValue().status : '';
                        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${moment(formPedidos.getFieldValue().dataPesquisa).format("YYYY-MM-DD")}&filtro=&status=${status}` + filtro } })
                        formPedidos.setFieldsValue({ dataFinal: moment(new Date) });
                        formPedidos.setFieldsValue({ dataInicial: moment(formPedidos.getFieldValue().dataPesquisa) });
                    }} />
                </Col>
            </Row>
            <div className="page">
                <Form layout="vertical" form={formPedidos} name={formPedidos}>
                    <Row align="middle" gutter={[0, 24]}>
                        <Col span={24}>
                            <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={togglePesquisa} block>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                    </Button>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col span={24}>
                                            <Form.Item label="Status do Pedido" name="status">
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Status do Pedido">
                                                    {status.map(stp => (
                                                        <Select.Option value={stp.stp_id} key={stp.key} >{stp.stp_descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={14} lg={16} xl={18}>
                                            <Form.Item label="Situação do Pedido" name="ativoPedido">
                                                <Select mode="multiple" placeholder="Situação do Pedido" defaultValue={["1"]}>
                                                    <Select.Option key="1" value="0">Cancelado</Select.Option>
                                                    <Select.Option key="2" value="1">Em Andamento</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                                            <Data label="Data Inicial" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                                            <Data label="Data Final" name="dataFinal" />
                                        </Col>
                                    </Row>
                                    <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={formPedidos} telaFiltro={9} retornarSempre={true} />
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="Pedido/ListarPedido" colunas={[
                    {
                        title: "Status",
                        width: 125,
                        align: 'center',
                        render: ({ stp_descricao, ped_numero, stp_corstatus }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <Tag color={stp_corstatus} className="w-100">
                                            <b>{stp_descricao}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{ped_numero}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: "Informações",
                        render: ({ loc_id_transferencia, loc_descricao, pes_nome, pef_cpf, pej_cnpj, pes_telefone, pes_endereco, pes_nroestabelecimento, pes_bairro, pes_cep, pes_cidade, pes_uf, ped_datainclusao, ped_dataprevisaoentrega, pdi_qtditem, ped_ativo }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[16, 0]}>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <b>{!!loc_id_transferencia ? loc_descricao : pes_nome}</b>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                        CPF/CNPJ: <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                        Telefone: <b>{FormatFone(pes_telefone) || 'Não Informado'}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[16, 0]}>
                                    {!ped_ativo && <Col className="c-red">
                                        <strong>Cancelado</strong>
                                    </Col>}
                                    <Col>
                                        Emissão: <b>{moment(ped_datainclusao).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col>
                                        Entrega: <b>{moment(ped_dataprevisaoentrega).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col>
                                        Quant. de Itens: <b>{pdi_qtditem}</b>
                                    </Col>
                                    <Col>
                                        Endereço: <b>{pes_endereco}, {pes_nroestabelecimento}, {pes_bairro}, {pes_cep}, {pes_cidade}-{pes_uf}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: "Total (R$)",
                        align: 'right',
                        width: 180,
                        render: ({ ped_valortotal }) => (
                            <div>
                                <b>{FormatNumber(ped_valortotal, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                    <Col>
                                        <Button icon={<EyeOutlined />} onClick={() => visualizarPedido(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerPedidos exibirDrawer={exibirDrawerPedidos} fecharDrawerPedidos={setExibirDrawerPedidos}/>
            <DrawerNF showDrawerExtra={showDrawerNF} setShowDrawerExtra={setShowDrawerNF} dadosNFE={dadosNF} />
            <DrawerNFSE showDrawerExtra={showDrawerNFSE} setShowDrawerExtra={setShowDrawerNFSE} dadosNFSE={dadosNFSE} />
            <DrawerTransferencia abrirDrawer={drawerTransferencia} fecharDrawer={() => { setDrawerTransferencia(false) }} dadosPedido={dadosPedido} />
            <ModalDetalhePedido exibirModal={openModalDetalhePedido} fecharModal={() => setOpenModalDetalhePedido(false)} dadosModal={dadosDetalhesPedido} />
            <ModalSelecionarNF exibirModal={openModalEscolha} fecharModal={() => setOpenModalEscolha(false)} drawerNFSE={() => nfseEscolhida()} drawerNF={() => nfEscolhida()} />
        </div>
    );

}