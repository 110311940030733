import React, { useEffect, useState } from "react";
import { Form, Row, Divider, Col, Input, Checkbox, notification, Select, message } from "antd";

import api from '../../services/api';
import { FormGW, SelectPaginacao, Data } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import { validarIE } from "../../services/funcoesIE";
import { MaskFormat } from "../../ValueObjects";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

export default function ManutencaoVeiculo({ formulario, carregando, aoSalvar, editando }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [controleTipoRodado, setControleTipoRodado] = useState([]);
    const [controleTipoCarroceria, setControleTipoCarroceria] = useState([]);
    const [controleTipoProprietario, setControleTipoProprietario] = useState([]);
    const [controleTipoTransporte, setControleTipoTransporte] = useState([]);
    const [listaCidades, setListaCidades] = useState(false);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const [cpfCnpj, setCpfCnpj] = useState(null);

    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoRodado`).then(res => {
            if (res.status === 200) {
                setControleTipoRodado(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=TipoCarroceria`).then(res => {
            if (res.status === 200) {
                setControleTipoCarroceria(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=TipoProprietario`).then(res => {
            if (res.status === 200) {
                setControleTipoProprietario(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=TipoTransporte`).then(res => {
            if (res.status === 200) {
                setControleTipoTransporte(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, [])

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.vei_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.vei_uf) {
                lista.push({ name: "vei_uf", campo: "Uf", value: manutencao.dados.vei_uf });
            }
            if (!!manutencao.dados.cid_id) {
                lista.push({ name: "cid_id", campo: "IdCidade", value: manutencao.dados.cid_id });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }
            formulario.setFieldsValue(manutencao.dados);
            setListaCidades(true);
        }
    }, [ui.showDrawer, manutencao.dados]);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'veiculo/';

        if (values.vei_inscricaoestadual_numeroInscricaoEstadual === undefined) {
            values.vei_inscricaoestadual_numeroInscricaoEstadual = "";
        }
        if (values.vei_inscricaoestadual_ufestado === undefined) {
            values.vei_inscricaoestadual_ufestado = "";
        }

        if (!!values.vei_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: "Operação concluída", description: "Os dados do Veículo foram salvos com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: "Erro ao processar a requisição.", description: "Ocorreu um erro ao tentar salvar os dados do Veículo. Por favor, tente novamente." });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: "Operação concluída", description: "Os dados do Veículo foram salvos com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: "Erro ao processar a requisição.", description: "Ocorreu um erro ao tentar salvar os dados do Veículo. Por favor, tente novamente." });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        };
        if (!!values.vei_inscricaoestadual_numeroInscricaoEstadual && !validarIE(values.vei_inscricaoestadual_numeroInscricaoEstadual, values.vei_inscricaoestadual_ufestado)) {
            notification.warning({ message: 'Aviso', description: 'Inscrição Estadual informada é inválida' });
            return false;
        } else if (!!!values.vei_inscricaoestadual_numeroInscricaoEstadual) {
            values.vei_inscricaoestadual_numeroInscricaoEstadual = "";
        }
    }

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: "+vei_descricao" } });
    }

    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(`&UfEstado=${UfEstado}`);
            formulario.setFieldsValue({ cid_id: undefined });
        }
        else {
            setListaCidades(false);
            formulario.setFieldsValue({ cid_id: undefined });
        }
    }

    const validatorInput = (_, value) => {
        if (!value || /^[0-9]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Insira apenas números'));
    };

    function validarCpfCnpj(cpfCnpj) {
        if (cpfCnpj) {
            if (cpfCnpj.length !== 14 && cpfCnpj.length !== 11) {
                notification.warning({ message: 'Aviso!', description: 'O CPF/CNPJ informado é inválido. Deve conter 11 ou 14 dígitos' });
                formulario.setFieldsValue({
                    vei_cpfcnpj: ""
                })
            }
        }
    }

    function validarPlaca(event) {
        const placa = event.target.value;
        const placaConfirm = /^[a-z]{3}[0-9]{4}$|^[a-z]{3}[0-9]{1}[a-z]{1}[0-9]{2}$|^[A-Z]{3}[0-9]{4}$|^[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}$/;

        if (!placaConfirm.test(placa)) {
            notification.warning({ description: "Formato de placa inválido. Insira uma placa no formato AAA0000 ou AAA0A00.", message: "Atenção!" });
            formulario.setFieldsValue({ vei_placa: "" });
        }
    }

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoVeiculo" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={17} md={16} lg={19} xl={16}>
                    <Form.Item name="vei_id" hidden><Input /></Form.Item>
                    <Form.Item label="Descrição" name="vei_descricao" rules={[
                        { required: true, message: "Informe a descrição" },
                        { min: 2, message: "No mínimo 2 caracteres" }
                    ]}>
                        <Input placeholder="Informe a Descrição" max={100}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={7} md={8} lg={5} xl={5}>
                    <Form.Item label="Placa" name="vei_placa" rules={[{ required: true, message: "Informe a placa" }]}>
                        <Input
                            placeholder="Informe a placa"
                            maxLength={7}
                            style={{ textTransform: 'uppercase' }}
                            onBlur={validarPlaca}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Form.Item label="Modelo" name="vei_modelo" >
                                <Input placeholder="Informe o Modelo" maxLength={50} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Form.Item label="Ano de Fabricação" name="vei_anofabricacao" rules={[{ validator: validatorInput }]}>
                                <Input placeholder="Informe o Ano" maxLength={4} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Form.Item label="Ano do Modelo" name="vei_anomodelo" rules={[{ validator: validatorInput }]}>
                                <Input placeholder="Informe o Ano" maxLength={4} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                            <Form.Item label="Marca" name="mav_id" >
                                <SelectPaginacao url="marcaVeiculo/Listar?Ativa=true"
                                    allowClear={true}
                                    selecionarRegUnico={"mav_id"}
                                    nameLabel="mav_descricao"
                                    nameValue="mav_id"
                                    placeholder="Selecione a Marca"
                                    form={formulario}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={4}>
                            <Form.Item label="Código do Renavam" name="vei_renavan" rules={[
                                { required: true, message: "Informe o código do Renavam" },
                                { validator: validatorInput }
                            ]}>
                                <Input placeholder="Informe o Código do Renavam" maxLength={11} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                            <Form.Item label="Tipo Rodado" name="vei_tiporodado" rules={[{ required: true, message: "Selecione o tipo rodado" }]}>
                                <Select placeholder="Selecione o tipo rodado" showSearch optionFilterProp="children" allowClear={true} >
                                    {controleTipoRodado.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                            <Form.Item label="Tipo Carroceria" name="vei_tipocarroceria" rules={[{ required: true, message: "Selecione o tipo de carroceria" }]}>
                                <Select placeholder="Selecione o tipo de carroceria" showSearch optionFilterProp="children" allowClear={true} >
                                    {controleTipoCarroceria.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Data label="Data da Aquisição" name="vei_dataaquisicao" />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Data label="Data da Venda" name="vei_datavenda" />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Peso" name="vei_tara" rules={[
                                { required: true, message: "Informe o peso" },
                                { validator: validatorInput }
                            ]}>
                                <Input suffix="Kg" maxLength={6} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Capacidade do Peso" name="vei_capacidadekg" rules={[
                                { required: true, message: "Informe a capacidade de peso" },
                                { validator: validatorInput }
                            ]}>
                                <Input suffix="Kg" maxLength={6}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item label="Capacidade de m³" name="vei_capacidadem3" rules={[
                                { required: true, message: "Informe a capacidade de m³" },
                                { validator: validatorInput }
                            ]}>
                                <Input suffix="m³" maxLength={3}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                            <Form.Item label="UF" name="vei_uf" rules={[{ required: true, message: "Selecione o Estado" }]}>
                                <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" selecionarRegUnico="est_sigla" allowClear={true} form={formulario} nameLabel="est_sigla" nameValue="vei_uf" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                    conteudo={
                                        est => (<Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="Cidade"
                                name="cid_id"
                                dependencies={['vei_uf']}
                                rules={[{ required: true, message: "Selecione a Cidade" }]}
                            >
                                <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Origem" disabled={listaCidades == false} nameLabel="cid_descricao" nameValue="cid_id" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id" allowClear={true}
                                    conteudo={
                                        cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                            <Form.Item label="Tipo Proprietário" name="vei_tipoproprietario">
                                <Select placeholder="Selecione o tipo de proprietário" showSearch optionFilterProp="children" allowClear={true}>
                                    {controleTipoProprietario.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={9} sm={5} md={3} lg={3} xl={2}>
                            <Form.Item name="vei_ativo" initialValue={true} valuePropName="checked" className="t-mob-573">
                                <Checkbox>Veículo Ativo?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={3}>
                            <Form.Item name="vei_reboque" initialValue={false} valuePropName="checked" className="t-mob-573">
                                <Checkbox>Veículo de Reboque?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.vei_tipoproprietario !== currentValues.vei_tipoproprietario}>
                        {({ getFieldValue }) => {
                            if (getFieldValue().vei_tipoproprietario !== 0 && getFieldValue().vei_tipoproprietario !== undefined) return (
                                <div>
                                    <Divider orientation="left" style={{ marginBottom: '0px' }}>Proprietário</Divider>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={7} md={5} lg={4} xl={3}>
                                            <Form.Item label="CPF/CNPJ" name="vei_cpfcnpj">
                                                <CpfCnpj
                                                    className="inputCpfCnpj"
                                                    placeholder="Informe o CPF ou CNPJ"
                                                    id="cpf"
                                                    value={cpfCnpj}
                                                    onBlur={valor => validarCpfCnpj(valor.currentTarget.value.replace(/\D/g, '')) }
                                                    onChange={(event) => {
                                                        setCpfCnpj(event.target.value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={17} md={19} lg={12} xl={10}>
                                            <Form.Item label="Nome" name="vei_nomeproprietario" rules={[{ required: true, message: "Informe o nome do proprietário" }]}>
                                                <Input placeholder="Informe o nome do porprietário" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={10} md={6} lg={8} xl={7}>
                                            <Form.Item label="Tipo de Transporte" name="vei_tipotransportador" rules={[{ required: true, message: "Selecione o tipo de transporte" }]}>
                                                <Select placeholder="Selecione o tipo de transporte" showSearch optionFilterProp="children" allowClear={true}>
                                                    {controleTipoTransporte.map((item) => (
                                                        <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                                            <Form.Item label="Código ANTT" name="vei_codigoantt" rules={[{ required: true, message: "Informe o código ANTT" }]}>
                                                <Input placeholder="Informe o código antt" maxLength={8} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={8} lg={8} xl={4}>
                                            <Form.Item label="Inscrição Estadual" name="vei_inscricaoestadual_numeroInscricaoEstadual">
                                                <Input placeholder="Informe a Inscrição Estadual" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={6} lg={8} xl={3}>
                                            <Form.Item label="UF" name="vei_inscricaoestadual_ufestado">
                                                <Input placeholder="Informe a UF" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    )
}