import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Select, Input, InputNumber, Tooltip, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { InputPercentual } from '../../../components';
import SelectPaginado from "../../selectPaginado";
import api from "../../../services/api";
import { selectPaginadoActions, listagemActions } from "../../../actions";
import { useStateValue } from "../../../state";

export default function ModalNcm({ exibirModal, fecharModal, dados, setDados, formularioDados }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formularioDados.resetFields();
                fecharModal();
            }
        });
    };

    useEffect(() => {
        if (!!dados)
            formularioDados.setFieldsValue(dados);
        else
            formularioDados.resetFields();

    }, [dados, exibirModal])

    useEffect(() => {
        if (exibirModal)
            setEstado();
    }, [exibirModal])

    function setEstado() {
        api.get(`Local/BuscarLocal`).then(res => {
            let lista = [];
            formularioDados.setFieldsValue({ mva_uforigem: res.data.loc_inscricaoestadual.ufEstado });
            lista.push({ name: "mva_uforigem", campo: "Uf", value: res.data.loc_inscricaoestadual.ufEstado });
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        })
    }

    function salvarDados(values) {
        console.log(manutencao);
        let lista = [...dados.mvaorigemdestinos];
        let registro = dados.mvaorigemdestinos.find(x => x.mva_uforigem == values.mva_uforigem && x.mva_ufdestino == values.mva_ufdestino)
        if (registro) {
            registro.mva_percaliquotaicmsreducao = values.mva_percaliquotaicmsreducao;
            registro.mva_percmva = values.mva_percmva;
            registro.mva_percaliquotaicms = values.mva_percaliquotaicms;
            registro.mva_ajustado = values.mva_ajustado;
            registro.mva_simplesnacional = values.mva_simplesnacional;
        } else {
            let dadosTemp = { ...values };
            manutencao.dados.mvaorigemdestinos.push(dadosTemp)
        }
        setDados(manutencao);
        fecharModal();
        formularioDados.resetFields();
    }

    return (
        <Modal centered
            title="Cadastro Origem e Destino"
            visible={exibirModal}
            onCancel={onCloseModal}
            width={720}
            onOk={() => {
                formularioDados.submit();
            }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="formOrigemDestino" form={formularioDados} onFinish={salvarDados}>
                <Row align="middle" gutter={[8, 16]}>
                    <Col>
                        <Typography.Text>
                            Defina as Informações de Origem e Destino referente a Substituição Tributário! <br /><br />
                        </Typography.Text>
                    </Col>
                </Row>
                <Form.Item hidden name="mva_id" />
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={6} md={8} lg={8} xl={8}>
                        <Form.Item label="UF de Origem" name="mva_uforigem">
                            <SelectPaginado url="estado/Listar?IdPais=33" placeholder="Selecione a UF de Origem" form={formularioDados} name="mva_uforigem" disabled={true} conteudo={
                                est => (<Select.Option value={est.est_sigla} key={est.key}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={8} lg={8} xl={8}>
                        <Form.Item label="UF de Destino" name="mva_ufdestino" rules={[{ required: true, message: 'Selecione o Estado de Destino' }]}>
                            <SelectPaginado url="estado/Listar?IdPais=33" placeholder="Selecione a UF de Destino" form={formularioDados} name="mva_ufdestino" conteudo={
                                est => (<Select.Option value={est.est_sigla} key={est.key}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <InputPercentual
                            label="% da Redução da Base de Cálculo"
                            name="mva_percaliquotaicmsreducao"
                            defaultValue={0}
                            min={0}
                            max={100}
                        />
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <InputPercentual
                            label="% do MVA"
                            name="mva_percmva"
                            defaultValue={0}
                            rules={[{ required: true, message: 'Informe o Percentual do MVA' }]}
                            min={0}
                            max={100}
                        />
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                        <InputPercentual
                            label={
                                <div>
                                    % da Alíquota ICMS Substituição Tributária
                                    <Tooltip title="Aliquota Interna Estadual do Destino">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>}
                            name="mva_percaliquotaicms"
                            rules={[{ required: true, message: 'Informe o Percentual da Aliquota ICMS Substituição Tributária' }]}
                            defaultValue={0}
                            min={0}
                            max={100}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <InputPercentual
                            label="% do MVA Ajustado"
                            name="mva_ajustado"
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <InputPercentual
                            label="% do MVA Interestadual"
                            name="mva_simplesnacional"
                            defaultValue={0}
                            min={0}
                            max={100}
                        />
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Fórmula da Base de Cálculo" name="mva_formulabc">
                            <Input placeholder="Informe a Fórmula da Base de Cálculo" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Fórmula da Substituição Tributária" name="mva_formulast">
                            <Input placeholder="Informe a Fórmula da Substituição Tributária" />
                        </Form.Item>
                    </Col> */}
                </Row>
            </Form>
        </Modal>
    );
}