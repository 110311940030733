import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import apiPayer from '../../../services/apiPayer';
import api from "../../../services/api";
import { getLocal } from "../../../services/auth";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";
import { HasValue } from "../../../services/funcoes";

export default function ModalCancelamentoNfe({ dadosNota, exibirModalCancelarEstornar, fecharModalCancelarEstornar, titleModal, textoBotao }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalCancelarEstornar();
            }
        });
    };

    const realizarCancelamento = async () => {
        let dados = { ...dadosNota };
        dados.loc_id = parseInt(getLocal());
        dados.justificativa = form.getFieldValue().justificativa;
        if (!dados.justificativa || dados.justificativa.length < 15) {
            notification.warning({ message: `Aviso!`, description: `A Justificativa deve ter no mínimo 15 caracteres!` });
            return false;
        }

        setCarregando(true);

        try {
            const res = await api.post(`NotaFiscal/Cancelar`, dados);

            if (dados.ntf_docfiscal == true) {
                let _cStat = res.data.retorno.cStat;
                let motivo = res.data.retorno.xMotivo;

                if (res.data.retorno.retEvento != null) {
                    _cStat = res.data.retorno.retEvento[0].infEvento.cStat;
                    motivo = _cStat + '-' + res.data.retorno.retEvento[0].infEvento.xMotivo;
                }

                if (_cStat == 135 || _cStat == 155 || _cStat == 101 || _cStat == 573 || _cStat == 218) {
                    await verificarEstornoPagamentoEletronico(dados); // Aguarda antes de continuar
                    notification.success({ message: `Doc. Fiscal Nº: ${dadosNota.ntf_numero} cancelado com sucesso!`, description: motivo });
                } else {
                    notification.warning({ message: `Não foi possível efetuar o cancelamento da NF-e Nº: ${dadosNota.ntf_numero}!`, description: motivo });
                }
            } else {
                if (res.data.retorno.cStat == 135) {
                    await verificarEstornoPagamentoEletronico(dados); // Aguarda antes de continuar
                    notification.success({ message: `Doc. Fiscal Nº: ${dadosNota.ntf_numero} cancelado com sucesso!` });
                } else {
                    notification.warning({ message: `Não foi possível efetuar o cancelamento do Doc. Fiscal Nº: ${dadosNota.ntf_numero}!` });
                }
            }
        } catch (erro) {
            if (erro.response.data)
                notification.warning({ description: `Não foi possível efetuar o cancelamento do Doc. Fiscal Nº: ${dadosNota.ntf_numero},  ${erro.response.data} !`, message: 'Aviso' });
            else
                notification.warning({ description: `Não foi possível efetuar o cancelamento do Doc. Fiscal Nº: ${dadosNota.ntf_numero}!`, message: 'Aviso' });
            console.error("Erro ao cancelar:", erro);
        } finally {
            form.resetFields();
            setCarregando(false);
            fecharModalCancelarEstornar(); // Executa após tudo
        }
    };

    async function verificarEstornoPagamentoEletronico(dados) {
        if (dados.ntf_status == 1) {
            let formaPagIntegrado = dados.formasPgto.filter((item) => HasValue(item.imp_autorizacao));
            if (formaPagIntegrado.length > 0) {
                await modalEstornarPagtos(formaPagIntegrado); // Aguarda a interação do modal
            }
        }
    }

    function modalEstornarPagtos(pagamentos) {
        const descricoesConcatenadas = pagamentos
            .map((item) => item.fpg_descricao)
            .join(', ');

        return new Promise((resolve, reject) => {
            Modal.confirm({
                title: 'Atenção',
                icon: <ExclamationCircleOutlined />,
                content: `Esta nota possui pagamentos integrados com as seguintes formas de pagamento: ${descricoesConcatenadas}. Deseja estorná-los? Pode ser necessário o uso do cartão para concluir o processo.`,
                okText: 'Sim',
                cancelText: 'Não',
                onOk: async () => {
                    console.log("Estorno confirmado.");
                    await verificarPagamentos(pagamentos); // Aguarda os estornos antes de concluir
                    resolve();
                },
                onCancel: () => {
                    console.log("Estorno cancelado.");
                    resolve();
                    //reject();
                },
            });
        });
    }

    async function verificarPagamentos(pagamentos) {
        for (const item of pagamentos) {
            if (item.fpg_tipointegracao != null) {
                try {
                    if (item.fpg_tipointegracao == 1) {
                        // Integração Vero
                        console.log("Estorno via Vero ainda não implementado.");
                    }
                    else if (item.fpg_tipointegracao == 2) {
                        // Payer localhost
                        const dadosIntegracaoPos = {
                            command: "CANCELLMENT",
                            idPayer: item.imp_idintegracao,
                        };
                        const retorno = await apiPayer.post('Client/Request', dadosIntegracaoPos);
                        console.log("Estorno Payer localhost concluído:", retorno);
                    }
                    else if (item.fpg_tipointegracao == 4) {
                        // Payer Gateway
                        const retorno = await api.post(`Payer/IncluirCancellmentPayerRequest/${item.imp_idintegracao}`);
                        console.log("Estorno Payer Gateway concluído:", retorno.data);
                    }
                } catch (error) {
                    notification.warning({
                        description: `Falha ao estornar a integração do pagamento ${item.fpg_descricao}. Detalhes: ${error.message}`,
                        message: 'AVISO!',
                    });
                    console.error("Erro ao processar pagamento:", error);
                }
            }
        }
    }



    return (
        <Modal centered
            title={titleModal}
            visible={exibirModalCancelarEstornar}
            onCancel={onCloseModal}
            onOk={realizarCancelamento}
            confirmLoading={carregando}
            okText={
                <>
                    <CheckOutlined /> {textoBotao}
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" form={form}>
                <Descriptions size="small">
                    <Descriptions.Item label="Chave Doc. Fiscal">
                        <b> {dadosNota.ntf_chavenfe} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Destinatário">
                        <b> {dadosNota.ntf_nomedest}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="CPF/CNPJ">
                        <b>{MaskFormat(dadosNota.ntf_cnpjcpfdest, '', true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Nº da Nota">
                        <b>{dadosNota.ntf_numero}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Série">
                        <b>{dadosNota.ntf_serie}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Modelo">
                        <b>{dadosNota.ntf_modelo}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Emitida em">
                        <b>{moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY')}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo de Nota">
                        <b>{dadosNota.tip_descricao}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Valor Total Doc. (R$)">
                        <b> {FormatNumber(dadosNota.valor_total, true)} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Row className="m-t-8">
                    <Col span={24}>
                        <Form.Item label="Motivo do Cancelamento" name="justificativa" rules={[{ required: true, message: `Informe a Justificativa do Cancelamento` }]} >
                            <Input.TextArea placeholder="Informe aqui o motivo do cancelamento do Doc. Fiscal" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}