import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Input, Select, Checkbox, notification, Button, Table } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { info } from "../../services/funcoes";
import { listagemActions, selectPaginadoActions } from "../../actions";
import { Data, FormGW, InputPreco, SelectPaginado } from "../../components";
import { TabAgenciaBancaria, TabEndereco, TabGerente } from './tabPage';
export default function ManutencaoContaCorrente({ formulario, carregando, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaTipoContaCorrente, setListaTipoContaCorrente] = useState([]);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [contaBancaria, setContaBancaria] = useState(false);
    const [listaUsuariosPermitidos, setListaUsuariosPermitidos] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        api.get(`TipoContaCorrente/Listar`).then(res => {
            setListaTipoContaCorrente(res.data);
        });
        api.get(`Usuario/RetornaUsuariosLocal?PageSize=500`).then(res => {
            setListaUsuarios(res.data.items);
        });
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?Ativo=true&CodReceitaDespesa=1`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        })
    }, []);

    useEffect(() => {
        if (!!manutencao.dados && manutencao.dados.ctc_id > 0 && ui.showDrawer) {
            api.get(`ContaCorrente/UsuariosPermissaoSaldo?idContaCorrente=${manutencao.dados.ctc_id}`).then(res => {
                setListaUsuariosPermitidos(res.data);
                if (res.data.length > 0) {
                    formulario.setFieldsValue({ limitarSaldoUsuarios: true });
                }
            });

            let lista = [];
            if (!!manutencao.dados.ban_id) {
                lista.push({ name: "ban_id", campo: "CodigoBanco", value: manutencao.dados.ban_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formulario.setFieldsValue(manutencao.dados);

            setContaBancaria(manutencao.dados.tcc_id === 2);
        }
    }, [ui.showDrawer]);

    function setAbaTipoContaCorrente(tc){
        setContaBancaria(tc === 2);
    }

    function adicionarUsuario() {
        let usu_id = formulario.getFieldValue().usu_id;
        let lista = [...listaUsuariosPermitidos];
        if (listaUsuariosPermitidos.length > 0 && listaUsuariosPermitidos.filter(usuario => { return usuario.usu_id === usu_id }).length > 0) {
            info("Este usuário já foi adicionado!");
            return;
        } else {
            var user = listaUsuarios.find(usuario => { return usuario.usu_id === usu_id })
            lista.push(user);
        }
        setListaUsuariosPermitidos(lista);
    };

    function excluirRegistro(id) {
        let lista = [...listaUsuariosPermitidos];
        let index = lista.findIndex(i => i.key === id);
        lista.splice(index, 1);
        setListaUsuariosPermitidos(lista);
    }

    function salvarManutencao(valores) {
        let values = { ...manutencao.dados, ...valores };
        let idRetorno;
        values.contacorrenteusuarios = listaUsuariosPermitidos;
        values.ban_id = !!values.ban_id ? values.ban_id.toString() : '';
        values.ctc_saldoinicial = values.ctc_saldoinicial > 0 ? values.ctc_saldoinicial : 0;
        values.ctc_operacaoconta = values.ctc_operacaoconta;
        values.contacorrentelocal = true;
        values.ctc_unidadeatendimento = values.ctc_unidadeatendimento == "" ? null : values.ctc_unidadeatendimento
        if (!!values.ctc_fonegerente) {
            values.ctc_fonegerente = values.ctc_fonegerente.replace(/\D/g, '');
        }
        carregando(true);
        if (!!values.ctc_id) {
            editarManutencao(values, idRetorno);
        } else {
            incluirManutencao(values, idRetorno);
        }

    };

    function editarManutencao(values, idRetorno) {
        api.put(`ContaCorrente/Editar`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Conta Corrente foram salvos com sucesso!" });
                idRetorno = res.data.ctc_id;

            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } });
                        carregando(false);
                        aoSalvar(idRetorno);
                    });
    }

    function incluirManutencao(values, idRetorno) {
        api.post(`ContaCorrente/Incluir`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Conta Corrente foram salvos com sucesso!" });
                idRetorno = res.data.ctc_id;

            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } });
                        carregando(false);
                        aoSalvar(idRetorno);
                    });
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoContaCorrente" onFinish={salvarManutencao} onFinishFailed={onFinishFailed}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Dados Gerais" key="1">
                        <Form.Item name="ctc_id" hidden />
                        <Row align="middle" gutter={[8, 0]}>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="Tipo de Conta Corrente" name="tcc_id" rules={[{ required: true, message: 'Informe o tipo de conta corrente' }]}>
                                    <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o Tipo de Conta Corrente" onChange={(tc) => setAbaTipoContaCorrente(tc)}>
                                        {listaTipoContaCorrente?.map(item => (
                                            <Select.Option key={item.tcc_id} value={item.tcc_id}>{item.tcc_id} - {item.tcc_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.tcc_id !== currentValues.tcc_id}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue(['tcc_id']) === 2)
                                        return (
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item
                                                    label="Banco"
                                                    name="ban_id"
                                                    rules={[{ required: true, message: 'Informe o Código do Banco' }]}>
                                                    <SelectPaginado url="Banco/Listar" placeholder="Selecione o Código do Banco" form={formulario} name="ban_id" conteudo={
                                                        ban => (<Select.Option value={ban.ban_id} key={ban.key}>{ban.ban_id} - {ban.ban_descricao}</Select.Option>)
                                                    } />
                                                </Form.Item>
                                            </Col>
                                        );
                                    else return null;
                                }}
                            </Form.Item>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8} hidden={(formulario.getFieldValue(['ctc_id']) > 0)}>
                                <InputPreco name="ctc_saldoinicial" label="Saldo Inicial (R$)" />
                            </Col>
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.ctc_saldoinicial !== currentValues.ctc_saldoinicial}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue(['ctc_saldoinicial']) > 0)
                                        return (
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                                    <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                                        {listaContaGerencialAgrupador.map(item => (
                                                            <Select.OptGroup label={item.ctg_descricao}>
                                                                {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                                    <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                                                ))}
                                                            </Select.OptGroup>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        );
                                    else return null;
                                }}
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.ctc_saldoinicial !== currentValues.ctc_saldoinicial}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue(['ctc_saldoinicial']) > 0)
                                        return (
                                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                <Data label="Data do Saldo Inicial" name="ctc_datasaldoinicial" rules={[{ required: false, message: 'Informe a Data do Saldo Inicial' }]} />
                                            </Col>
                                        );
                                    else return null;
                                }}
                            </Form.Item>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Descrição" name="ctc_descricao" rules={[{ required: true, message: 'Informe a Descrição Referente o Banco' }]}>
                                    <Input.TextArea placeholder="Informe a Descrição Referente o Banco" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col hidden={(!(formulario.getFieldValue(['ctc_id']) > 0))}>
                                <Form.Item name="ctc_ativo" valuePropName="checked" initialValue={true}>
                                    <Checkbox>Conta Corrente Ativa?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="limitarSaldoUsuarios" valuePropName="checked" initialValues={false}>
                                    <Checkbox>Limitar visualização do saldo por usuários/grupo de usuários?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="todos_locais" valuePropName="checked" initialValues={manutencao.dados?.loc_id == null} hidden={manutencao.dados?.ctc_id != null}>
                                    <Checkbox>Todos os Locais?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.limitarSaldoUsuarios !== currentValues.limitarSaldoUsuarios}>
                            {({ getFieldValue }) => {
                                if (getFieldValue(['limitarSaldoUsuarios']) === true)
                                    return (
                                        <Row gutter={[8, 0]} justify="center">
                                            <Col xs={24} sm={17} md={19} lg={20} xl={20}>
                                                <Form.Item
                                                    name="usu_id" valuePropName="checked" label="Usuário">
                                                    <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione um Usuário">
                                                        {listaUsuarios?.map(usuario => (
                                                            <Select.Option key={usuario.usu_id} value={usuario.usu_id}>{usuario.usu_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={7} md={5} lg={4} xl={4} className="t-mob-573">
                                                <Button name="adicionarUsuario" block type="primary" icon={<PlusOutlined />}
                                                    onClick={() => adicionarUsuario()}
                                                >
                                                    Adicionar Usuário
                                                </Button>
                                            </Col>
                                        </Row>
                                    );
                                else return null;
                            }}
                        </Form.Item>
                        {!!listaUsuariosPermitidos && listaUsuariosPermitidos.length > 0 &&
                            <div className="tabela mt-dif">
                                <Table showHeader={false} columns={[
                                    {
                                        render: ({ usu_nome }) => (
                                            <div>
                                                <b>Usuário: {usu_nome}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        dataIndex: '',
                                        align: 'center',
                                        key: 'x',
                                        width: 42,
                                        render: (record) => (
                                            <div>
                                                <Row align="middle" justify="center">
                                                    <Col>
                                                        <Button icon={<DeleteOutlined />} onClick={() => excluirRegistro(record.usu_id)} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                ]} pagination={false} dataSource={listaUsuariosPermitidos} />
                            </div>
                        }

                    </Tabs.TabPane>
                    {contaBancaria && <Tabs.TabPane tab="Agência Bancária" key="2">
                        <TabAgenciaBancaria />
                    </Tabs.TabPane>}
                    {contaBancaria && <Tabs.TabPane tab="Endereço" key="3">
                        <TabEndereco formulario={formulario} />
                    </Tabs.TabPane>}
                    {contaBancaria && <Tabs.TabPane tab="Dados do Gerente" key="4">
                        <TabGerente />
                    </Tabs.TabPane>}
                </Tabs>
            </FormGW>
        </div >
    );
}