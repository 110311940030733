import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import SelectProduto from "../selects/selectProduto";
import api from "../../services/api";
import InputPreco from "../inputPreco";
import { CalcularImpostoItem } from "../../services/calculaImposto";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { useStateValue } from "../../state";

export default function FiltroServicoNFSE({ form, dadosTabela, infoEndereco, operacaoFiscal, dadosVendedor, btnEditando, itemEditando, carregarPagina }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [listaSelectServ, setListaSelectServ] = useState([]);
    const [dadosCfop, setDadosCfop] = useState([]);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [dadosProdutosImpostos, setDadosProdutosImpostos] = useState({});

    useEffect(() => {
        carregarDados();
        popularDadosContribuinte();
    }, [])

    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando.editando])

    useEffect(() => {
        if (isObjetoDiffVazio(dadosContribuinte)) {
            if (!!manutencao.dados && (!!form.getFieldValue().vnc_id || !!form.getFieldValue().ped_id)) {
                incluirItemPedidoCondicional();
            }
        }
    }, [dadosContribuinte]);

    function carregarDados() {
        api.get(`Cfop/ListarCfopTipo?tipoCfopFim=8000&tipoCfopIni=1000`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosCfop(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    async function popularDadosContribuinte(callback) {
        await api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                    if (callback) {
                        callback();
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }

    async function incluirItemPedidoCondicional() {
        let listaItens = [];
        let indice = 0;
        carregarPagina(true);
        for (let [idx, itens] of dadosTabela.dadosItens.entries()) {
            let servNota = await buscarDadosProdImpostos(itens.pro_id)
            let obj = { ...servNota, ...itens};
            obj.nfi_cfop = obj.cfo_cfop;
            listaItens.push(obj)
            if (indice === dadosTabela.dadosItens.length - 1) {
                dadosTabela.setDadosItens(listaItens);
                carregarPagina(false);
            }
            indice = indice + 1;;
        };
    }

    function editandoItem(dadosEditando) {
        let dadosTemp = {};
        let dados = dadosEditando;
        form.setFieldsValue({
            pro_id: dados.pro_id,
            quantidade: dados.nfi_qtde,
            valorUnitario: dados.nfi_valorunitario,
            nfi_numeroitem: dados.nfi_numeroitem,
            valorTotalItem: dados.nfi_qtde * dados.nfi_valorunitario,
            valorItemDesconto: (dados.nfi_qtde * dados.nfi_valorunitario) - dados.nfi_valordesconto + dados.nfi_valoroutros,
            desconto: dados.nfi_valordesconto,
            percacresc: dados.percacresc,
            percdesc: dados.percdesc,
            nfi_cfop: dados.nfi_cfop,
            acrescimo: dados.nfi_valoroutros,
            informacaoAdicional: dados.nfi_informacaoadicional,
            aliquotaIssqn: dados.aliquotaIssqn
        });
        dadosTemp = {
            pro_descricao: dadosEditando.pro_descricao,
            pro_codigo: dadosEditando.pro_codigo,
            ump_id: dadosEditando.ump_id,
            informacaoAdicional: dadosEditando.nfi_informacaoadicional,
        };
        setDadosDetalhes(dadosTemp);
    }

    //#region Calculos do Item

    function onChangeQuantidade() {
        recalcularValorTotal();
    }

    function onChangeValorUnitario() {
        recalcularValorTotal();
    }

    function onChangeValorTotal() {
        recalcularValorUnitario();
    }

    function onChangePercDesc() {
        calculaDesconto('PERC');
    }

    function onChangeDesconto() {
        calculaDesconto('VALOR');
    }

    function onChangePercAcresc() {
        calculaAcrescimo('PERC');
    }

    function onChangeAcrescimo() {
        calculaAcrescimo('VALOR');
    }

    function onClickBtnCancelar() {
        cancelar();
    }

    function onClickBtnAdicionar() {
        adicionarItem();
    }

    function recalcularValorTotal() {
        let total = 0;
        total = parseFloat(parseFloat(form.getFieldValue().quantidade) * parseFloat(form.getFieldValue().valorUnitario));
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
        calculaDesconto('ATT');
        calculaAcrescimo('ATT');
    }

    function recalcularValorUnitario() {
        let vlrUnit = 0;
        vlrUnit = parseFloat(parseFloat(form.getFieldValue().valorTotalItem) / parseFloat(form.getFieldValue().quantidade));
        form.setFieldsValue({ valorUnitario: vlrUnit.toFixed(2) });
        calculaDesconto('ATT');
        calculaAcrescimo('ATT');
    }

    function calculaDesconto(campo) {
        let valor = parseFloat((!!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0));
        let perc = parseFloat((!!form.getFieldValue().percdesc ? form.getFieldValue().percdesc : 0));
        let tot = parseFloat(form.getFieldValue().valorTotalItem);
        let result = 0;
        if (validarDescontoMaximoVendedor(perc)) {
            if (!!campo) {
                if (campo === 'PERC') {
                    result = (tot * perc) / 100;
                    form.setFieldsValue({ desconto: result.toFixed(2) });
                } else if (campo === 'ATT') {
                    result = (tot * perc) / 100;
                    form.setFieldsValue({ desconto: result.toFixed(2) });
                    valor = (!!form.getFieldValue().desconto ? parseFloat(form.getFieldValue().desconto) : 0);
                    result = (!!tot > 0 ? (valor * 100) / tot : 0);
                    form.setFieldsValue({ percdesc: result.toFixed(2) });
                } else {
                    if (valor > tot) {
                        notification.warning({ message: 'Aviso!', description: 'Valor de desconto maior do que valor total do item!' });
                        form.setFieldsValue({ desconto: tot });
                    }
                    result = (valor * 100) / tot;
                    form.setFieldsValue({ percdesc: result.toFixed(2) });
                }
                calculaTotalItemDescAcr();
            }
        } else {
            form.setFieldsValue({ desconto: 0 });
            form.setFieldsValue({ percdesc: 0 });
            calculaTotalItemDescAcr();
        }
    }

    function validarDescontoMaximoVendedor(perDesconto) {
        let retorno = true;
        if (!!dadosVendedor && !!dadosVendedor.ven_maximodesconto) {
            if (perDesconto > (dadosVendedor.ven_maximodesconto * 100)) {
                notification.warning({ description: "O desconto máximo para este vendedor não pode exceder " + dadosVendedor.ven_maximodesconto * 100 + "% !", message: "Aviso" });
                form.setFieldsValue({
                    desconto: null,
                    percdesc: null
                });
                retorno = false;
            }
        }
        return retorno;
    }

    function calculaAcrescimo(campo) {
        let valor = (!!form.getFieldValue().acrescimo ? form.getFieldValue().acrescimo : 0);
        let perc = (!!form.getFieldValue().percacresc ? form.getFieldValue().percacresc : 0);
        let tot = (!!form.getFieldValue().valorTotalItem ? parseFloat(form.getFieldValue().valorTotalItem) : 0);
        let result = 0;

        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ acrescimo: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ acrescimo: result.toFixed(2) });
                valor = !!form.getFieldValue().acrescimo ? parseFloat(form.getFieldValue().acrescimo) : 0;
                result = (tot > 0 ? (valor * 100) / tot : 0);
                form.setFieldsValue({ percacresc: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percacresc: result.toFixed(2) });
            }
            calculaTotalItemDescAcr();
        }
    };

    function calculaTotalItemDescAcr() {
        let valorTot = 0;
        let valorItens = !!form.getFieldValue().valorTotalItem ? form.getFieldValue().valorTotalItem : 0;
        let valorAcrescimo = !!form.getFieldValue().acrescimo ? form.getFieldValue().acrescimo : 0;
        let valorDesc = !!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0;

        valorTot = parseFloat(valorItens) + parseFloat(valorAcrescimo) - parseFloat(valorDesc);
        form.setFieldsValue({ valorItemDesconto: valorTot.toFixed(2) })
    }

    //#endregion

    //#region Processos do Item

    async function onChangeServID(pro_id) {
        let listaItens = [...listaSelectServ];
        let servico = listaItens.filter(pro => pro.key === pro_id)[0];
        let servicoImposto = await buscarDadosProdImpostos(servico.pro_id);
        await popularFormServico(servicoImposto);
        setarCampoQtde();
    }

    async function buscarDadosProdImpostos(servico) {
        let destinoOperacao = (!!infoEndereco.est_sigla && !!dadosContribuinte.est_sigla && (infoEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let codigoServ = !!servico ? servico : form.getFieldValue().pro_id;
        let ufDest = !!infoEndereco.est_sigla ? infoEndereco.est_sigla : dadosContribuinte.est_sigla;
        let inscEstadual = !!form.getFieldValue().pej_inscricaoestadual ? `&inscEstadual=${form.getFieldValue().pej_inscricaoestadual}` : '';
        let objProd = (await api.get(`Produto/BuscarDadosProduto?IdProduto=${codigoServ}&ufDestino=${ufDest}`
            + `&operacaoCodigo=${operacaoFiscal.ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}${''}${inscEstadual}`
        )).data[0];
        setDadosProdutosImpostos(objProd);
        form.setFieldsValue({
            pis_id: objProd.pis_id,
            nfi_cfop: objProd.cfo_cfop,
            informacaoAdicional: !!form.getFieldValue().informacaoAdicional ? form.getFieldValue().informacaoAdicional : objProd.pro_observacao,
            aliquotaIssqn: objProd.aliquotaIssqn
        });
        return objProd;
    }

    async function popularFormServico(servico) {
        let valorVenda = (!!servico.lpi_valorvenda ? servico.lpi_valorvenda : 0);
        setExibirDetalhes(true);
        form.setFieldsValue({
            quantidade: 1,
            valorUnitario: valorVenda,
            valorTotalItem: valorVenda,
            valorItemDesconto: valorVenda,
            nfi_itempedidocompra: null
        });
        setDadosDetalhes(servico);
        return servico;
    }

    async function adicionarItem(item = {}) {
        let dadosForm = form.getFieldValue();
        let possuiIe = !!dadosForm.pej_inscricaoestadual ? true : false;
        let fisicaJuridica = dadosForm.ntf_cpfcnpj.length === 14 ? 2 : dadosForm.ntf_cpfcnpj.length === 11 ? 1 : 3;
        let aliquota = !!dadosForm.aliquotaIssqn ? dadosForm.aliquotaIssqn : 0;
        let impostos = await CalcularImpostoItem("0", null, null, null, null, dadosForm.quantidade, dadosForm.valorUnitario, null, null, null, null, null, null, null,
            null, null, null, null, fisicaJuridica, 0, possuiIe, dadosForm.ntf_indicadorpresenca, false, false, true, aliquota);
        let listaItens = [...dadosTabela.dadosItens];

        if (btnEditando.editando) {
            item = listaItens.filter((serv) => (serv.nfi_numeroitem === dadosForm.nfi_numeroitem))[0];
        } else {
            item = { ...item, ...dadosForm, ...dadosDetalhes };
        }
        let dados = {};
        if (!!validarItem(dadosForm)) {
            let dadosEditando = listaItens.filter((serv) => (serv.pro_id === item.pro_id))[0];
            if (!!dadosEditando) {
                dadosEditando.impostos = impostos;
                dados = popularItemNota(true, item, dadosEditando);
                if (!!btnEditando && btnEditando.editando) {
                    btnEditando.setEditando(false);
                    alterarItem(dados, listaItens, dadosEditando)
                } else {
                    Modal.confirm({
                        title: 'Atualizar?',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Serviço já cadastrado na nota, deseja atualizar suas informações?',
                        okText: 'Atualizar',
                        cancelText: 'Incluir',
                        centered: true,
                        onOk() {
                            alterarItem(dados, listaItens, dadosEditando)
                        },
                        onCancel() {
                            dados.nfi_numeroitem = listaItens.length + 1;
                            incluirItem(dados, listaItens)
                        }
                    });
                }
            } else {
                item.impostos = impostos;
                dados = popularItemNota(false, item);
                dados.nfi_numeroitem = listaItens.length + 1;
                incluirItem(dados, listaItens)
            }
            setExibirDetalhes(false);
            limparCampos();
        }
    }

    function validarItem(dados) {
        if (dados.quantidade === 0 || !!!dados.quantidade) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade do serviço inválida!' });
            return false;
        }
        if (parseFloat(dados.valorItemDesconto) === 0 || parseFloat(dados.valorUnitario) === 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor do serviço inválido!' });
            return false
        }
        if (!!!dados.nfi_cfop) {
            notification.warning({ message: 'Aviso', description: 'Campo CFOP é obrigatório!' });
            return false;
        }
        return true;
    }

    function popularItemNota(editando, item, dadosEditando) {
        let itemNota = {};
        let formulario = form.getFieldValue();

        if (editando) {
            itemNota = {
                ...dadosEditando,
                impostos: dadosEditando.impostos,
                orm_id: dadosEditando.orm_id,
                nfi_qtde: formulario.quantidade,
                nfi_valorunitario: parseFloat(formulario.valorUnitario),
                percdesc: parseFloat(formulario.percdesc),
                nfi_valoroutros: parseFloat(formulario.acrescimo),
                nfi_valordesconto: parseFloat(formulario.desconto),
                percacresc: parseFloat(formulario.percacresc),
                nfi_informacaoadicional: formulario.informacaoAdicional,
                nfi_cfop: formulario.nfi_cfop,
                total: parseFloat(formulario.valorItemDesconto),
                aliquotaIssqn: parseFloat(formulario.aliquotaIssqn ?? 0)
            };
        } else {
            let cfop = dadosCfop.filter((cfop) => (cfop.cfo_cfop === formulario.nfi_cfop))[0];
            itemNota = {
                impostos: item.impostos,
                orm_id: item.orm_id,
                pro_id: item.pro_id,
                nfi_cfop: cfop.cfo_cfop,
                nfi_qtde: formulario.quantidade,
                nfi_numeroitem: !!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 ? dadosTabela.dadosItens.length + 1 : 1,
                nfi_valorunitario: parseFloat(formulario.valorUnitario ?? 0),
                total: parseFloat(formulario.valorItemDesconto),
                percdesc: parseFloat(formulario.percdesc ?? 0),
                nfi_valordesconto: parseFloat(formulario.desconto ?? 0),
                percacresc: parseFloat(formulario.percacresc ?? 0),
                nfi_valoroutros: parseFloat(formulario.acrescimo ?? 0),
                aliquotaIssqn: parseFloat(formulario.aliquotaIssqn ?? 0),
                nfi_informacaoadicional: formulario.informacaoAdicional,
                cfo_cfop: cfop.cfo_cfop,
                cfo_descricao: cfop.cfo_descricao,
                cfo_id: cfop.cfo_id,
                nfi_unidademedida: item.ump_id,
                pro_codigo: item.pro_codigo,
                pro_descricao: item.pro_descricao,
                ump_id: item.ump_id,
                pis_id: item.pis_id ?? dadosProdutosImpostos.pis_id,
            };
        }
        return itemNota;
    }

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, pro_codigo: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, nfi_cfop: null, icm_id: null, informacaoAdicional: '' });
    };

    function alterarItem(dados, listaItens, dadosEditando) {
        let indice = listaItens.findIndex((item) => (item === dadosEditando));
        listaItens.splice(indice, 1, { ...dados });
        dadosTabela.setDadosItens(listaItens);
        notification.success({ message: 'Aviso', description: 'Serviço editado com sucesso!' });
    }

    function incluirItem(dados, listaItens) {
        listaItens.push(dados);
        dadosTabela.setDadosItens(listaItens);
        notification.success({ message: 'Aviso', description: 'Serviço incluído com sucesso!' });
    }

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        limparCampos();
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    }

    function setarCampoQtde() {
        setTimeout(() => {
            try {
                let element = document.getElementById("qtde");
                if (element) {
                    element.focus();
                    element.select(); // Seleciona todo o texto dentro do campo
                }
            } catch (error) {
                console.log('error :>> ', error);
            }
        }, 200);
    }

    //#endregion

    return (
        <div>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item label="Selecione o Serviço" name="pro_id">
                        <SelectProduto valueKey={true} detalhes={true}
                            placeholder="Selecione o Serviço"
                            name="pro_id"
                            form={form}
                            ordem={"pro_descricao"}
                            filtroExtra={`&SomenteServico=true`}
                            setListaDados={setListaSelectServ}
                            onChange={(dados) => { onChangeServID(dados) }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card title={
                        <Row gutter={[8, 0]}>
                            <Col>
                                Serviço: <b>{dadosDetalhes.pro_descricao}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Form.Item hidden label="Valor Frete" name="nfi_valofrete">
                                <Input></Input>
                            </Form.Item>
                            <Col xs={24} sm={24} md={17} lg={16} xl={20}>
                                <Form.Item label="CFOP - Código Fiscal de Operação" name="nfi_cfop" rules={[{ required: true, message: 'Informe o CFOP' }]}>
                                    <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione um CFOP">
                                        {dadosCfop.filter(cfop => { return form.getFieldValue().interestadual ? cfop.cfo_cfop.substring(0, 1) === "6" : cfop.cfo_cfop.substring(0, 1) !== "6" }).map((cfop) => (
                                            <Select.Option value={cfop.cfo_cfop} key={cfop.cfo_id}>{cfop.cfo_cfop} - {cfop.cfo_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                                <Form.Item label="Alíquota ISSQN" name="aliquotaIssqn">
                                    <InputNumber disabled precision={2} min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={4} md={3} lg={4} xl={3}>
                                <Form.Item label="Quantidade" name="quantidade">
                                    <InputNumber decimalSeparator="," min={0} step={1} id="qtde" placeholder="Informe a Quantidade do Serviço" onChange={() => { onChangeQuantidade() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                <InputPreco label="Valor Unitário (R$)" name="valorUnitario" onChange={() => onChangeValorUnitario()} />
                            </Col>
                            <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                <InputPreco label="Valor Total do Serviço (R$)" name="valorTotalItem" onChange={() => onChangeValorTotal()} />
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={3} xl={2}>
                                <Form.Item label="Desc. (%)" name="percdesc">
                                    <InputNumber
                                        onChange={() => (onChangePercDesc())}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={3} xl={2}>
                                <InputPreco label="Desc. (R$)" name="desconto" precision={2} onChange={() => onChangeDesconto()} />
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={3} xl={2}>
                                <Form.Item label="Acrésc. (%)" name="percacresc">
                                    <InputNumber
                                        onChange={() => { onChangePercAcresc() }}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={4} md={3} lg={3} xl={2}>
                                <InputPreco label="Acrésc. (R$)" name="acrescimo" onChange={() => { onChangeAcrescimo() }} />
                            </Col>
                            <Col xs={24} sm={8} md={4} lg={4} xl={5}>
                                <InputPreco label="VALOR TOTAL (R$)" name="valorItemDesconto" disabled={true} />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="Informações Adicionais do Serviço" name="informacaoAdicional">
                                    <Input.TextArea placeholder="Informe as Observações Referente o Serviço" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-8">
                            <Col>
                                <Button icon={<CloseOutlined />} onClick={() => (onClickBtnCancelar())}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<PlusOutlined />} type="primary" onClick={() => onClickBtnAdicionar()}>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            }
        </div>
    )
}