import React, { useState } from "react";
import { Button } from "antd";

import { validarPermissaoTela } from "../../services/permissoes";

export default function NotaFiscalServicoButton(props) {
    const [desabilita, setDesabilita] = useState(false);

    useState(() => {
        setDesabilita(validarPermissaoTela(9));
    }, []);

    return (
        <Button type={props.type} disabled={desabilita} onClick={() => props.onClick()} icon={props.icon} size={props.size} htmlType={props.htmlType}>
            {props.children}
        </Button>
    )
}