import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Input, notification, Tabs, Select, Button, Table, Tag } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import InputPreco from "../../inputPreco";
import Data from "../../data";
import api from "../../../services/api";
import moment from "moment";
import SelectPaginacao from "../../selects/selectPaginacao";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalDocsFiscais({ form, exibirModal, fecharModal, listaDocs, setListaDocs }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaDocsTab1, setListaDocsTab1] = useState([]);
    const [selectedRowKeys1, setSelectedRowKeys1] = useState([]);
    const [listaDocsTab2, setListaDocsTab2] = useState([]);
    const [selectedRowKeys2, setSelectedRowKeys2] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [serie, setSerie] = useState([]);

    useEffect(() => {
        setAbaSelecionada("1");
        definirCamposTab1();
        definirCamposTab2();
        setListaDocsTab2([]);
        carregarDados();
    }, [exibirModal]);

    const docsSelecionados1 = (newSelectedRowKeys) => {
        setSelectedRowKeys1(newSelectedRowKeys);
    };

    const rowSelection1 = {
        selectedRowKeys1,
        onChange: docsSelecionados1,
    };

    const docsSelecionados2 = (newSelectedRowKeys) => {
        setSelectedRowKeys2(newSelectedRowKeys)
    };

    const rowSelection2 = {
        selectedRowKeys2,
        onChange: docsSelecionados2,
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        });
    };

    async function carregarDados() {
        let listaSerie = await api.get('SerieFiscal/Listar?CodigoModeloDocFiscal=55&EmitenteDocumento=0&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setSerie(serie);
        }
    }

    async function definirCamposTab1() {
        form.setFieldsValue({
            Filtro: null,
            DataInicial: moment(new Date()),
            DataFinal: moment(new Date())
        });
        setListaDocsTab1([]);
    }

    async function definirCamposTab2() {
        form.setFieldsValue({
            doc_chaveacesso: undefined,
            doc_modelodoc: undefined,
            destino: null,
            doc_pesobruto: 0,
            doc_valortotal: 0
        })
    }

    async function buscarDoc() {
        setCarregando(true);
        let dados = form.getFieldValue();
        let Filtro = dados.Filtro || null;
        let DataInicial = Filtro === null && dados.DataInicial ? moment(dados.DataInicial).format('YYYY-MM-DD') : null;
        let DataFinal = Filtro === null && dados.DataFinal ? moment(dados.DataFinal).format('YYYY-MM-DD') : null;

        const filtros = { Filtro, DataInicial, DataFinal };

        await api.get(`ManifestoEletronico/ListarDocumentos`, { params: filtros }).then(
            res => {
                let retorno = res.data.items;
                if (retorno.length === 0) {
                    retornoNull();
                } else {
                    adicionarDoc(retorno);
                }
            }
        ).catch(
            error => {
                retornoNull()
                console.log(error)
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        )
    }

    async function adicionarDoc(retorno) {
        if (abaSelecionada === "1") {
            let listaTemp = [];
            let sequencia = listaTemp.length;

            retorno.forEach((doc, index) => {
                doc.sequencia = sequencia + 1 + index;
                listaTemp.push(doc);
            });

            setListaDocsTab1(listaTemp);
            form.setFieldsValue({ Filtro: null })

        } else if (abaSelecionada === "2") {
            let dados = form.getFieldValue();

            if (dados.doc_chaveacesso && dados.destino && dados.doc_modelodoc && dados.doc_valortotal > 0) {
                if (!validarCampos(dados)) return;
                let listaTemp = [...listaDocsTab2];

                listaTemp.push({
                    doc_sequencia: listaDocsTab2.length + 1,
                    doc_chaveacesso: dados.doc_chaveacesso,
                    destino: dados.destino,
                    doc_modelodoc: dados.doc_modelodoc,
                    doc_valortotal: dados.doc_valortotal,
                    doc_pesobruto: dados.doc_pesobruto
                })

                setListaDocsTab2(listaTemp);
                notification.success({ message: 'Atenção!', description: 'Documento Fiscal adicionado.' });
                definirCamposTab2();
            } else {
                notification.warning({ message: 'Atenção!', description: 'Preencha todos os campos obrigatórios' });
            }
        }
    }

    function validarCampos(dados) {
        if (dados.doc_chaveacesso.length !== 44) {
            notification.warning({ message: 'Atenção!', description: 'Chave de Acesso deve possuir 44 digítos' });
            return false;
        }
        if (dados.doc_modelodoc !== "55" && dados.doc_modelodoc !== "57") {
            notification.warning({ message: 'Atenção!', description: 'Deve ser informado um modelo 55 ou 57' });
            return false;
        }
        return true;
    }

    async function retornoNull() {
        notification.warning({ message: 'Aviso!', description: 'Não foi encontrado nenhum Documento.' })
    }

    function salvarDocs() {
        const chavesExistentes = listaDocs.map((doc) => doc.doc_chaveacesso);
        if (abaSelecionada === "1") {
            const docsSelecionados1 = listaDocsTab1.filter((doc) => selectedRowKeys1.includes(doc.sequencia));
            if (docsSelecionados1.length === 0) {
                notification.warning({ message: 'Atenção!', description: 'É necessário selecionar pelo menos um documento para vinculá-lo' })
            } else {
                const docsDuplicados = docsSelecionados1.filter((doc) => chavesExistentes.includes(doc.chave));

                if (docsDuplicados.length > 0) {
                    notification.warning({ message: 'Atenção!', description: 'Já existe um documento com a mesma chave vinculado.' });
                } else {
                    const docsAtualizados = docsSelecionados1.map((doc, index) => ({
                        doc_sequencia: listaDocs.length + index + 1,
                        doc_chaveacesso: doc.chave,
                        doc_modelodoc: doc.modelo,
                        doc_pesobruto: doc.peso,
                        doc_valortotal: doc.valorTotal,
                        numero: doc.numero,
                        dataEmissao: doc.dataEmissao,
                        destinatario: doc.destinatario,
                        idCidadeOrigem: doc.idCidadeOrigem,
                        cidadeOrigem: doc.cidadeOrigem,
                        ufOrigem: doc.ufOrigem,
                        idCidadeDestino: doc.idCidadeDestino,
                        cid_id: doc.idCidadeDestino,
                        cidadeDestino: doc.cidadeDestino,
                        ufDestino: doc.ufDestino,
                        cepDestino: doc.cepDestino,
                        cepOrigem: doc.cepOrigem
                    }))
                    setListaDocs((prevListaDocs) => [...prevListaDocs, ...docsAtualizados]);
                    fecharModal();
                }
            }
        } else if (abaSelecionada === "2") {
            const docsSelecionados2 = listaDocsTab2.filter((doc) => selectedRowKeys2.includes(doc.doc_sequencia));
            if (docsSelecionados2.length === 0) {
                notification.warning({ message: 'Atenção!', description: 'É necessário selecionar pelo menos um documento para vinculá-lo' })
            } else {
                const docsDuplicados = docsSelecionados2.filter((doc) => chavesExistentes.includes(doc.doc_chaveacesso));
                if (docsDuplicados.length > 0) {
                    notification.warning({ message: 'Atenção!', description: 'Já existe um documento com a mesma chave vinculado.' });
                } else {
                    const docsAtualizados = docsSelecionados2.map((doc, index) => ({
                        doc_sequencia: listaDocs.length + index + 1,
                        destino: doc.destino,
                        cid_id: doc.destino,
                        doc_chaveacesso: doc.doc_chaveacesso,
                        doc_modelodoc: doc.doc_modelodoc,
                        doc_pesobruto: doc.doc_pesobruto,
                        doc_valortotal: doc.doc_valortotal
                    }));
                    setListaDocs((prevListaDocs) => [...prevListaDocs, ...docsAtualizados]);
                    fecharModal();
                }
            }
        }
    }

    return (
        <div>
            <Modal centered
                title="Selecionar Documento Fiscal"
                visible={exibirModal}
                onCancel={onCloseModal}
                width="70%"
                onOk={salvarDocs}
                okText={
                    <>
                        <SaveOutlined /> Vincular Documentos
                    </>
                }
                cancelText={
                    <>
                        <CloseOutlined /> Cancelar
                    </>
                }
                maskClosable={false}
            >
                <div>
                    <Form layout="vertical" name="formDocsFiscais" form={form} initialValues={{ serie_id: "" }}>
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)} >
                            <Tabs.TabPane tab="Vincluar NF-e ao Manifesto Eletrônico" key="1">
                                <Row align="middle" gutter={[8, 0]} style={{ marginBottom: '10px' }} >
                                    <Col xs={24} sm={8} md={3} lg={4} xl={3}>
                                        <Form.Item label="Série" name="serie_id">
                                            <Select allowClear placeholder="Selecione uma Série">
                                                {serie.map((ser) => (
                                                    <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                        <Data label="Data Inicial" name="DataInicial" />
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                        <Data label="Data Final" name="DataFinal" />
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                        <Form.Item label="Pesquise por" name="Filtro">
                                            <Input placeholder="chave, descrição ou modelo" maxLength={44} />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ marginTop: '14px' }}>
                                        <Button type="primary" size="small" loading={carregando} icon={<SearchOutlined />} onClick={() => buscarDoc()}>Filtrar Dados</Button>
                                    </Col>
                                    <Col style={{ marginTop: '14px' }}>
                                        <Button size="small" icon={<CloseOutlined />} onClick={() => definirCamposTab1()}>Limpar Filtros</Button>
                                    </Col>
                                </Row>
                                <Table dataSource={listaDocsTab1} rowKey={(record) => record.sequencia} columns={
                                    [
                                        {
                                            title: 'Sequência',
                                            width: 30,
                                            align: 'center',
                                            render: ({ sequencia }) => (
                                                <Tag>
                                                    <b>{sequencia}</b>
                                                </Tag>
                                            )
                                        },
                                        {
                                            title: 'Chave de Acesso',
                                            align: 'left',
                                            render: ({ chave }) => (
                                                <b>{chave}</b>
                                            )
                                        },
                                        {
                                            title: 'Data Emissão',
                                            align: 'center',
                                            width: 200,
                                            render: ({ dataEmissao }) => (
                                                <b>{dataEmissao}</b>
                                            )
                                        },
                                        {
                                            title: 'Modelo',
                                            align: 'center',
                                            width: 10,
                                            render: ({ modelo }) => (
                                                <b>{modelo}</b>
                                            )
                                        },
                                        {
                                            title: 'Número',
                                            align: 'center',
                                            width: 100,
                                            render: ({ numero }) => (
                                                <b>{numero}</b>
                                            )
                                        },
                                        {
                                            title: 'Destinatário',
                                            align: 'left',
                                            render: ({ destinatario }) => (
                                                <b>{destinatario}</b>
                                            )
                                        },
                                        {
                                            title: 'Valor Total (R$)',
                                            fixed: 'right',
                                            align: 'right',
                                            width: 100,
                                            render: ({ valorTotal }) => (
                                                <div>
                                                    <b>R$ {FormatNumber(valorTotal, true)}</b>
                                                </div>
                                            )
                                        }
                                    ]
                                } rowSelection={rowSelection1}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Vincular NF-e de Terceiros ao Manifesto Eletrônico" key="2">
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col xs={24} sm={24} md={16} lg={16} xl={8}>
                                        <Form.Item label="Chave" name="doc_chaveacesso" rules={[{ required: true }]}>
                                            <Input placeholder="Informe a chave de acesso" maxLength={44} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={8} xl={3}>
                                        <Form.Item label="Modelo" name="doc_modelodoc" rules={[{ required: true }]}>
                                            <Input placeholder="Informe o Modelo" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={16} md={12} lg={12} xl={5}>
                                        <Form.Item label="Cidade de Destino" name="destino" rules={[{ required: true }]}>
                                            <SelectPaginacao url="Cidade/listar" nameLabel="cid_descricao" allowClear nameValue="destino" selecionarRegUnico="cid_id" form={form}
                                                conteudo={
                                                    cid => (
                                                        <Select.Option value={cid.cid_id} key={cid.key} label={`${cid.cid_id} - ${cid.cid_descricao}`}>
                                                            {cid.cid_id} - {cid.cid_descricao} - {cid.estado.est_sigla}
                                                        </Select.Option>
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={3}>
                                        <InputPreco label="Peso" name="doc_pesobruto" />
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={3}>
                                        <InputPreco label="Valor Total (R$)" name="doc_valortotal" rules={[{ required: true }]} />
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[8, 0]} style={{ marginBottom: '10px' }}>
                                    <Col>
                                        <Button type="primary" size="small" icon={<PlusOutlined />} onClick={() => adicionarDoc()}>Adicionar Nota</Button>
                                    </Col>
                                    <Col>
                                        <Button size="small" icon={<CloseOutlined />} onClick={() => definirCamposTab2()}>Limpar Campos</Button>
                                    </Col>
                                </Row>
                                <Table dataSource={listaDocsTab2} rowKey={(record) => record.doc_sequencia} columns={
                                    [
                                        {
                                            title: 'Sequência',
                                            width: 30,
                                            align: 'center',
                                            render: ({ doc_sequencia }) => (
                                                <Tag>
                                                    <b>{doc_sequencia}</b>
                                                </Tag>
                                            )
                                        },
                                        {
                                            title: 'Chave de Acesso',
                                            width: 300,
                                            align: 'left',
                                            render: ({ doc_chaveacesso }) => (
                                                <b>{doc_chaveacesso}</b>
                                            )
                                        },
                                        {
                                            title: 'Destino',
                                            align: 'center',
                                            width: 100,
                                            render: ({ destino }) => (
                                                <b>{destino}</b>
                                            )
                                        },
                                        {
                                            title: 'Peso',
                                            width: 100,
                                            align: 'center',
                                            render: ({ doc_pesobruto }) => (
                                                <b>{doc_pesobruto}</b>
                                            )
                                        },
                                        {
                                            title: 'Valor Total (R$)',
                                            fixed: 'right',
                                            align: 'right',
                                            width: 100,
                                            render: ({ doc_valortotal }) => (
                                                <b>{doc_valortotal}</b>
                                            )
                                        }
                                    ]
                                } rowSelection={rowSelection2}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}