import React, { useState } from "react";
import { Button, Col, Collapse, Form, Input, Row } from "antd";
import { BreadcrumbPage, ComboData, Data, EmitirConhecimento, TabelaDados } from "../../components";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import { drawerActions } from "../../actions";
import moment from "moment";
import DrawerConhecimentoTransporte from "./drawer";

export default function ConhecimentoDeTransporte({ }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [formParam] = Form.useForm();
    const [open, setOpen] = useState(false);

    const togglePesquisa = () => {
        setOpen(!open);
    }

    function filtrosAvancados(value) {
        let sql = "";
        // sql += !!formParam.getFieldValue().status ? `&StatusMDFe=${formParam.getFieldValue().status}` : "";
        // sql += !!form.getFieldValue().dataInicial ? `&DataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        // sql += !!form.getFieldValue().dataFinal ? `&DataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        // //sql += !!form.getFieldValue().ser_serie ? `&SerieFiscal=${form.getFieldValue().ser_serie}`: "";
        // dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!value ? value : !!formParam.getFieldValue().filtroPor ? formParam.getFieldValue().filtroPor : ''}${sql}`, pagina: 1 } });
    };

    return (
        <div className="p-10">
            <div>
                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>
                        <BreadcrumbPage />
                    </Col>
                    <Col>
                        <Row align="middle" justify="end">
                            <Col>
                                <EmitirConhecimento size="middle" type="primary" onClick={() => {
                                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                                }} icon={<PlusOutlined />}>
                                    EMITIR
                                </EmitirConhecimento>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form layout="vertical" form={formParam}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={17} md={17} lg={8} xl={8} xxl={8}>
                            <Form.Item label={"Pesquisar por"} name="filtroPor">
                                <Input.Search placeholder="Número, Descrição.. " onSearch={valor => filtrosAvancados(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                            <ComboData form={form} eventoAoAlterar={() => {
                                form.setFieldsValue({ DataFinal: moment(new Date()) });
                                form.setFieldsValue({ DataInicial: moment(form.getFieldValue().dataPesquisa) });
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                filtrosAvancados();
                            }} />
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Form layout="vertical" form={form}>
                    <div className="page m-t-8 m-b-8">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Final" name="dataFinal" onBlur={() => filtrosAvancados()} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={5} className="t-mob t-5">
                                                <Row gutter={[8, 8]} justify="end" className="tt-5">
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button icon={<CloseOutlined />} block onClick={() => { form.resetFields() }}>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => filtrosAvancados()}>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados/>
            </div>
            <DrawerConhecimentoTransporte />
        </div>
    )
}