import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Typography, Select, Button, Radio, Table, Modal, Tag, Divider } from "antd";
import { EditOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { arredonda, isNotNullOrEmpty } from "../../services/funcoes";
import { FormatDate, FormatNumber } from "../../ValueObjects";
import { BreadcrumbAbrirChamado, Data } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
import { Link } from "@material-ui/core";
import { ModalGradeEstoque } from "../../components/modals";

export default function ResumoProduto() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaProdutos, setListaProdutos] = useState([]);
    const [dadosProdutoGrade, setDadosProdutoGrade] = useState({});
    const [openGradeProduto, setOpenGradeProduto] = useState(false);
    const [openProduto, setOpenProduto] = useState(false);
    const [dadosProduto, setDadosProduto] = useState({});
    const [dadosUltimaVenda, setDadosUltimaVenda] = useState({});
    const [dadosUltimaCompra, setDadosUltimaCompra] = useState({});
    const [dadosUltimosCustos, setDadosUltimosCustos] = useState({});
    const [listaNotasVenda, setListaNotasVenda] = useState([]);
    const [listaNotasCompra, setListaNotasCompra] = useState([]);
    const [dadosCondicionais, setDadosCondicionais] = useState([]);
    const [listaOrdemCompra, setListaOrdemCompra] = useState([]);
    const [listaPedidos, setListaPedidos] = useState([]);
    const [listaMovimentacoes, setListaMovimentacoes] = useState([]);
    const [listaPreco, setListaPreco] = useState([]);
    const [dadosEstoque, setDadosEstoque] = useState([]);
    const [formulario] = Form.useForm(); const [totalEstoque, setTotalEstoque] = useState(0);
    const [totalPedido, setTotalPedido] = useState(0);
    const [totalCondicional, setTotalCondicional] = useState(0);
    const [totalPrevisoes, setTotalPrevisoes] = useState(0);
    const [openModalEstoque, setOpenModalEstoque] = useState(false);

    const alterarProduto = () => {
        setOpenProduto(!openProduto);
    };

    const footer = () => {
        return (
            <Form>
                <Row gutter={[0, 16]}>
                    <Col span={24} >
                        <Row justify="end">
                            <Col style={{ width: '120px', fontSize: '13px', textAlign: 'center' }}>
                                <b>Totalizador: </b>
                            </Col>
                            <Col></Col>
                            <Col style={{ width: '120px', fontSize: '12px', textAlign: 'center' }}>
                                <b>{totalEstoque}</b>
                            </Col>
                            <Col></Col>
                            <Col style={{ width: '120px', fontSize: '12px', textAlign: 'center' }}>
                                <b>{totalPedido}</b>
                            </Col>
                            <Col></Col>
                            <Col style={{ width: '125px', fontSize: '12px', textAlign: 'center' }}>
                                <b>{totalCondicional}</b>
                            </Col>
                            <Col></Col>
                            <Col style={{ width: '110px', fontSize: '12px', textAlign: 'center' }}>
                                <b>{totalPrevisoes}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }

    useEffect(() => {
        api.get(`Produto/ListaProdutoFiltro?filtro=&ordem=%2Bpro_descricao`).then(res => {
            if (res.status === 200) {
                setListaProdutos(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);

    useEffect(() => {
        formulario.setFieldsValue({ periodo: 2 });
        montarFiltro(false);
        if (!!manutencao.dados && !!manutencao.dados.pro_id) {
            setDadosProduto(manutencao.dados);
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (JSON.stringify(dadosProduto) !== '{}' && !!dadosProduto.pro_id) {
            listarDados(dadosProduto);
        }
    }, [dadosProduto]);

    function onChangeProId(value) {
        api.get(`Produto/RetornaDadosProduto?ProdutoId=${value}`).then(
            res => {
                if (!!res.data) {
                    setDadosProduto(res.data);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    };

    function listarDados(produto = dadosProduto) {
        let dataInicial;
        let dataFinal;
        if (!!!formulario.getFieldValue().dataInicial || !!!formulario.getFieldValue().dataFinal) {
            let dataInicial = retornaDataInicial();

            dataInicial = FormatDate(moment(dataInicial).toDate().toLocaleDateString());
            dataFinal = FormatDate(moment(new Date()).toDate().toLocaleDateString());
        } else {
            dataInicial = FormatDate(formulario.getFieldValue().dataInicial.toDate().toLocaleDateString());
            dataFinal = FormatDate(formulario.getFieldValue().dataFinal.toDate().toLocaleDateString());
        }
        if (JSON.stringify(produto) !== '{}') {
            api.get(`NotaFiscal/RetornaDadosUltimaCompraVendaCRM?IdProduto=${produto.pro_id}&tiponota=0`).then(res => {
                if (res.status === 200 && !!res.data) {
                    setDadosUltimaVenda(res.data);
                } else {
                    setDadosUltimaVenda({});
                }
            }).catch((erro) => console.error(erro));

            api.get(`NotaFiscal/RetornaDadosUltimaCompraVendaCRM?IdProduto=${produto.pro_id}&tiponota=1`).then(res => {
                if (res.status === 200 && !!res.data) {
                    setDadosUltimaCompra(res.data);
                } else {
                    setDadosUltimaCompra({});
                }
            }).catch((erro) => console.error(erro));

            api.get(`Produto/BuscarUltimoCustoProduto?idProduto=${produto.pro_id}`).then(res => {
                if (res.status === 200 && !!res.data) {
                    setDadosUltimosCustos(res.data);
                } else {
                    setDadosUltimosCustos({});
                }
            }).catch((erro) => console.error(erro));

            api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${produto.pro_id}`).then(res => {
                if (res.status === 200) {
                    setListaPreco(res.data);
                }
            }).catch((erro) => console.error(erro));

            api.get(`Estoque/Listar?CodProduto=${produto.pro_id}&TipoLista=5`).then(res => {
                if (res.status === 200) {
                    console.log("dadosestoque", res.data.items);
                    setDadosEstoque(res.data.items);
                    setTotalEstoque(res.data.items[0].totalEstoque);
                    setTotalPedido(res.data.items[0].totalPedido);
                    setTotalCondicional(res.data.items[0].totalCondicional);
                    setTotalPrevisoes(res.data.items[0].totalPrevisoes);
                }
            }).catch((erro) => console.error(erro));

            api.get(`NotaFiscal/RetornaDadosCompraVendaCRMProduto?idProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}&tiponota=0`).then(res => {
                if (res.status === 200) {
                    setListaNotasVenda(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`NotaFiscal/RetornaDadosCompraVendaCRMProduto?idProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}&tiponota=1`).then(res => {
                if (res.status === 200) {
                    setListaNotasCompra(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`VendaCondicional/RetornaDadosCondicionaisCRMProduto?idProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`).then(res => {
                if (res.status === 200) {
                    setDadosCondicionais(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`VendaCondicional/RetornaDadosCondicionaisCRMProduto?idProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`).then(res => {
                if (res.status === 200) {
                    setDadosCondicionais(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`OrdemCompra/RetornaDadosOrdemCompraCRMProduto?idProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`).then(res => {
                if (res.status === 200) {
                    setListaOrdemCompra(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`Pedido/RetornaDadosPedidoCRMProduto?IdProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`).then(res => {
                if (res.status === 200) {
                    setListaPedidos(res.data);
                }
            }).catch((erro) => console.error(erro));
            api.get(`Estoque/RetornaDadosMovimentacaoEstoqueCRMProduto?CodigoProduto=${produto.pro_id}&dataFinal=${dataFinal}&dataInicial=${dataInicial}`).then(res => {
                if (res.status === 200) {
                    res.data.map((movimento) => {
                        movimento.key = movimento.codigoMovimento;
                    });
                    setListaMovimentacoes(res.data);
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function filtrarProduto(pro_id) {
        api.get(`Produto/RetornaDadosProduto?ProdutoId=${pro_id}`).then(res => {
            if (res.status === 200) {
                setDadosProduto(res.data);
            }
        }).catch((erro) => console.error(erro));
    };

    function montarFiltro(listar = true) {
        let dataInicial = retornaDataInicial();

        formulario.setFieldsValue({ dataInicial: moment(dataInicial), dataFinal: moment(new Date()) });

        if (listar) {
            listarDados();
        }
    };

    function retornaDataInicial() {
        let periodo = isNotNullOrEmpty(formulario.getFieldValue().periodo) ? formulario.getFieldValue().periodo : 2;

        let dataInicial = new Date();
        switch (periodo) {
            case 0:
                dataInicial = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
                break;
            case 1:
                dataInicial = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate());
                break;
            case 2:
                dataInicial = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30);
                break;
        };
        return dataInicial;
    };

    function listarGrades(record) {
        setDadosProdutoGrade(record);
        setOpenModalEstoque(true);
    };

    return (
        <div className="p-10 pages-col">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" form={formulario}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={15} md={16} lg={16} xl={18} xxl={20}>
                        <Form.Item label="Período" name="periodo">
                            <Radio.Group onChange={(e) => { montarFiltro() }}>
                                <Radio value={0}>1 Ano</Radio>
                                <Radio value={1}>6 Meses</Radio>
                                <Radio value={2}>Últimos 30 dias</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={9} md={8} lg={8} xl={6} xxl={4}>
                        <Row justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Data onBlur={() => { listarDados() }} label="Data Inicial" name="dataInicial" />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Data onBlur={() => { listarDados() }} label="Data Final" name="dataFinal" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[0, 24]} className="m-t-16">
                    <Col span={24}>
                        <Card className="card-crm" extra={
                            <Button onClick={() => alterarProduto()} icon={openProduto ? <CloseOutlined /> : <EditOutlined />} />
                        }>
                            {!!openProduto === true &&
                                <Row className="select">
                                    <Col span={24}>
                                        <Form.Item name="pro_id">
                                            <SelectPaginado url="Produto/ListarTodas" placeholder="Selecione o Produto" form={formulario} name="pro_id" onChangeFunction={(cod) => onChangeProId(cod)} conteudo={
                                                pro => (<Select.Option value={pro.pro_id} key={pro.pro_id}>{pro.pro_id} - {pro.pro_descricao}
                                                    {(!!pro.pro_ean ? <div>EAN/GTIN: <b>{pro.pro_ean}</b></div> : "")}
                                                    {(!!pro.descricaograde ? <div>Grade: <b>{pro.descricaograde}</b> </div> : "")}
                                                    {(!!pro.pro_codigoetiqueta ? <div>Cód. Etiqueta: <b>{pro.pro_codigoetiqueta}</b> </div> : "")}
                                                </Select.Option>)
                                            }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <Typography.Title level={3}>
                                        {!!dadosProduto && !!dadosProduto.pro_descricao ? dadosProduto.pro_descricao : 'Não localizado'}
                                    </Typography.Title>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <div className="crm-card m-t-16">
                    <Row gutter={[0, 16]} className="lista-resumo">
                        <Col span={24}>
                            <Card size="default" title="Estoque por filial">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={dadosEstoque} footer={footer} columns={[
                                            {
                                                title: 'Filial',
                                                render: ({ loc_descricao }) => (
                                                    <div>
                                                        <b>{loc_descricao}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                width: 98,
                                                align: 'center',
                                                render: (dadosEstoque) => (
                                                    dadosEstoque.possuiGrade ? (
                                                        <div>
                                                            <Link to="" onClick={() => listarGrades(dadosEstoque)}>
                                                                <u>Estoque Grade</u>
                                                            </Link>
                                                        </div>
                                                    ) : null
                                                ),
                                            },
                                            {
                                                title: 'Un. Medida',
                                                align: 'center',
                                                width: 120,
                                                render: ({ ump_id }) => (
                                                    <div>
                                                        <b>{ump_id}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Preço (R$)',
                                                align: 'right',
                                                render: ({ precoVendaMinimo, precoVendaMaximo }) => (
                                                    <div>
                                                        {parseFloat(precoVendaMaximo) > parseFloat(precoVendaMinimo) ? <div>
                                                            <b> {FormatNumber(precoVendaMinimo, true)} </b>
                                                            <b> até {FormatNumber(precoVendaMaximo, true)} </b>
                                                        </div> : <div>
                                                            <b> {FormatNumber(precoVendaMinimo, true)} </b>
                                                        </div>}
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Estoque',
                                                align: 'center',
                                                width: 120,
                                                render: ({ pre_qtde,ump_casasdecimais }) => (
                                                    <div>
                                                        <b>{arredonda(pre_qtde,ump_casasdecimais)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Pedido',
                                                align: 'center',
                                                width: 120,
                                                render: ({ pre_qtdePedido ,ump_casasdecimais}) => (
                                                    <div>
                                                        <b>{arredonda(pre_qtdePedido,ump_casasdecimais)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Condicional',
                                                align: 'center',
                                                width: 120,
                                                render: ({ pre_qtdeCondicional ,ump_casasdecimais}) => (
                                                    <div>
                                                        <b>{arredonda(pre_qtdeCondicional,ump_casasdecimais)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Previsão',
                                                align: 'center',
                                                width: 120,
                                                render: ({ pre_qtde, pre_qtdePedido, pre_qtdeCondicional,ump_casasdecimais }) => (
                                                    <div>
                                                        <b>{arredonda(parseFloat(pre_qtde) - parseFloat(pre_qtdePedido) - parseFloat(pre_qtdeCondicional),ump_casasdecimais)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                            locale={{
                                                emptyText: (
                                                    <Row>
                                                        <Col span={24} className="p-t-b-8">
                                                            <Divider orientation="center">
                                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                )
                                            }} scroll={{ x: 900 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="lista-resumo m-t-16">
                        <Col span={12}>
                            <Card size="default" title="Última Venda">
                                <Row>
                                    <Col span={6}>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Nº Nota</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaVenda.ntf_numero ? dadosUltimaVenda.ntf_numero : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Data</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaVenda.ntf_dataemissao ? moment(dadosUltimaVenda.ntf_dataemissao).format("DD/MM/YYYY") : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} align="center">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Qtde</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaVenda.nfi_qtde ? dadosUltimaVenda.nfi_qtde : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>R$</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimaVenda.totalItem ? dadosUltimaVenda.totalItem : 0, true, false)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card size="default" title="Última Compra">
                                <Row>
                                    <Col span={6}>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Nº Nota</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaCompra.ntf_numero ? dadosUltimaCompra.ntf_numero : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6}>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Data</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaCompra.ntf_dataemissao ? moment(dadosUltimaCompra.ntf_dataemissao).format("DD/MM/YYYY") : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} align="center">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Qtde</b>
                                            </Col>
                                            <Col span={24}>
                                                {!!dadosUltimaCompra.nfi_qtde ? dadosUltimaCompra.nfi_qtde : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>R$</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimaCompra.totalItem ? dadosUltimaCompra.totalItem : 0, true, false)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="últimos Custos">
                                <Row>
                                    <Col span={6} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Última Compra (R$)</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimosCustos.pre_custoultimacompra ? dadosUltimosCustos.pre_custoultimacompra : 0, true)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Custo Médio (R$)</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimosCustos.pre_valorcustomedio ? dadosUltimosCustos.pre_valorcustomedio : 0, true)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Com ICMS (R$)</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimosCustos.pre_custocomicms ? dadosUltimosCustos.pre_custocomicms : 0, true)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Sem ICMS (R$)</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimosCustos.pre_custosemicms ? dadosUltimosCustos.pre_custosemicms : 0, true)}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4} align="right">
                                        <Row gutter={[0, 8]}>
                                            <Col span={24} className="b-b-1">
                                                <b>Informado (R$)</b>
                                            </Col>
                                            <Col span={24}>
                                                {FormatNumber(!!dadosUltimosCustos.pre_valorcustoinformado ? dadosUltimosCustos.pre_valorcustoinformado : 0, true)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Notas de Venda">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaNotasVenda} columns={[
                                            {
                                                title: 'Série',
                                                width: 85,
                                                align: 'center',
                                                render: ({ ser_serie }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{ser_serie}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Nº',
                                                width: 85,
                                                align: 'center',
                                                render: ({ numeroNota }) => (
                                                    <div>
                                                        <b>{numeroNota}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Cliente',
                                                width: 450,
                                                render: ({ pessoaNome }) => (
                                                    <div>
                                                        <b>{pessoaNome}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataEmissao }) => (
                                                    <div>
                                                        <b>{moment(dataEmissao).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Vlr. Unit.',
                                                align: 'right',
                                                width: 95,
                                                render: ({ valorTotal, quantidade }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal / quantidade, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Qtde',
                                                align: 'right',
                                                width: 95,
                                                render: ({ quantidade }) => (
                                                    <div>
                                                        <b>{FormatNumber(quantidade, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                fixed: 'right',
                                                width: 120,
                                                render: ({ valorTotal }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Notas de Compra">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaNotasCompra} columns={[
                                            {
                                                title: 'Nº',
                                                width: 85,
                                                align: 'center',
                                                render: ({ numeroNota }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{numeroNota}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Fornecedor',
                                                render: ({ pessoaNome }) => (
                                                    <div>
                                                        <b>{pessoaNome}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataEmissao }) => (
                                                    <div>
                                                        <b>{moment(dataEmissao).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                fixed: 'right',
                                                render: ({ valorTotal }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Condicionais">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={dadosCondicionais} columns={[
                                            {
                                                title: 'Nº',
                                                width: 85,
                                                align: 'center',
                                                render: ({ codigoCondicional }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{codigoCondicional}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },{
                                                title: 'Situação',
                                                width: 125,
                                                render: ({ situacao }) => (
                                                    <div>
                                                        <b>{situacao}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Cliente',
                                                render: ({ nomeCliente }) => (
                                                    <div>
                                                        <b>{nomeCliente}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataCondicional }) => (
                                                    <div>
                                                        <b>{moment(dataCondicional).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Qtde Dev.',
                                                align: 'right',
                                                fixed: 'right',
                                                render: ({ qtdeDevol }) => (
                                                    <div>
                                                        <b>{qtdeDevol}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Qtde.',
                                                align: 'right',
                                                fixed: 'right',
                                                render: ({ qtde }) => (
                                                    <div>
                                                        <b>{qtde}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                fixed: 'right',
                                                render: ({ valorTotal }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Ordem de Compra">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaOrdemCompra} columns={[
                                            {
                                                title: 'Nº',
                                                width: 85,
                                                align: 'center',
                                                render: ({ codigoOrdemCompra }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{codigoOrdemCompra}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Fornecedor',
                                                render: ({ nomeFornecedor }) => (
                                                    <div>
                                                        <b>{nomeFornecedor}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataOrdemCompra }) => (
                                                    <div>
                                                        <b>{moment(dataOrdemCompra).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                fixed: 'right',
                                                render: ({ valorTotal }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Pedidos">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaPedidos} columns={[
                                            {
                                                title: 'Nº',
                                                width: 95,
                                                align: 'center',
                                                render: ({ numeroPedido }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{numeroPedido}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Cliente/Fornecedor',
                                                width: 750,
                                                render: ({ pessoaNome }) => (
                                                    <div>
                                                        <b>{pessoaNome}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataPedido }) => (
                                                    <div>
                                                        <b>{moment(dataPedido).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Vlr. Unit.',
                                                align: 'right',
                                                width: 95,
                                                render: ({ valorUnitario }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorUnitario, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Qtde',
                                                align: 'right',
                                                width: 95,
                                                render: ({ quantidade }) => (
                                                    <div>
                                                        <b>{FormatNumber(quantidade, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                width: 120,
                                                render: ({ valorTotal }) => (
                                                    <div>
                                                        <b>{FormatNumber(valorTotal, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Status',
                                                align: 'right',
                                                width: 120,
                                                render: ({ statusAtual }) => (
                                                    <div>
                                                        <b>{statusAtual}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Movimentações">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaMovimentacoes} columns={[
                                            {
                                                title: 'Série',
                                                width: 85,
                                                align: 'center',
                                                render: ({ ser_serie }) => (
                                                    <div>
                                                        <Tag color="processing" className="w-100">
                                                            <b>{ser_serie}</b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Operação',
                                                width: 200,
                                                render: ({ ope_descricao }) => (
                                                    <div>
                                                        <b>{ope_descricao}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Nº Nota',
                                                width: 120,
                                                render: ({ numeroNota }) => (
                                                    <div>
                                                        <b>{numeroNota}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Cliente',
                                                width: 450,
                                                render: ({ pes_nome }) => (
                                                    <div>
                                                        <b>{pes_nome}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Data',
                                                width: 95,
                                                render: ({ dataPosicao }) => (
                                                    <div>
                                                        <b>{moment(dataPosicao).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Vlr. Unit.',
                                                align: 'right',
                                                width: 95,
                                                render: ({ vlrUnitario }) => (
                                                    <div>
                                                        <b>{FormatNumber(vlrUnitario, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Qtde',
                                                align: 'right',
                                                width: 95,
                                                render: ({ quantidade }) => (
                                                    <div>
                                                        <b>{FormatNumber(quantidade, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                align: 'right',
                                                width: 120,
                                                render: ({ vlrTotalItem }) => (
                                                    <div>
                                                        <b>{FormatNumber(vlrTotalItem, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Status',
                                                align: 'right',
                                                width: 120,
                                                render: ({ tipoRegistro }) => (
                                                    <div>
                                                        <b>{tipoRegistro}</b>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                            //]} expandedRowRender={({ ope_descricao, quantidade, vlrUnitario, vlrCustoUltimaCompra }) => {
                                            //     return (
                                            //         <div>
                                            //             <Row justify="space-between" gutter={[8, 0]}>
                                            //                 <Col>
                                            //                     Operação: <b>{ope_descricao}</b>
                                            //                 </Col>
                                            //                 <Col>
                                            //                     Quant.: <b>{quantidade}</b>
                                            //                 </Col>
                                            //                 <Col>
                                            //                     Valor Unitário (R$): <b>{FormatNumber(vlrUnitario, true)} </b>
                                            //                 </Col>
                                            //                 <Col>
                                            //                     Custo Atual (R$): <b>{FormatNumber(vlrCustoUltimaCompra, true)}</b>
                                            //                 </Col>
                                            //             </Row>
                                            //         </div>
                                            //     )
                                            // }} expandIcon={({ record, expanded, onExpand }) => {
                                            //     return expanded ? (
                                            //         <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                                            //     ) : (
                                            //         <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                                            //     );
                                            //}} 
                                            locale={{
                                                emptyText: (
                                                    <Row>
                                                        <Col span={24} className="p-t-b-8">
                                                            <Divider orientation="center">
                                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                )
                                            }} columnWidth={30} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="lista-resumo m-t-16">
                        <Col span={24}>
                            <Card size="default" title="Listas de Preço">
                                <Row>
                                    <Col span={24}>
                                        <Table dataSource={listaPreco} columns={[
                                            {
                                                title: 'Lista',
                                                render: ({ ltp_nome }) => (
                                                    <div>
                                                        <b>{ltp_nome}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Valor (R$)',
                                                align: 'right',
                                                render: ({ lpi_valorvenda }) => (
                                                    <div>
                                                        <b>{FormatNumber(lpi_valorvenda, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24} className="p-t-b-8">
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Form>
            <ModalGradeEstoque dadosGrade={dadosProdutoGrade} exibirModal={openModalEstoque} fecharModal={()=>setOpenModalEstoque(false)} />
        </div>
    )
}