import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Collapse, Tag, Popover, Modal, Menu, Dropdown, Radio } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CloudUploadOutlined, MoreOutlined, CopyOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerProduto from './drawer';
import { useStateValue } from '../../state';
import { FormatNumber } from '../../ValueObjects/';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BreadcrumbPage, BotaoIncluirDados, PesquisaAvancada, InputPreco } from "../../components";
import { ModalGradeEstoque } from "../../components/modals";

export default function Produto() {

    const [{ manutencao, listagem }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [formPesquisaAvancada] = Form.useForm();
    const [formPesquisaAdc] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [dadosProdutoGrade, setDadosProdutoGrade] = useState({});
    const [openModalEstoque, setOpenModalEstoque] = useState(false);
    const togglePesquisa = () => {
        setOpen(!open);
    };

    const menuOutros = () => {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { entidade: 5 } } });
                    }}>
                        <Link to="/importarProduto"> Importar Produto </Link>
                    </Button>
                </Menu.Item>
            </Menu >
        )
    };

    useEffect(() => {
        let _ativo = !!!formPesquisaAvancada.getFieldsValue().produtoAtivo ? 1 : formPesquisaAvancada.getFieldsValue().produtoAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}&SomenteProduto=true`, filtro: '', ordem: '+pro_descricao' } });
        formPesquisaAvancada.setFieldsValue({ produtoAtivo: 1 });
    }, [])

    function duplicarProduto(produto) {
        let dados = {};
        if (!!produto.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${produto.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados = res.data;
                    dados.pro_id = null;
                    dados.pro_descricao = '';
                    api.get(`tributacaoIcms/BuscarTributacaoPorCodigoProduto?IdProduto=${produto.pro_id}`).then(res => {
                        if (res.status === 200) {
                            dados.icm_id = res.data.icm_id;
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                        }
                    }).catch((erro) => console.error(erro));
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function incluirMaisUm(produto) {
        let dados = {};
        if (!!produto.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${produto.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados.pro_id = null;
                    dados.pro_descricao = '';
                    dados.gru_id = res.data.gru_id;
                    dados.sgp_id = res.data.sgp_id;
                    dados.mar_id = res.data.mar_id;
                    dados.ncm_id = res.data.ncm_id;
                    dados.orm_id = res.data.orm_id;
                    dados.cen_id = res.data.cen_id;
                    dados.pis_id = res.data.pis_id;
                    dados.tpi_id = res.data.tpi_id;
                    dados.ump_id = res.data.ump_id;
                    dados.lpi_valorvenda = res.data.lpi_valorvenda;
                    api.get(`tributacaoIcms/BuscarTributacaoPorCodigoProduto?IdProduto=${produto.pro_id}`).then(res => {
                        if (res.status === 200) {
                            dados.icm_id = res.data.icm_id;
                            setEditando(true);
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                        }
                    }).catch((erro) => console.error(erro));
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function showDrawer(produto) {
        let dados = {};
        if (!!produto.pro_id) {
            api.get(`produto/RetornaDadosProduto?ProdutoId=${produto.pro_id}`).then(res => {
                if (res.status === 200) {
                    dados = res.data;
                    dados.pro_datainclusao = !!dados.pro_datainclusao ? moment(dados.pro_datainclusao) : null;
                    dados.data_sincro = !!dados.data_sincro ? moment(dados.data_sincro) : null;
                    dados.orm_id = parseInt(dados.orm_id);
                    //     api.get(`tributacaoIcms/BuscarTributacaoPorCodigoProduto?IdProduto=${produto.pro_id}`).then(
                    //         res => {
                    //             if (res.status === 200) {
                    //                 //dados.icm_id = res.data.icm_id;
                    //             }
                    //         }
                    //     ).catch(
                    //         (erro) => {
                    //             if (!!erro.response && !!erro.response.data && erro.response.data.Message) {
                    //                 notification.error({ description: erro.response.data.Message, message: 'Aviso' });
                    //             } else {
                    //                 console.error(erro);
                    //             }
                    //         }
                    //     ).finally(
                    //         () => {
                    //             dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                    //             dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    //         }
                    //     );
                }
            }).catch((erro) => console.error(erro))
                .finally(
                    () => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }
                );
        }
    };

    function aplicarFiltros() {
        let filtrosTela = popularFiltrosTela();

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtrosTela, filtro: (!!listagem.filtro ? listagem.filtro : '') } });
    }

    function excluirRegistro(produto) {
        api.delete(`produto/excluir/${produto.pro_id}`).then((res) => {
            if (res.data == true) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            } else {
                modalInativarProduto(produto);
            }
        }, (erro) => {
            console.error(erro);
            modalInativarProduto(produto);
        })
    };

    function inativarProduto(produto) {
        api.put(`produto/AtivarInativar?IdProduto=${produto.pro_id}&Ativo=false`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pro_descricao' } });
        })
    };

    const modalExcluirProduto = (produto) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o produto ${produto.pro_codigo}-${produto.pro_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(produto);
            }
        });
    };

    const modalInativarProduto = (produto) => {
        Modal.confirm({
            title: 'Não foi possível excluir o produto!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o produto ${produto.pro_codigo}-${produto.pro_descricao} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarProduto(produto);
            }
        });
    };

    function popularFiltrosTela() {
        let filtrosTela = '';
        filtrosTela += `&SomenteProduto=true&Ativo=${formPesquisaAvancada.getFieldsValue().produtoAtivo}`
        if (parseFloat(formPesquisaAdc.getFieldsValue().precoMinimo) > 0.00) filtrosTela += `&precoMinimo=${formPesquisaAdc.getFieldsValue().precoMinimo}`;
        if (parseFloat(formPesquisaAdc.getFieldsValue().precoMaximo) > 0.00) filtrosTela += `&precoMaximo=${formPesquisaAdc.getFieldsValue().precoMaximo}`;

        return filtrosTela;
    }

    function retornoPesquisaAvancada(expressao) {
        listagem.outrosFiltros = '';
        expressao = !!expressao ? expressao : '';
        let filtrosTela = popularFiltrosTela();
        dispatch({ type: listagemActions.CHANGE, data: { filtro: !!listagem.filtro ? listagem.filtro : '', outrosFiltros: `${filtrosTela}&filtroAdicional=${btoa(expressao)}`, ordem: '+pro_descricao' } })
    };


    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', ordem: '+pro_descricao' } })
    };

    function onClickEditar(produto) {
        setEditando(true);
        showDrawer(produto);
    };

    function listarGrades(record) {
        setDadosProdutoGrade(record);
        setOpenModalEstoque(true);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaProdutos" form={formPesquisaAvancada} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pro_descricao" },
                            { label: "Nome Z - A", value: "-pro_descricao" },
                            { label: "Código Crescente", value: "+pro_codigo" },
                            { label: "Código Decrescente", value: "-pro_codigo" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Produto" name="produtoAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={formPesquisaAvancada} telaFiltro={4} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>

            <Form layout="vertical" name="formularioPesqAvancadaProdutos" form={formPesquisaAvancada}>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Form layout="vertical" name="formPesquisaAdc" form={formPesquisaAdc}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={19}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={7} lg={7} xl={7} >
                                                <InputPreco label="Preço Mínimo" name="precoMinimo" placeholder="Preço mínimo de venda" onBlur={aplicarFiltros} />
                                            </Col>
                                            <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                                                <InputPreco label="Preço Máximo" name="precoMaximo" placeholder="Preço máximo de venda" onBlur={aplicarFiltros} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                                <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={formPesquisaAvancada} telaFiltro={2} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="produto/ListarTodas" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo, pro_ativo }) => (
                            <Popover content={pro_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={pro_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'EAN-13',
                        align: 'center',
                        width: 110,
                        render: ({ pro_ean, pro_usagrade }, record) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{pro_ean}</b>
                                    </Col>
                                    {pro_usagrade &&
                                        <Col>
                                            <Button type="link" onClick={() => listarGrades(record)}>
                                                <u>Ver grades</u>
                                            </Button>
                                        </Col>
                                    }

                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição do Produto',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Un. Medida',
                        width: 100,
                        render: ({ ump_descricao }) => (
                            <div>
                                <b>{ump_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Preço (R$)',
                        width: 120,
                        align: 'right',
                        render: ({ precoVendaMinimo, precoVendaMaximo }) => (
                            <div>
                                {precoVendaMinimo === precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)}</b>
                                }
                                {precoVendaMinimo !== precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)} até {FormatNumber(precoVendaMaximo, true)}</b>
                                }
                            </div>
                        ),
                    },
                    {
                        width: 98,
                        align: 'center',
                        render: (produto) => (
                            <div>
                                <Link to="/resumoProduto" onClick={() => { dispatch({ type: manutencaoActions.CHANGE, data: { dados: produto } }); }}>
                                    <u>Mais Detalhes</u>
                                </Link>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (produto) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<CopyOutlined />} onClick={() => { duplicarProduto(produto) }} />
                                    </Col>
                                    <Col>
                                        <Editar onClick={() => onClickEditar(produto)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluirProduto(produto)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({
                    pro_observacao, pro_datainclusao, gru_nome, sgp_nome, orm_id, orm_descricao, ncm_codigo, pro_fci, ump_descricao, tpi_descricao, pis_descricao, nat_descricao, mar_nome, pro_ean
                }) => {
                    return (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[16, 0]}>
                                <Col>
                                    Grupo: <b>{gru_nome || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Subgrupo: <b>{sgp_nome || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Marca: <b>{mar_nome || 'Não Informado'}</b>
                                </Col>
                            </Row>
                            <Row align="middle" justify="space-between" gutter={[16, 8]}>
                                <Col>
                                    NCM: <b>{ncm_codigo || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    CST Origem: <b>{orm_id} - {orm_descricao}</b>
                                </Col>
                                <Col>
                                    FCI: <b>{pro_fci || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    {/* Unidade de Medida: <b>{ump_descricao}</b> */}
                                </Col>
                                <Col>
                                    Tipo de Produto para SPED: <b>{tpi_descricao || 'Não Informado'}</b>
                                </Col>
                            </Row>
                            <Row align="middle" justify="space-between" gutter={[16, 8]}>
                                <Col>
                                    Tributação Pis/Cofins: <b>{pis_descricao || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Natureza da Receita Pis/Cofins: <b>{nat_descricao || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Data Inclusão: <b>{moment(pro_datainclusao).format("DD/MM/YYYY")}</b>
                                </Col>
                            </Row>
                            {!!pro_observacao &&
                                <Row align="middle" gutter={[16, 8]}>
                                    <Col>
                                        Observações: <b>{pro_observacao}</b>
                                    </Col>
                                </Row>
                            }
                        </div>
                    )
                }} />
            </div>
            <DrawerProduto incluirMaisUm={incluirMaisUm} editando={{ editando, setEditando }} />
            <ModalGradeEstoque dadosGrade={dadosProdutoGrade} exibirModal={openModalEstoque} fecharModal={()=>setOpenModalEstoque(false)} />
        </div>
    )
}