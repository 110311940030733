import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, LeftOutlined, MinusOutlined, RightOutlined, SaveOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { DrawerNav } from "../../components";
import { listagemActions, manutencaoActions } from "../../actions";
import NFServico from "../notaFiscalServico/manutencao";

export default function DrawerServico({ showDrawer }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formServico] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [resume, setResume] = useState(false);
    const [proximaPag, setProximaPag] = useState(0);

    const ActionsController = () => {
        setProximaPag(tagPages - 1)
        setResume(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setResume(false);
                fecharDrawer();
            }
        });
    }

    function fecharDrawer() {
        formServico.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
        setResume(false);
        showDrawer.setShowNFServico(false);
    }
    
    return (
        <DrawerNav title="Nota Fiscal de Serviço"
            width="70%"
            visible={showDrawer.showNFServico}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => ActionsController()} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={resume} size="large" onClick={() => { setProximaPag(tagPages + 1) }}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formServico.submit()} hidden={!resume} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Emitir Nota
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <NFServico form={formServico} showDrawer={showDrawer?.showNFServico} carregando={setCarregando} aoSalvar={fecharDrawer} pages={{ tagPages, setTagPages }} 
            proximaPag={{ proximaPag, setProximaPag }} resume={setResume} />
        </DrawerNav>
    )
}