import React, { useState } from 'react';
import { Row, Col, Tag, Table, Button } from "antd";

import { FormatNumber } from '../../ValueObjects';

export default function TabDetalheItens({ itensNF }) {

    const [percentualDesc, setPercentualDesc] = useState(false);

    function retornarPercentual(perc_desconto, nfi_valordesconto) {
        if (percentualDesc) {
            return (
                <div>
                    <b>{perc_desconto}</b>%
                </div>
            )
        } else {
            return (
                <div>
                    <b>{FormatNumber(nfi_valordesconto, true)}</b>
                </div>
            )
        }
    };

    return (
        <div className="tabela">
            <Table dataSource={itensNF} columns={
                [
                    {
                        title: '',
                        render: ({ pro_codigo, pro_descricao, descricaograde }) => (
                            <div>
                                <Row align="middle" gutter={[5, 5]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{pro_descricao}</b>
                                    </Col>
                                    {!!descricaograde &&
                                        <Col>
                                            <Tag color="default">
                                                <b>{descricaograde}</b>
                                            </Tag>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Faturado',
                        align: 'right',
                        width: 60,
                        render: ({ pdi_qtdeentregue }) => (
                            <div>
                                <Row justify='end' gutter={[8, 0]}>
                                    <Col>
                                        {!!pdi_qtdeentregue ? <b>{pdi_qtdeentregue}</b> : <b>0</b>}
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Devolvido',
                        align: 'right',
                        width: 60,
                        render: ({ pdi_qtdedevolvido }) => (
                            <div>
                                <Row justify='end' gutter={[8, 0]}>
                                    <Col>
                                        {!!pdi_qtdedevolvido ? <b>{pdi_qtdedevolvido}</b> : <b>0</b>}
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Qtde.',
                        width: 50,
                        align: 'right',
                        render: ({ pdi_quantidade }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{pdi_quantidade}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unt.(R$)',
                        align: 'right',
                        width: 80,
                        render: ({ pdi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(pdi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: () => {
                            return (
                                <div>
                                    <Row align="middle" justify="end" gutter={[5, 0]}>
                                        <Col>
                                            Desc.
                                        </Col>
                                        <Col>
                                            <Button size="small" onClick={() => { setPercentualDesc(!percentualDesc) }}>{(percentualDesc ? '%' : 'R$')}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        width: 75,
                        align: 'right',
                        render: ({ perc_desconto, pdi_valordesconto }) => (
                            retornarPercentual(perc_desconto, pdi_valordesconto)
                        ),
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        width: 100,
                        render: ({ pdi_valortotal }) => (
                            <div>
                                <b>{FormatNumber(pdi_valortotal, true)}</b>
                            </div>
                        ),
                    }
                ]
            } />
        </div>
    );
}