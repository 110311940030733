import api from '../services/api';
import { notification } from "antd";
import { getRegimeTributario } from "../services/auth";

export const calculoIPI = (baseIPI, aliquotaIPI, valorFrete, valorOutrasDespesas) => {
  return new Promise(resolve => {
    if (!!baseIPI && !!aliquotaIPI) {
      if (valorFrete < 0) {
        resolve({ error: "Valor do frete não pode ser negativo" });
      }
      if (valorOutrasDespesas < 0) {
        resolve({ error: "Valor do outras despesas acessórias não pode ser negativo" });
      }

      let baseCalculo = (baseIPI + valorFrete + valorOutrasDespesas).toFixed(2);
      let valorIpi = ((baseCalculo * aliquotaIPI) / 100).toFixed(2);

      resolve({ baseIPI: parseFloat(baseCalculo), valorIPI: parseFloat(valorIpi) });
    } else {
      if (!(!!baseIPI)) {
        resolve({ error: "A base de cálculo do IPI não foi informada!" });
      }
      if (!(!!aliquotaIPI)) {
        resolve({ error: "A alíquota do IPI não foi informada!" });
      }
    }
  });
}

export const calculoICMSST = (valorItem, quantidade, valorFrete, valorDespesas, valorIPI, valorDesconto, valorAcrescimo, reducaoICMS, aliquotaICMS, reducaoICMSST, mva, aliquotaICMSST, ufDestino, ufOrigem, regimeTributario, mvaAjustado, retornoCalcIcms) => {
  return new Promise(resolve => {
    let valorICMS = 0;
    let resultadoBaseICMSST = 0;
    let resultadoICMSST = 0;
    if (!!valorItem && !!mva && !!aliquotaICMSST && !!quantidade) {
      if (valorFrete < 0) {
        resolve({ error: "Valor do frete não pode ser negativo" });
      }
      if (valorDespesas < 0) {
        resolve({ error: "Valor das despesas não pode ser negativo" });
      }
      if (valorIPI < 0) {
        resolve({ error: "Valor do IPI não pode ser negativo" });
      }
      if (valorDesconto < 0) {
        resolve({ error: "Valor do desconto não pode ser negativo" });
      }
      if (reducaoICMS < 0) {
        resolve({ error: "Valor do desconto não pode ser negativo" });
      }
      if (valorItem < 0) {
        resolve({ error: "Valor do item não pode ser negativo" });
      }
      if (aliquotaICMS < 0) {
        resolve({ error: "A alíquota do ICMS não pode ser negativa" });
      }
      if (aliquotaICMSST < 0) {
        resolve({ error: "A alíquota do ICMS ST não pode ser negativa" });
      }
      if (mva < 0) {
        resolve({ error: "O MVA não pode ser negativo" });
      }
      if (reducaoICMSST < 0) {
        resolve({ error: "O percentual de redução do MVA não pode ser negativo" });
      }

      if (!!retornoCalcIcms) {
        if (!(!!retornoCalcIcms.ICMS)) {
          valorICMS = 0;
        } else {
          valorICMS = retornoCalcIcms.ICMS;
        }
        if (aliquotaICMSST > aliquotaICMS && ufOrigem !== ufDestino && regimeTributario === 3) {
          if (mvaAjustado === 0) {
            mvaAjustado = ((((1 + (mva / 100)) * ((100 - aliquotaICMS) / 100)) / ((100 - aliquotaICMSST) / 100) - 1) * 100);
          }
          resultadoBaseICMSST = ((((valorItem * quantidade) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI)) * (1 + (mvaAjustado / 100))) - ((valorItem + valorFrete + valorDespesas + parseFloat(valorIPI)) * reducaoICMSST / 100)).toFixed(2);
          resultadoICMSST = ((((resultadoBaseICMSST * aliquotaICMSST / 100) - valorICMS))).toFixed(2);

        } else {
          resultadoBaseICMSST = ((((valorItem * quantidade) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI)) * (1 + (mva / 100))) - ((valorItem + valorFrete + valorDespesas + parseFloat(valorIPI)) * reducaoICMSST / 100)).toFixed(2);
          resultadoICMSST = ((((resultadoBaseICMSST * aliquotaICMSST / 100) - valorICMS))).toFixed(2);

        }
        resolve({ baseICMSST: parseFloat(resultadoBaseICMSST), ICMSST: parseFloat(resultadoICMSST) });
      } else {
        calculoICMS(valorItem, quantidade, valorFrete, valorDespesas, valorIPI, valorDesconto, valorAcrescimo, reducaoICMS, aliquotaICMS).then(function (icms) {
          if (!(!!icms.ICMS)) {
            valorICMS = 0;
          } else {
            valorICMS = icms.ICMS;
          }
          if (aliquotaICMSST > aliquotaICMS && ufOrigem !== ufDestino && regimeTributario === 3) {
            if (mvaAjustado === 0) {
              mvaAjustado = ((((1 + (mva / 100)) * ((100 - aliquotaICMS) / 100)) / ((100 - aliquotaICMSST) / 100) - 1) * 100);
            }
            resultadoBaseICMSST = ((((valorItem * quantidade) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI)) * (1 + (mvaAjustado / 100))) - ((valorItem + valorFrete + valorDespesas + parseFloat(valorIPI)) * reducaoICMSST / 100)).toFixed(2);
            resultadoICMSST = ((((resultadoBaseICMSST * aliquotaICMSST / 100) - valorICMS))).toFixed(2);

          } else {
            resultadoBaseICMSST = ((((valorItem * quantidade) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI)) * (1 + (mva / 100))) - ((valorItem + valorFrete + valorDespesas + parseFloat(valorIPI)) * reducaoICMSST / 100)).toFixed(2);
            resultadoICMSST = ((((resultadoBaseICMSST * aliquotaICMSST / 100) - valorICMS))).toFixed(2);

          }
          resolve({ baseICMSST: parseFloat(resultadoBaseICMSST), ICMSST: parseFloat(resultadoICMSST) });
        });
      }
    } else {
      if (!(!!valorItem)) {
        resolve({ error: "O valor do item  não foi informado" });
      }
      if (!(!!quantidade)) {
        resolve({ error: "A quantidade do item  não foi informado" });
      }

      if (!(!!aliquotaICMSST)) {
        resolve({ error: "A alíquota do ICMS não foi informada" });
      }

      if (!(!!mva)) {
        resolve({ error: "O MVA não foi informado" });
      }
    }
  });
}

export const calculoICMS = (valorItem, quantidadeItem, valorFrete, valorDespesas, valorIPI, valorDesconto, valorAcrescimo, reducaoICMS, aliquotaICMS) => {
  return new Promise(resolve => {
    if (!!valorItem && !!aliquotaICMS) {
      if (valorFrete < 0) {
        resolve({ error: "Valor do frete não pode ser negativo" });
      }
      if (valorDespesas < 0) {
        resolve({ error: "Valor das despesas não pode ser negativo" });
      }
      if (valorIPI < 0) {
        resolve({ error: "Valor do IPI não pode ser negativo" });
      }
      if (valorDesconto < 0) {
        resolve({ error: "Valor do desconto não pode ser negativo" });
      }
      if (reducaoICMS < 0) {
        resolve({ error: "Valor do desconto não pode ser negativo" });
      }
      if (valorItem < 0) {
        resolve({ error: "Valor do item não pode ser negativo" });
      }
      if (quantidadeItem <= 0) {
        resolve({ error: "A quantidade do item não pode ser igual a zero" });
      }
      if (aliquotaICMS < 0) {
        resolve({ error: "A alíquota não pode ser negativa" });
      }

      let resultadoBaseICMS = ((((valorItem * quantidadeItem) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI) - valorDesconto) * parseFloat(100 - reducaoICMS)) / 100).toFixed(2);
      let resultadoICMS = (((((valorItem * quantidadeItem) + valorFrete + valorDespesas + valorAcrescimo + parseFloat(valorIPI) - valorDesconto) * (100 - reducaoICMS) / 100) * aliquotaICMS) / 100).toFixed(2);

      resolve({ baseICMS: parseFloat(resultadoBaseICMS), ICMS: parseFloat(resultadoICMS) });
    } else {
      if (!(!!valorItem)) {
        resolve({ error: "O valor do item não foi informado" });
      }

      if (quantidadeItem == 0) {
        resolve({ error: "A quantidade do item não foi informada" });
      }

      if (!(!!aliquotaICMS)) {
        resolve({ error: "A alíquota do ICMS não foi informada" });
      }
    }
  });
}

export const calculoICMSDiferido = (valorICMSOperacao, percentualICMSDiferido) => {
  return new Promise(resolve => {
    if (!!valorICMSOperacao && !!percentualICMSDiferido) {
      if (valorICMSOperacao < 0) {
        resolve({ error: "Valor do ICMS operação não pode ser negativo" });
      }
      if (percentualICMSDiferido < 0) {
        resolve({ error: "Valor do percentual do diferimento não pode ser negativo" });
      }

      var resultadoICMSDiferido = ((valorICMSOperacao * parseFloat(percentualICMSDiferido)) / 100).toFixed(2);
      var resultadoICMSDevido = (valorICMSOperacao - resultadoICMSDiferido).toFixed(2);

      resolve({ valorICMSDiferido: parseFloat(resultadoICMSDiferido), ICMSDevido: parseFloat(resultadoICMSDevido) });
    } else {
      if (!(!!valorICMSOperacao)) {
        resolve({ error: "O valor do ICMS operação não foi informado" });
      }

      if (!(!!percentualICMSDiferido)) {
        resolve({ error: "O percentual do ICMS diferido não foi informado" });
      }
    }
  });
}
// tratar campos numericos....
export const CalcularImpostoItem = (origemProduto, cst, csosn, ufOrigem, ufDestino, quantidadeItem, valorUnitario, valorFrete, valorOutros, valorDesconto, aliquotaIcms, percDiferimento,
  percReducaoIcms, aliquotaIcmsSt, percMva, percReducaoIcmsST, percMvaAjustado, aliquotaIpi, tipoPessoa, eConsumidorFinal, destinatarioTemIE,
  indicadorPresenca = 0, eProdutorRural = false, eOrgaoPublico = false, eServico = false, aliquotaIssqn) => {
  return new Promise(resolve => {
    var values = {
      orm_id: origemProduto,
      cso_id: csosn,
      cst_id: cst,
      uf_origem: ufOrigem,
      uf_destino: ufDestino,
      quantidade: quantidadeItem,
      vlr_unitario: parseFloat(valorUnitario),
      vlr_frete: parseFloat(valorFrete),
      vlr_outros: parseFloat(valorOutros),
      vlr_descontos: parseFloat(valorDesconto),
      aliquotaIcms: aliquotaIcms,
      percDiferimento: percDiferimento,
      percReducaoIcms: percReducaoIcms,
      aliquotaIcmsSt: aliquotaIcmsSt,
      percReducaoIcmsST: percReducaoIcmsST,
      percMva: percMva,
      percMvaAjustado: percMvaAjustado,
      aliquotaIpi: aliquotaIpi,
      emp_regimetributario: parseInt(getRegimeTributario()),
      ope_indicadorpresenca: indicadorPresenca,
      tipoPessoa: tipoPessoa,
      eConsumidorFinal: eConsumidorFinal,
      eOrgaoPublico: eOrgaoPublico,
      eProdutorRural: eProdutorRural,
      destinatarioTemIE: destinatarioTemIE,
      eServico: eServico,
      aliquotaIssqn: aliquotaIssqn,
      identificadorDestino: ufDestino==='EX'? 3 : ufOrigem == ufDestino ? 1 : 2,
    };

    api.post(`CalculaImpostos/CalcularImpostoItem`, values).then(res => {
      if (res.status === 200) {
        resolve(res.data);
      }
    }, err => {
      notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
    }).finally(
      () => {

      }
    );
  });
}