import React from "react";
import { Button, Modal, Row } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, FileOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export default function ModalSelecionarNF({ exibirModal, fecharModal, drawerNFSE, drawerNF }) {

    return (
        <Modal
            visible={exibirModal}
            maskClosable={false}
            width={'416px'}
            closable={false}
            footer={[
                <Row justify="center" gutter={[16, 0]}>
                    <Button icon={<CloseOutlined />} onClick={() => fecharModal()}>Cancelar</Button>
                    <Button icon={<FileOutlined />} onClick={() => drawerNF()} type="primary">Nota Fiscal</Button>
                    <Button icon={<FileOutlined />} onClick={() => drawerNFSE()} type="primary">Nota Fiscal de Serviço</Button>
                </Row>
            ]}
        >
            <Row justify="center" style={{ marginBottom: '5px' }}>
                <QuestionCircleOutlined style={{ fontSize: '25px', color: '#faad14' }} />
            </Row>
            <Row justify="center">
                <b>Qual Documento Fiscal Deseja emitir?</b>
            </Row>
            <Row justify="center" style={{ marginTop: '8px', marginBottom: '-10px' }}>
                O pedido possui Produtos e Serviços.<br />
                Selecione o ducumento fiscal que deseja emitir primeiro.
            </Row>
        </Modal>
    )
}