import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Table } from "antd";
import { FormatNumber } from "../../ValueObjects";

import moment from 'moment';

export default function DetalheRemessa({ dadosModal }) {

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Arquivo">
                            <b>{(!!dadosModal[0].rem_nomearquivoremessa ? dadosModal[0].rem_nomearquivoremessa : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label=" Numero Remessa">
                            <b>{(!!dadosModal[0].rem_id ? dadosModal[0].rem_id : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Col align="middle">
                <div className="tabela mt-dif">
                    <Table columns={[
                        {
                            title: 'Cliente',
                            width: '45%',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'N°. Parcela',
                            align: 'center',
                            
                            width: '8%',
                            render: ({ prc_numeroparcela }) => (
                                <div>
                                    <b>{prc_numeroparcela}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'N° Doc.',
                            align: 'center',
                            width: '8%',
                            render: ({ bol_numerodocumento }) => (
                                <div>
                                    <b>{bol_numerodocumento}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor',
                            align: 'center',
                            width: '8%',
                            render: ({ bol_valor }) => (
                                <div>
                                    <b>{FormatNumber(bol_valor,true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Doc.',
                            align: 'center',
                            width: '8%',
                            render: ({ bol_dataemissao }) => (
                                <div>
                                    <b>{!!bol_dataemissao ? moment(bol_dataemissao).format('DD/MM/YYYY') : null}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Vencimento',
                            align: 'center',
                            width: '10%',
                            render: ({ bol_datavencimento }) => (
                                <div>
                                    <b>{!!bol_datavencimento ? moment(bol_datavencimento).format('DD/MM/YYYY') : null}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Carteira/Nosso Número',
                            align: 'center',
                            width: '10%',
                            render: ({ crt_carteira, bol_nossonumero }) => (
                                <div>
                                    <b>{(!!crt_carteira ? (crt_carteira + "/") : null)+ (!!bol_nossonumero ? bol_nossonumero : null)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Instrução Remessa',
                            align: 'center',
                            width: '10%',
                            render: ({ isp_descricao }) => (
                                <div>
                                    <b>{!!isp_descricao ? isp_descricao : null}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Chave Nfe',
                            align: 'center',
                            width: '10%',
                            render: ({ ntf_chavenfe }) => (
                                <div>
                                    <b>{ntf_chavenfe}</b>
                                </div>
                            ),
                        },
                    ]} dataSource={dadosModal} 
                    scroll={{ x: 'max-content'}} //Permite rolagem horizontal
                    />
                </div>
            </Col>

        </div>
    );
}