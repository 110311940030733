import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, RedoOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import ManutencaoRemessa from './manutencao';
import { DrawerNav } from '../../components';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerRemessa({ listarRemessas }) {

    const [formRemessa] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (!!manutencao.dados) {
        formRemessa.setFieldsValue(manutencao.dados);
    }
    else {
        //formRemessa.resetFields();
    }

    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        listarRemessas();
        formRemessa.resetFields();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Cadastro Remessa"
            width="90%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" icon={<RedoOutlined />} onClick={() => formRemessa.submit()} loading={carregando} size="large">
                                Gerar Remessa
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoRemessa formulario={formRemessa} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav>

    )
}