import React from "react";
import { Typography, Row, Col, Form, Divider, InputNumber, Input } from "antd";
import { InputPreco } from "../../components";

export default function TabVolumes() {

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-produto.png").default} alt="Volumes Transportados" /> Volumes Transportados
                    </Typography.Title>
                    <Divider orientation="left" plain> Informe os volumes transportados. </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-16">
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={4}>
                    <Form.Item label="Quantidade de Volumes" name="nvl_qtdevolumes">
                        <InputNumber placeholder="Informe a Quantidade de Volumes" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="Espécie (Caixa, rolo, barril, etc.)" name="nvl_especievolume">
                        <Input placeholder="Informe a Espécie" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={5}>
                    <Form.Item label="Numeração dos Volumes" name="nvl_numeracaovolume">
                        <InputNumber placeholder="Informe a Numeração" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="Marca" name="nvl_marcavolume">
                        <Input placeholder="Informe a Marca" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={5}>
                    <InputPreco
                        label="Peso Líquido"
                        name="nvl_pesoliquido"
                        precision={3}
                    />
                </Col>
                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={5}>
                    <InputPreco
                        label="Peso Bruto"
                        name="nvl_pesobruto"
                        precision={3}
                    />
                </Col>
            </Row>
        </div>
    );
}