import DrawerCidade from './drawer';
import api from '../../services/api';
import { useEffect } from 'react';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { Col, Modal, notification, Popover, Row, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { BreadcrumbIncluirDados, Duplicar, Editar, Excluir, PesquisaOrdenacao, TabelaDados } from '../../components';
import { useState } from 'react';


export default function Cidade(){

    const [{ ui, manutencao, SelectPaginado }, dispatch] = useStateValue();
    
        useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+cnf_id' } }), []);

    function excluirRegistro(dados){
        api.delete(`CidadeConfigNfse/Excluir?id=${dados.cnf_id}`).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Configurações excluidas com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    notification.error({ message: "erro", description: `${erro}`})
                }
            );
        }
             const modalExcluir = (dados) => {
                    Modal.confirm({
                        title: 'Excluir?',
                        icon: <ExclamationCircleOutlined />,
                        content: `Deseja realmente excluir?`,
                        okText: 'Sim',
                        cancelText: 'Não',
                        centered: true,
                        onOk() {
                            excluirRegistro(dados);
                        }
                    });
                };

                return (
                        <div className="p-10">
                            <BreadcrumbIncluirDados />
                            <PesquisaOrdenacao opcoesOrdenacao={[
                                { label: "Nome A - Z", value: "+cnf_id" },
                                { label: "Nome Z - A", value: "-cnf_id" },
                                { label: "Descrição Crescente", value: "+cnf_id" },
                                { label: "Descrição Decrescente", value: "-cnf_id" }
                            ]} />
                             <div className="tabela">
                <TabelaDados url="CidadeConfigNfse/Listar" 
                colunas={[
                    {
                        title: 'Cidades',
                        render: ({ cidade }) => (
                            <div>
                                <b>{cidade?.cid_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar
                                            onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerCidade cadCidade={true}/>
        </div>
    );
}